/* Sizes */
.live-tile,
.list-tile,
.copy-tile,
.tile-strip .flip-list > li {
  outline: 1px solid transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px;
  width: 100%;
}
.live-tile.half-tile,
.list-tile.half-tile,
.copy-tile.half-tile,
.tile-strip .flip-list > li.half-tile {
  height: 70px;
  width: 70px;
}
.live-tile.half-wide,
.list-tile.half-wide,
.copy-tile.half-wide,
.tile-strip .flip-list > li.half-wide {
  width: 70px;
}
.live-tile.two-wide,
.list-tile.two-wide,
.copy-tile.two-wide,
.tile-strip .flip-list > li.two-wide {
  width: 310px;
}
.live-tile.three-wide,
.list-tile.three-wide,
.copy-tile.three-wide,
.tile-strip .flip-list > li.three-wide {
  width: 470px;
}
.live-tile.four-wide,
.list-tile.four-wide,
.copy-tile.four-wide,
.tile-strip .flip-list > li.four-wide {
  width: 630px;
}
.live-tile.five-wide,
.list-tile.five-wide,
.copy-tile.five-wide,
.tile-strip .flip-list > li.five-wide {
  width: 790px;
}
.live-tile.six-wide,
.list-tile.six-wide,
.copy-tile.six-wide,
.tile-strip .flip-list > li.six-wide {
  width: 950px;
}
.live-tile.seven-wide,
.list-tile.seven-wide,
.copy-tile.seven-wide,
.tile-strip .flip-list > li.seven-wide {
  width: 1110px;
}
.live-tile.eight-wide,
.list-tile.eight-wide,
.copy-tile.eight-wide,
.tile-strip .flip-list > li.eight-wide {
  width: 1270px;
}
.live-tile.nine-wide,
.list-tile.nine-wide,
.copy-tile.nine-wide,
.tile-strip .flip-list > li.nine-wide {
  width: 1430px;
}
.live-tile.ten-wide,
.list-tile.ten-wide,
.copy-tile.ten-wide,
.tile-strip .flip-list > li.ten-wide {
  width: 1590px;
}
.live-tile.half-tall,
.list-tile.half-tall,
.copy-tile.half-tall,
.tile-strip .flip-list > li.half-tall {
  height: 70px;
}
.live-tile.two-tall,
.list-tile.two-tall,
.copy-tile.two-tall,
.tile-strip .flip-list > li.two-tall {
  height: 310px;
}
.live-tile.three-tall,
.list-tile.three-tall,
.copy-tile.three-tall,
.tile-strip .flip-list > li.three-tall {
  height: 470px;
}
.live-tile.four-tall,
.list-tile.four-tall,
.copy-tile.four-tall,
.tile-strip .flip-list > li.four-tall {
  height: 630px;
}
.live-tile.five-tall,
.list-tile.five-tall,
.copy-tile.five-tall,
.tile-strip .flip-list > li.five-tall {
  height: 790px;
}
.live-tile.six-tall,
.list-tile.six-tall,
.copy-tile.six-tall,
.tile-strip .flip-list > li.six-tall {
  height: 950px;
}
.live-tile.seven-tall,
.list-tile.seven-tall,
.copy-tile.seven-tall,
.tile-strip .flip-list > li.seven-tall {
  height: 1110px;
}
.live-tile.eight-tall,
.list-tile.eight-tall,
.copy-tile.eight-tall,
.tile-strip .flip-list > li.eight-tall {
  height: 1270px;
}
.live-tile.nine-tall,
.list-tile.nine-tall,
.copy-tile.nine-tall,
.tile-strip .flip-list > li.nine-tall {
  height: 1430px;
}
.live-tile.ten-tall,
.list-tile.ten-tall,
.copy-tile.ten-tall,
.tile-strip .flip-list > li.ten-tall {
  height: 1590px;
}
/* flip-list tile grid sprite styles */
.live-tile > .flip-list,
.list-tile > .flip-list {
  height: 100%;
  width: 100%;
}
.flip-list > li {
  height: 50px;
  margin: 0px;
  padding: 0px;
  width: 50px;
}
.flip-list.fourTiles > li {
  border: none;
  padding: 0;
  margin: 0;
  height: 50%;
  width: 50%;
}
.flip-list.nineTiles > li {
  height: 33%;
  width: 33%;
  border: none;
  padding: 0;
  margin: 0;
}
.flip-list.fourTiles > li > div,
.flip-list.fourTiles > li > div > a,
.flip-list.fourTiles > li > div > img,
.flip-list.nineTiles > li > div,
.flip-list.nineTiles > li > div > a,
.flip-list.nineTiles > li > div > img {
  border: none;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}
/* fourTile background positions */
.fourTiles .fourTiles1 img,
.fourTiles img.fourTiles1,
.fourTiles .fourTiles1 a,
.fourTiles .tile-1 img,
.fourTiles .tile-1 a {
  background-position: 0px 0px;
}
.fourTiles .fourTiles2 img,
.fourTiles img.fourTiles2,
.fourTiles .fourTiles2 a,
.fourTiles .tile-2 img,
.fourTiles .tile-2 a {
  background-position: -75px 0px;
}
.fourTiles .fourTiles3 img,
.fourTiles img.fourTiles3,
.fourTiles .fourTiles3 a,
.fourTiles .tile-3 img,
.fourTiles .tile-3 a {
  background-position: 0px -75px;
}
.fourTiles .fourTiles4 img,
.fourTiles img.fourTiles4,
.fourTiles .fourTiles4 a,
.fourTiles .tile-4 img,
.fourTiles .tile-4 a {
  background-position: -75px -75px;
}
/* nineTile background positions */
.nineTiles .nineTiles1 img,
.nineTiles img.nineTiles1,
.nineTiles .nineTiles1 a,
.nineTiles .tile-1 img,
.nineTiles .tile-1 a {
  background-position: 0px 0px;
}
.nineTiles .nineTiles2 img,
.nineTiles img.nineTiles2,
.nineTiles .nineTiles2 a,
.nineTiles .tile-2 img,
.nineTiles .tile-2 a {
  background-position: -50px 0px;
}
.nineTiles .nineTiles3 img,
.nineTiles img.nineTiles3,
.nineTiles .nineTiles3 a,
.nineTiles .tile-3 img,
.nineTiles .tile-3 a {
  background-position: -100px 0px;
}
.nineTiles .nineTiles4 img,
.nineTiles img.nineTiles4,
.nineTiles .nineTiles4 a,
.nineTiles .tile-4 img,
.nineTiles .tile-4 a {
  background-position: 0px -50px;
}
.nineTiles .nineTiles5 img,
.nineTiles img.nineTiles5,
.nineTiles .nineTiles5 a,
.nineTiles .tile-5 img,
.nineTiles .tile-5 a {
  background-position: -50px -50px;
}
.nineTiles .nineTiles6 img,
.nineTiles img.nineTiles6,
.nineTiles .nineTiles6 a,
.nineTiles .tile-6 img,
.nineTiles .tile-6 a {
  background-position: -100px -50px;
}
.nineTiles .nineTiles7 img,
.nineTiles img.nineTiles7,
.nineTiles .nineTiles7 a,
.nineTiles .tile-7 img,
.nineTiles .tile-7 a {
  background-position: 0px -100px;
}
.nineTiles .nineTiles8 img,
.nineTiles img.nineTiles8,
.nineTiles .nineTiles8 a,
.nineTiles .tile-8 img,
.nineTiles .tile-8 a {
  background-position: -50px -100px;
}
.nineTiles .nineTiles9 img,
.nineTiles img.nineTiles9,
.nineTiles .nineTiles9 a,
.nineTiles .tile-9 img,
.nineTiles .tile-9 a {
  background-position: -100px -100px;
}
.tile-group,
.tile-strip {
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 20;
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  -ms-perspective: 800px;
  perspective: 800px;
}
.tile-group.one-tile,
.tile-strip.one-tile {
  height: 160px;
  width: 160px;
}
.tile-group.half-wide,
.tile-strip.half-wide {
  width: 80px;
}
.tile-group.one-wide,
.tile-strip.one-wide {
  width: 160px;
}
.tile-group.two-wide,
.tile-strip.two-wide {
  width: 320px;
}
.tile-group.three-wide,
.tile-strip.three-wide {
  width: 480px;
}
.tile-group.four-wide,
.tile-strip.four-wide {
  width: 640px;
}
.tile-group.five-wide,
.tile-strip.five-wide {
  width: 800px;
}
.tile-group.six-wide,
.tile-strip.six-wide {
  width: 960px;
}
.tile-group.seven-wide,
.tile-strip.seven-wide {
  width: 1120px;
}
.tile-group.eight-wide,
.tile-strip.eight-wide {
  width: 1280px;
}
.tile-group.nine-wide,
.tile-strip.nine-wide {
  width: 1440px;
}
.tile-group.ten-wide,
.tile-strip.ten-wide {
  width: 1600px;
}
.tile-group.half-tall,
.tile-strip.half-tall {
  height: 80px;
}
.tile-group.one-tall,
.tile-strip.one-tall {
  height: 160px;
}
.tile-group.two-tall,
.tile-strip.two-tall {
  height: 320px;
}
.tile-group.three-tall,
.tile-strip.three-tall {
  height: 480px;
}
.tile-group.four-tall,
.tile-strip.four-tall {
  height: 640px;
}
.tile-group.five-tall,
.tile-strip.five-tall {
  height: 800px;
}
.tile-group.six-tall,
.tile-strip.six-tall {
  height: 960px;
}
.tile-group.seven-tall,
.tile-strip.seven-tall {
  height: 1120px;
}
.tile-group.eight-tall,
.tile-strip.eight-tall {
  height: 1280px;
}
.tile-group.nine-tall,
.tile-strip.nine-tall {
  height: 1440px;
}
.tile-group.ten-tall,
.tile-strip.ten-tall {
  height: 1600px;
}
/* liveTile styles */
/*
.tile-strip,
.live-tile,
.list-tile,
.copy-tile,
.slide {
  float: left;
}
*/
.bounce {
  cursor: pointer;
  outline: 1px solid transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.bounce.bounce-c {
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.95, M12=0, M21=0, M22=0.95);
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
}
.bounce.bounce-t {
  -webkit-transform: rotateX(20deg);
  -ms-transform: rotateX(20deg);
  -moz-transform: rotateX(20deg);
  -o-transform: rotateX(20deg);
  transform: rotateX(20deg);
  -webkit-transform-origin: 0% 100%;
  -moz-transform-origin: 0% 100%;
  -ms-transform-origin: 0% 100%;
  -o-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
}
.bounce.bounce-b {
  -webkit-transform: rotateX(-20deg);
  -ms-transform: rotateX(-20deg);
  -moz-transform: rotateX(-20deg);
  -o-transform: rotateX(-20deg);
  transform: rotateX(-20deg);
  -webkit-transform-origin: 50% 0%;
  -moz-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  -o-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}
.bounce.bounce-r {
  -webkit-transform: rotateY(20deg);
  -ms-transform: rotateY(20deg);
  -moz-transform: rotateY(20deg);
  -o-transform: rotateY(20deg);
  transform: rotateY(20deg);
  -webkit-transform-origin: 0% 50%;
  -moz-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  -o-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
}
.bounce.bounce-l {
  -webkit-transform: rotateY(-20deg);
  -ms-transform: rotateY(-20deg);
  -moz-transform: rotateY(-20deg);
  -o-transform: rotateY(-20deg);
  transform: rotateY(-20deg);
  -webkit-transform-origin: 100% 50%;
  -moz-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  -o-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}
.bounce.bounce-tl {
  -webkit-transform: rotateX(10deg) rotateY(-10deg);
  -ms-transform: rotateX(10deg) rotateY(-10deg);
  -moz-transform: rotateX(10deg) rotateY(-10deg);
  -o-transform: rotateX(10deg) rotateY(-10deg);
  transform: rotateX(10deg) rotateY(-10deg);
}
.bounce.bounce-tr {
  -webkit-transform: rotateX(10deg) rotateY(10deg);
  -ms-transform: rotateX(10deg) rotateY(10deg);
  -moz-transform: rotateX(10deg) rotateY(10deg);
  -o-transform: rotateX(10deg) rotateY(10deg);
  transform: rotateX(10deg) rotateY(10deg);
}
.bounce.bounce-bl {
  -webkit-transform: rotateX(-10deg) rotateY(-10deg);
  -ms-transform: rotateX(-10deg) rotateY(-10deg);
  -moz-transform: rotateX(-10deg) rotateY(-10deg);
  -o-transform: rotateX(-10deg) rotateY(-10deg);
  transform: rotateX(-10deg) rotateY(-10deg);
}
.bounce.bounce-br {
  -webkit-transform: rotateX(-10deg) rotateY(10deg);
  -ms-transform: rotateX(-10deg) rotateY(10deg);
  -moz-transform: rotateX(-10deg) rotateY(10deg);
  -o-transform: rotateX(-10deg) rotateY(10deg);
  transform: rotateX(-10deg) rotateY(10deg);
}
.live-tile,
.list-tile,
.copy-tile {
  overflow: hidden;
  position: relative;
  z-index: 20;
}

.live-tile.fade {
opacity: 1;
}


.live-tile p,
.list-tile p,
.copy-tile p,
.live-tile .face,
.list-tile .face,
.copy-tile .face {
  display: block;
  margin: 0;
}
.live-tile .face,
.list-tile .face,
.copy-tile .face {
  padding: 0px;
}
.live-tile p,
.list-tile p,
.copy-tile p {
  padding: 10px;
}
.live-tile.half-tile p,
.list-tile.half-tile p,
.copy-tile.half-tile p {
  padding: 5px;
}
.live-tile .face.full,
.list-tile .face.full,
.copy-tile .face.full {
  height: 100%;
  width: 100%;
}
.live-tile img,
.list-tile img,
.copy-tile img {
  border: none;
}
.live-tile .tile-title,
.list-tile .tile-title,
.copy-tile .tile-title {
  position: absolute;
  bottom: 0px;
  font-size: 12px;
  left: 0px;
  padding: 0 0 6px 2%;
  text-decoration: none;
  width: 98%;
  z-index: 30;
}
.live-tile a,
.list-tile a,
.copy-tile a {
  color: #fff;
}
.light .copy-tile {
  color: #000;
}
.light .copy-tile.accent {
  color: #fff;
}
.light .copy-tile.accent a,
.light .copy-tile.accent a:link,
.light .copy-tile.accent a:visited,
.light .copy-tile.accent a:hover {
  color: #fff;
}
.light .copy-tile a,
.light .copy-tile a:link,
.light .copy-tile a:visited,
.light .copy-tile a:hover {
  color: #000;
}
.light .copy-tile .face,
.light .copy-tile .accent {
  color: #fff;
}
.light .copy-tile .face a,
.light .copy-tile .accent a,
.light .copy-tile .face a:link,
.light .copy-tile .accent a:link,
.light .copy-tile .face a:visited,
.light .copy-tile .accent a:visited,
.light .copy-tile .face a:hover,
.light .copy-tile .accent a:hover {
  color: #fff;
}
.copy-tile p {
  padding: 5px 5px 0px 5px;
  font-size: .95em;
}
.noselect > div,
.noselect > .slide,
.noselect > .slide-front,
.noselect > .slide-back,
.noselect > .fade-front,
.noselect > .fade-back,
.noselect > .flip-front,
.noselect > .flip-back {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.live-tile > div,
.list-tile > div,
.live-tile > .slide,
.carousel > .slide,
.live-tile > .slide-front,
.live-tile > .slide-back,
.live-tile > .fade-front,
.live-tile > .fade-back,
.live-tile > .flip-front,
.live-tile > .flip-back {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  top: 0px;
  left: 0px;
  margin: 0px;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 20;
}
.list-tile > div {
  position: relative;
  background-color: transparent;
}
img.full,
a.full,
.live-tile .full,
.list-tile .full {
  display: block;
  margin: 0px;
  height: 100%;
  text-decoration: none;
  width: 100%;
}
img.full-w,
a.full-w,
.live-tile .full-w,
.list-tile .full-w {
  display: block;
  margin: 0px;
  text-decoration: none;
  width: 100%;
}
img.full-h,
a.full-h,
.live-tile .full-h,
.list-tile .full-h {
  display: block;
  margin: 0px;
  height: 100%;
}
.live-tile > .back,
.live-tile > .slide-back,
.live-tile > .fade-back,
.live-tile > .flip-back,
.flip-list .flip-back {
  position: absolute;
  z-index: 10;
}
/*  ========== flip-list Styles ==========
	.flip-list
	---li
	------div.flip-front
	------div.flip-back
*/
.flip-list {
  padding: 0px;
  margin: 0px;
}
.list-tile .flip-list {
  height: 100%;
  width: 100%;
}
.flip-list > li > a {
  border: none;
  outline: none;
  text-decoration: none;
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: 100%;
}
.flip-list > li img {
  border: none;
  outline: none;
  height: 100%;
  margin: 0px;
  padding: 0px;
  width: 100%;
}
.flip-list > li {
  float: left;
  list-style-type: none;
  outline: none;
  position: relative;
}
.flip-list > li > div {
  border: none;
  background: white;
  height: 100%;
  left: 0px;
  margin: 0px;
  overflow: hidden;
  position: absolute;
  padding: 0px;
  top: 0px;
  width: 100%;
  z-index: 20;
}
.flip-list > li > div.flip-back {
  height: 0px;
}
/* ------ Hardware Accelerated Tiles --------*/
/* flip */
.live-tile.flip.ha,
.flip-list.ha {
  position: relative;
  overflow: hidden;
}
.live-tile.flip .ha.flip-front,
.flip-list .ha.flip-front,
.live-tile.flip .ha.flip-back,
.flip-list .ha.flip-back {
  position: absolute;
  z-index: 20;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.flip-list > li > .ha.flip-back,
.flip-list > li > .ha.flip-back img {
  /* override the values set for margin and ensure the back tile is visible  */

  margin: 0px !important;
  height: 100%;
}

.metroBig,
.metroExtraLarge,
.face.metroBig,
.face.metroExtraLarge,
.live-tile .metroExtraLarge {
  font-size: 2em;
  line-height: 1.1em;
}
a.metroBig:link,
a.metroExtraLarge:link,
a.face.metroBig:link,
a.face.metroExtraLarge:link,
a.live-tile .metroExtraLarge:link {
  text-decoration: none;
}
.metroLarger,
.face.metroLarger,
.live-tile .metroLarger {
  font-size: 1.5em;
  line-height: 1.1em;
}
a.metroLarger:link,
a.face.metroLarger:link,
a.live-tile .metroLarger:link {
  text-decoration: none;
}
.metroLarge,
.face.metroLarge,
.live-tile .metroLarge {
  font-size: 1.2em;
  line-height: 1.1em;
}
a.metroLarge:link,
a.face.metroLarge:link,
a.live-tile .metroLarge:link {
  text-decoration: none;
}
.metroSmall,
.face.metroSmall,
.live-tile .metroSmall {
  font-size: .9em;
  line-height: 1em;
}
a.metroSmall:link,
a.face.metroSmall:link,
a.live-tile .metroSmall:link {
  text-decoration: none;
}
.metroSmaller,
.face.metroSmaller,
.live-tile .metroSmaller {
  font-size: .8em;
  line-height: 1em;
}
a.metroSmaller:link,
a.face.metroSmaller:link,
a.live-tile .metroSmaller:link {
  text-decoration: none;
}
.metroExtraSmall,
.face.metroExtraSmall,
.live-tile .metroExtraSmall {
  font-size: .7em;
  line-height: 1em;
}
a.metroExtraSmall:link,
a.face.metroExtraSmall:link,
a.live-tile .metroExtraSmall:link {
  text-decoration: none;
}
.live-tile.me .metroBig,
.live-tile .bottom {
  display: block;
  position: absolute;
  left: 10px;
  bottom: 10px;
  padding: 0;
  text-decoration: none;
}
.live-tile.half-tile .bottom {
  left: 5px;
}
/***** Theme Definitions *****/
.light {
  background-color: #fff;
  color: #000;
}
.dark {
  background-color: #000;
  color: #fff;
}
.light.accent {
  background-color: #fff;
}
.dark.accent {
  background-color: #000;
}
.light .appbar {
  background-color: #dfdfdf;
}
.dark .appbar {
  background-color: #212021;
}
/*live tile accent colors */
.amber .accent,
.amber .accentBg,
.amber .accentBgHover:hover,
.amber .accentBgHover a:hover,
.amber .live-tile > div,
.amber .copy-tile > div,
.amber .live-tile > .face,
.amber .copy-tile > .face,
.amber .live-tile > .slide,
.amber .live-tile > .flip-back,
.amber .live-tile > .flip-front,
.amber .live-tile > .slide-back,
.amber .live-tile > .slide-front,
.amber .list-tile > div,
.amber .flip-list > li > div {
  background-color: #f1a30b;
}
.amber .accentColor,
.amber .accentHover:hover,
.amber .accentHover a:hover,
.amber .accentLink a,
.amber .accentLink a:hover,
.amber .accentLink a:visited,
.amber a.accentLink,
.amber a.accentLink:hover,
.amber a.accentLink:visited {
  color: #f1a30b;
}
.amber .accentBorder {
  border-color: #f1a30b;
}
.amber .accentBorderBottom {
  border-bottom-color: #f1a30b;
}
.amber .accentBorderLeft {
  border-left-color: #f1a30b;
}
.amber .accentBorderRight {
  border-right-color: #f1a30b;
}
.amber .accentBorderTop {
  border-top-color: #f1a30b;
}
.blue .accent,
.blue .accentBg,
.blue .accentBgHover:hover,
.blue .accentBgHover a:hover,
.blue .live-tile > div,
.blue .copy-tile > div,
.blue .live-tile > .face,
.blue .copy-tile > .face,
.blue .live-tile > .slide,
.blue .live-tile > .flip-back,
.blue .live-tile > .flip-front,
.blue .live-tile > .slide-back,
.blue .live-tile > .slide-front,
.blue .list-tile > div,
.blue .flip-list > li > div {
  background-color: #1ba1e2;
}
.blue .accentColor,
.blue .accentHover:hover,
.blue .accentHover a:hover,
.blue .accentLink a,
.blue .accentLink a:hover,
.blue .accentLink a:visited,
.blue a.accentLink,
.blue a.accentLink:hover,
.blue a.accentLink:visited {
  color: #1ba1e2;
}
.blue .accentBorder {
  border-color: #1ba1e2;
}
.blue .accentBorderBottom {
  border-bottom-color: #1ba1e2;
}
.blue .accentBorderLeft {
  border-left-color: #1ba1e2;
}
.blue .accentBorderRight {
  border-right-color: #1ba1e2;
}
.blue .accentBorderTop {
  border-top-color: #1ba1e2;
}
.brown .accent,
.brown .accentBg,
.brown .accentBgHover:hover,
.brown .accentBgHover a:hover,
.brown .live-tile > div,
.brown .copy-tile > div,
.brown .live-tile > .face,
.brown .copy-tile > .face,
.brown .live-tile > .slide,
.brown .live-tile > .flip-back,
.brown .live-tile > .flip-front,
.brown .live-tile > .slide-back,
.brown .live-tile > .slide-front,
.brown .list-tile > div,
.brown .flip-list > li > div {
  background-color: #a05000;
}
.brown .accentColor,
.brown .accentHover:hover,
.brown .accentHover a:hover,
.brown .accentLink a,
.brown .accentLink a:hover,
.brown .accentLink a:visited,
.brown a.accentLink,
.brown a.accentLink:hover,
.brown a.accentLink:visited {
  color: #a05000;
}
.brown .accentBorder {
  border-color: #a05000;
}
.brown .accentBorderBottom {
  border-bottom-color: #a05000;
}
.brown .accentBorderLeft {
  border-left-color: #a05000;
}
.brown .accentBorderRight {
  border-right-color: #a05000;
}
.brown .accentBorderTop {
  border-top-color: #a05000;
}
.cobalt .accent,
.cobalt .accentBg,
.cobalt .accentBgHover:hover,
.cobalt .accentBgHover a:hover,
.cobalt .live-tile > div,
.cobalt .copy-tile > div,
.cobalt .live-tile > .face,
.cobalt .copy-tile > .face,
.cobalt .live-tile > .slide,
.cobalt .live-tile > .flip-back,
.cobalt .live-tile > .flip-front,
.cobalt .live-tile > .slide-back,
.cobalt .live-tile > .slide-front,
.cobalt .list-tile > div,
.cobalt .flip-list > li > div {
  background-color: #0050ef;
}
.cobalt .accentColor,
.cobalt .accentHover:hover,
.cobalt .accentHover a:hover,
.cobalt .accentLink a,
.cobalt .accentLink a:hover,
.cobalt .accentLink a:visited,
.cobalt a.accentLink,
.cobalt a.accentLink:hover,
.cobalt a.accentLink:visited {
  color: #0050ef;
}
.cobalt .accentBorder {
  border-color: #0050ef;
}
.cobalt .accentBorderBottom {
  border-bottom-color: #0050ef;
}
.cobalt .accentBorderLeft {
  border-left-color: #0050ef;
}
.cobalt .accentBorderRight {
  border-right-color: #0050ef;
}
.cobalt .accentBorderTop {
  border-top-color: #0050ef;
}
.crimson .accent,
.crimson .accentBg,
.crimson .accentBgHover:hover,
.crimson .accentBgHover a:hover,
.crimson .live-tile > div,
.crimson .copy-tile > div,
.crimson .live-tile > .face,
.crimson .copy-tile > .face,
.crimson .live-tile > .slide,
.crimson .live-tile > .flip-back,
.crimson .live-tile > .flip-front,
.crimson .live-tile > .slide-back,
.crimson .live-tile > .slide-front,
.crimson .list-tile > div,
.crimson .flip-list > li > div {
  background-color: #a20025;
}
.crimson .accentColor,
.crimson .accentHover:hover,
.crimson .accentHover a:hover,
.crimson .accentLink a,
.crimson .accentLink a:hover,
.crimson .accentLink a:visited,
.crimson a.accentLink,
.crimson a.accentLink:hover,
.crimson a.accentLink:visited {
  color: #a20025;
}
.crimson .accentBorder {
  border-color: #a20025;
}
.crimson .accentBorderBottom {
  border-bottom-color: #a20025;
}
.crimson .accentBorderLeft {
  border-left-color: #a20025;
}
.crimson .accentBorderRight {
  border-right-color: #a20025;
}
.crimson .accentBorderTop {
  border-top-color: #a20025;
}
.cyan .accent,
.cyan .accentBg,
.cyan .accentBgHover:hover,
.cyan .accentBgHover a:hover,
.cyan .live-tile > div,
.cyan .copy-tile > div,
.cyan .live-tile > .face,
.cyan .copy-tile > .face,
.cyan .live-tile > .slide,
.cyan .live-tile > .flip-back,
.cyan .live-tile > .flip-front,
.cyan .live-tile > .slide-back,
.cyan .live-tile > .slide-front,
.cyan .list-tile > div,
.cyan .flip-list > li > div {
  background-color: #1ba0e1;
}
.cyan .accentColor,
.cyan .accentHover:hover,
.cyan .accentHover a:hover,
.cyan .accentLink a,
.cyan .accentLink a:hover,
.cyan .accentLink a:visited,
.cyan a.accentLink,
.cyan a.accentLink:hover,
.cyan a.accentLink:visited {
  color: #1ba0e1;
}
.cyan .accentBorder {
  border-color: #1ba0e1;
}
.cyan .accentBorderBottom {
  border-bottom-color: #1ba0e1;
}
.cyan .accentBorderLeft {
  border-left-color: #1ba0e1;
}
.cyan .accentBorderRight {
  border-right-color: #1ba0e1;
}
.cyan .accentBorderTop {
  border-top-color: #1ba0e1;
}
.emerald .accent,
.emerald .accentBg,
.emerald .accentBgHover:hover,
.emerald .accentBgHover a:hover,
.emerald .live-tile > div,
.emerald .copy-tile > div,
.emerald .live-tile > .face,
.emerald .copy-tile > .face,
.emerald .live-tile > .slide,
.emerald .live-tile > .flip-back,
.emerald .live-tile > .flip-front,
.emerald .live-tile > .slide-back,
.emerald .live-tile > .slide-front,
.emerald .list-tile > div,
.emerald .flip-list > li > div {
  background-color: #008a00;
}
.emerald .accentColor,
.emerald .accentHover:hover,
.emerald .accentHover a:hover,
.emerald .accentLink a,
.emerald .accentLink a:hover,
.emerald .accentLink a:visited,
.emerald a.accentLink,
.emerald a.accentLink:hover,
.emerald a.accentLink:visited {
  color: #008a00;
}
.emerald .accentBorder {
  border-color: #008a00;
}
.emerald .accentBorderBottom {
  border-bottom-color: #008a00;
}
.emerald .accentBorderLeft {
  border-left-color: #008a00;
}
.emerald .accentBorderRight {
  border-right-color: #008a00;
}
.emerald .accentBorderTop {
  border-top-color: #008a00;
}
.green .accent,
.green .accentBg,
.green .accentBgHover:hover,
.green .accentBgHover a:hover,
.green .live-tile > div,
.green .copy-tile > div,
.green .live-tile > .face,
.green .copy-tile > .face,
.green .live-tile > .slide,
.green .live-tile > .flip-back,
.green .live-tile > .flip-front,
.green .live-tile > .slide-back,
.green .live-tile > .slide-front,
.green .list-tile > div,
.green .flip-list > li > div {
  background-color: #339933;
}
.green .accentColor,
.green .accentHover:hover,
.green .accentHover a:hover,
.green .accentLink a,
.green .accentLink a:hover,
.green .accentLink a:visited,
.green a.accentLink,
.green a.accentLink:hover,
.green a.accentLink:visited {
  color: #339933;
}
.green .accentBorder {
  border-color: #339933;
}
.green .accentBorderBottom {
  border-bottom-color: #339933;
}
.green .accentBorderLeft {
  border-left-color: #339933;
}
.green .accentBorderRight {
  border-right-color: #339933;
}
.green .accentBorderTop {
  border-top-color: #339933;
}
.indigo .accent,
.indigo .accentBg,
.indigo .accentBgHover:hover,
.indigo .accentBgHover a:hover,
.indigo .live-tile > div,
.indigo .copy-tile > div,
.indigo .live-tile > .face,
.indigo .copy-tile > .face,
.indigo .live-tile > .slide,
.indigo .live-tile > .flip-back,
.indigo .live-tile > .flip-front,
.indigo .live-tile > .slide-back,
.indigo .live-tile > .slide-front,
.indigo .list-tile > div,
.indigo .flip-list > li > div {
  background-color: #6a00ff;
}
.indigo .accentColor,
.indigo .accentHover:hover,
.indigo .accentHover a:hover,
.indigo .accentLink a,
.indigo .accentLink a:hover,
.indigo .accentLink a:visited,
.indigo a.accentLink,
.indigo a.accentLink:hover,
.indigo a.accentLink:visited {
  color: #6a00ff;
}
.indigo .accentBorder {
  border-color: #6a00ff;
}
.indigo .accentBorderBottom {
  border-bottom-color: #6a00ff;
}
.indigo .accentBorderLeft {
  border-left-color: #6a00ff;
}
.indigo .accentBorderRight {
  border-right-color: #6a00ff;
}
.indigo .accentBorderTop {
  border-top-color: #6a00ff;
}
.lime .accent,
.lime .accentBg,
.lime .accentBgHover:hover,
.lime .accentBgHover a:hover,
.lime .live-tile > div,
.lime .copy-tile > div,
.lime .live-tile > .face,
.lime .copy-tile > .face,
.lime .live-tile > .slide,
.lime .live-tile > .flip-back,
.lime .live-tile > .flip-front,
.lime .live-tile > .slide-back,
.lime .live-tile > .slide-front,
.lime .list-tile > div,
.lime .flip-list > li > div {
  background-color: #a2c139;
}
.lime .accentColor,
.lime .accentHover:hover,
.lime .accentHover a:hover,
.lime .accentLink a,
.lime .accentLink a:hover,
.lime .accentLink a:visited,
.lime a.accentLink,
.lime a.accentLink:hover,
.lime a.accentLink:visited {
  color: #a2c139;
}
.lime .accentBorder {
  border-color: #a2c139;
}
.lime .accentBorderBottom {
  border-bottom-color: #a2c139;
}
.lime .accentBorderLeft {
  border-left-color: #a2c139;
}
.lime .accentBorderRight {
  border-right-color: #a2c139;
}
.lime .accentBorderTop {
  border-top-color: #a2c139;
}
.magenta .accent,
.magenta .accentBg,
.magenta .accentBgHover:hover,
.magenta .accentBgHover a:hover,
.magenta .live-tile > div,
.magenta .copy-tile > div,
.magenta .live-tile > .face,
.magenta .copy-tile > .face,
.magenta .live-tile > .slide,
.magenta .live-tile > .flip-back,
.magenta .live-tile > .flip-front,
.magenta .live-tile > .slide-back,
.magenta .live-tile > .slide-front,
.magenta .list-tile > div,
.magenta .flip-list > li > div {
  background-color: #d80073;
}
.magenta .accentColor,
.magenta .accentHover:hover,
.magenta .accentHover a:hover,
.magenta .accentLink a,
.magenta .accentLink a:hover,
.magenta .accentLink a:visited,
.magenta a.accentLink,
.magenta a.accentLink:hover,
.magenta a.accentLink:visited {
  color: #d80073;
}
.magenta .accentBorder {
  border-color: #d80073;
}
.magenta .accentBorderBottom {
  border-bottom-color: #d80073;
}
.magenta .accentBorderLeft {
  border-left-color: #d80073;
}
.magenta .accentBorderRight {
  border-right-color: #d80073;
}
.magenta .accentBorderTop {
  border-top-color: #d80073;
}
.mango .accent,
.mango .accentBg,
.mango .accentBgHover:hover,
.mango .accentBgHover a:hover,
.mango .live-tile > div,
.mango .copy-tile > div,
.mango .live-tile > .face,
.mango .copy-tile > .face,
.mango .live-tile > .slide,
.mango .live-tile > .flip-back,
.mango .live-tile > .flip-front,
.mango .live-tile > .slide-back,
.mango .live-tile > .slide-front,
.mango .list-tile > div,
.mango .flip-list > li > div {
  background-color: #f09609;
}
.mango .accentColor,
.mango .accentHover:hover,
.mango .accentHover a:hover,
.mango .accentLink a,
.mango .accentLink a:hover,
.mango .accentLink a:visited,
.mango a.accentLink,
.mango a.accentLink:hover,
.mango a.accentLink:visited {
  color: #f09609;
}
.mango .accentBorder {
  border-color: #f09609;
}
.mango .accentBorderBottom {
  border-bottom-color: #f09609;
}
.mango .accentBorderLeft {
  border-left-color: #f09609;
}
.mango .accentBorderRight {
  border-right-color: #f09609;
}
.mango .accentBorderTop {
  border-top-color: #f09609;
}
.mauve .accent,
.mauve .accentBg,
.mauve .accentBgHover:hover,
.mauve .accentBgHover a:hover,
.mauve .live-tile > div,
.mauve .copy-tile > div,
.mauve .live-tile > .face,
.mauve .copy-tile > .face,
.mauve .live-tile > .slide,
.mauve .live-tile > .flip-back,
.mauve .live-tile > .flip-front,
.mauve .live-tile > .slide-back,
.mauve .live-tile > .slide-front,
.mauve .list-tile > div,
.mauve .flip-list > li > div {
  background-color: #765f89;
}
.mauve .accentColor,
.mauve .accentHover:hover,
.mauve .accentHover a:hover,
.mauve .accentLink a,
.mauve .accentLink a:hover,
.mauve .accentLink a:visited,
.mauve a.accentLink,
.mauve a.accentLink:hover,
.mauve a.accentLink:visited {
  color: #765f89;
}
.mauve .accentBorder {
  border-color: #765f89;
}
.mauve .accentBorderBottom {
  border-bottom-color: #765f89;
}
.mauve .accentBorderLeft {
  border-left-color: #765f89;
}
.mauve .accentBorderRight {
  border-right-color: #765f89;
}
.mauve .accentBorderTop {
  border-top-color: #765f89;
}
.olive .accent,
.olive .accentBg,
.olive .accentBgHover:hover,
.olive .accentBgHover a:hover,
.olive .live-tile > div,
.olive .copy-tile > div,
.olive .live-tile > .face,
.olive .copy-tile > .face,
.olive .live-tile > .slide,
.olive .live-tile > .flip-back,
.olive .live-tile > .flip-front,
.olive .live-tile > .slide-back,
.olive .live-tile > .slide-front,
.olive .list-tile > div,
.olive .flip-list > li > div {
  background-color: #6d8764;
}
.olive .accentColor,
.olive .accentHover:hover,
.olive .accentHover a:hover,
.olive .accentLink a,
.olive .accentLink a:hover,
.olive .accentLink a:visited,
.olive a.accentLink,
.olive a.accentLink:hover,
.olive a.accentLink:visited {
  color: #6d8764;
}
.olive .accentBorder {
  border-color: #6d8764;
}
.olive .accentBorderBottom {
  border-bottom-color: #6d8764;
}
.olive .accentBorderLeft {
  border-left-color: #6d8764;
}
.olive .accentBorderRight {
  border-right-color: #6d8764;
}
.olive .accentBorderTop {
  border-top-color: #6d8764;
}
.orange .accent,
.orange .accentBg,
.orange .accentBgHover:hover,
.orange .accentBgHover a:hover,
.orange .live-tile > div,
.orange .copy-tile > div,
.orange .live-tile > .face,
.orange .copy-tile > .face,
.orange .live-tile > .slide,
.orange .live-tile > .flip-back,
.orange .live-tile > .flip-front,
.orange .live-tile > .slide-back,
.orange .live-tile > .slide-front,
.orange .list-tile > div,
.orange .flip-list > li > div {
  background-color: #fa6801;
}
.orange .accentColor,
.orange .accentHover:hover,
.orange .accentHover a:hover,
.orange .accentLink a,
.orange .accentLink a:hover,
.orange .accentLink a:visited,
.orange a.accentLink,
.orange a.accentLink:hover,
.orange a.accentLink:visited {
  color: #fa6801;
}
.orange .accentBorder {
  border-color: #fa6801;
}
.orange .accentBorderBottom {
  border-bottom-color: #fa6801;
}
.orange .accentBorderLeft {
  border-left-color: #fa6801;
}
.orange .accentBorderRight {
  border-right-color: #fa6801;
}
.orange .accentBorderTop {
  border-top-color: #fa6801;
}
.pink .accent,
.pink .accentBg,
.pink .accentBgHover:hover,
.pink .accentBgHover a:hover,
.pink .live-tile > div,
.pink .copy-tile > div,
.pink .live-tile > .face,
.pink .copy-tile > .face,
.pink .live-tile > .slide,
.pink .live-tile > .flip-back,
.pink .live-tile > .flip-front,
.pink .live-tile > .slide-back,
.pink .live-tile > .slide-front,
.pink .list-tile > div,
.pink .flip-list > li > div {
  background-color: #e671b8;
}
.pink .accentColor,
.pink .accentHover:hover,
.pink .accentHover a:hover,
.pink .accentLink a,
.pink .accentLink a:hover,
.pink .accentLink a:visited,
.pink a.accentLink,
.pink a.accentLink:hover,
.pink a.accentLink:visited {
  color: #e671b8;
}
.pink .accentBorder {
  border-color: #e671b8;
}
.pink .accentBorderBottom {
  border-bottom-color: #e671b8;
}
.pink .accentBorderLeft {
  border-left-color: #e671b8;
}
.pink .accentBorderRight {
  border-right-color: #e671b8;
}
.pink .accentBorderTop {
  border-top-color: #e671b8;
}
.purple .accent,
.purple .accentBg,
.purple .accentBgHover:hover,
.purple .accentBgHover a:hover,
.purple .live-tile > div,
.purple .copy-tile > div,
.purple .live-tile > .face,
.purple .copy-tile > .face,
.purple .live-tile > .slide,
.purple .live-tile > .flip-back,
.purple .live-tile > .flip-front,
.purple .live-tile > .slide-back,
.purple .live-tile > .slide-front,
.purple .list-tile > div,
.purple .flip-list > li > div {
  background-color: #a200ff;
}
.purple .accentColor,
.purple .accentHover:hover,
.purple .accentHover a:hover,
.purple .accentLink a,
.purple .accentLink a:hover,
.purple .accentLink a:visited,
.purple a.accentLink,
.purple a.accentLink:hover,
.purple a.accentLink:visited {
  color: #a200ff;
}
.purple .accentBorder {
  border-color: #a200ff;
}
.purple .accentBorderBottom {
  border-bottom-color: #a200ff;
}
.purple .accentBorderLeft {
  border-left-color: #a200ff;
}
.purple .accentBorderRight {
  border-right-color: #a200ff;
}
.purple .accentBorderTop {
  border-top-color: #a200ff;
}
.violet .accent,
.violet .accentBg,
.violet .accentBgHover:hover,
.violet .accentBgHover a:hover,
.violet .live-tile > div,
.violet .copy-tile > div,
.violet .live-tile > .face,
.violet .copy-tile > .face,
.violet .live-tile > .slide,
.violet .live-tile > .flip-back,
.violet .live-tile > .flip-front,
.violet .live-tile > .slide-back,
.violet .live-tile > .slide-front,
.violet .list-tile > div,
.violet .flip-list > li > div {
  background-color: #a200ff;
}
.violet .accentColor,
.violet .accentHover:hover,
.violet .accentHover a:hover,
.violet .accentLink a,
.violet .accentLink a:hover,
.violet .accentLink a:visited,
.violet a.accentLink,
.violet a.accentLink:hover,
.violet a.accentLink:visited {
  color: #a200ff;
}
.violet .accentBorder {
  border-color: #a200ff;
}
.violet .accentBorderBottom {
  border-bottom-color: #a200ff;
}
.violet .accentBorderLeft {
  border-left-color: #a200ff;
}
.violet .accentBorderRight {
  border-right-color: #a200ff;
}
.violet .accentBorderTop {
  border-top-color: #a200ff;
}
.red .accent,
.red .accentBg,
.red .accentBgHover:hover,
.red .accentBgHover a:hover,
.red .live-tile > div,
.red .copy-tile > div,
.red .live-tile > .face,
.red .copy-tile > .face,
.red .live-tile > .slide,
.red .live-tile > .flip-back,
.red .live-tile > .flip-front,
.red .live-tile > .slide-back,
.red .live-tile > .slide-front,
.red .list-tile > div,
.red .flip-list > li > div {
  background-color: #e51400;
}
.red .accentColor,
.red .accentHover:hover,
.red .accentHover a:hover,
.red .accentLink a,
.red .accentLink a:hover,
.red .accentLink a:visited,
.red a.accentLink,
.red a.accentLink:hover,
.red a.accentLink:visited {
  color: #e51400;
}
.red .accentBorder {
  border-color: #e51400;
}
.red .accentBorderBottom {
  border-bottom-color: #e51400;
}
.red .accentBorderLeft {
  border-left-color: #e51400;
}
.red .accentBorderRight {
  border-right-color: #e51400;
}
.red .accentBorderTop {
  border-top-color: #e51400;
}
.sienna .accent,
.sienna .accentBg,
.sienna .accentBgHover:hover,
.sienna .accentBgHover a:hover,
.sienna .live-tile > div,
.sienna .copy-tile > div,
.sienna .live-tile > .face,
.sienna .copy-tile > .face,
.sienna .live-tile > .slide,
.sienna .live-tile > .flip-back,
.sienna .live-tile > .flip-front,
.sienna .live-tile > .slide-back,
.sienna .live-tile > .slide-front,
.sienna .list-tile > div,
.sienna .flip-list > li > div {
  background-color: #7b3a3e;
}
.sienna .accentColor,
.sienna .accentHover:hover,
.sienna .accentHover a:hover,
.sienna .accentLink a,
.sienna .accentLink a:hover,
.sienna .accentLink a:visited,
.sienna a.accentLink,
.sienna a.accentLink:hover,
.sienna a.accentLink:visited {
  color: #7b3a3e;
}
.sienna .accentBorder {
  border-color: #7b3a3e;
}
.sienna .accentBorderBottom {
  border-bottom-color: #7b3a3e;
}
.sienna .accentBorderLeft {
  border-left-color: #7b3a3e;
}
.sienna .accentBorderRight {
  border-right-color: #7b3a3e;
}
.sienna .accentBorderTop {
  border-top-color: #7b3a3e;
}
.steel .accent,
.steel .accentBg,
.steel .accentBgHover:hover,
.steel .accentBgHover a:hover,
.steel .live-tile > div,
.steel .copy-tile > div,
.steel .live-tile > .face,
.steel .copy-tile > .face,
.steel .live-tile > .slide,
.steel .live-tile > .flip-back,
.steel .live-tile > .flip-front,
.steel .live-tile > .slide-back,
.steel .live-tile > .slide-front,
.steel .list-tile > div,
.steel .flip-list > li > div {
  background-color: #657688;
}
.steel .accentColor,
.steel .accentHover:hover,
.steel .accentHover a:hover,
.steel .accentLink a,
.steel .accentLink a:hover,
.steel .accentLink a:visited,
.steel a.accentLink,
.steel a.accentLink:hover,
.steel a.accentLink:visited {
  color: #657688;
}
.steel .accentBorder {
  border-color: #657688;
}
.steel .accentBorderBottom {
  border-bottom-color: #657688;
}
.steel .accentBorderLeft {
  border-left-color: #657688;
}
.steel .accentBorderRight {
  border-right-color: #657688;
}
.steel .accentBorderTop {
  border-top-color: #657688;
}
.teal .accent,
.teal .accentBg,
.teal .accentBgHover:hover,
.teal .accentBgHover a:hover,
.teal .live-tile > div,
.teal .copy-tile > div,
.teal .live-tile > .face,
.teal .copy-tile > .face,
.teal .live-tile > .slide,
.teal .live-tile > .flip-back,
.teal .live-tile > .flip-front,
.teal .live-tile > .slide-back,
.teal .live-tile > .slide-front,
.teal .list-tile > div,
.teal .flip-list > li > div {
  background-color: #00aba9;
}
.teal .accentColor,
.teal .accentHover:hover,
.teal .accentHover a:hover,
.teal .accentLink a,
.teal .accentLink a:hover,
.teal .accentLink a:visited,
.teal a.accentLink,
.teal a.accentLink:hover,
.teal a.accentLink:visited {
  color: #00aba9;
}
.teal .accentBorder {
  border-color: #00aba9;
}
.teal .accentBorderBottom {
  border-bottom-color: #00aba9;
}
.teal .accentBorderLeft {
  border-left-color: #00aba9;
}
.teal .accentBorderRight {
  border-right-color: #00aba9;
}
.teal .accentBorderTop {
  border-top-color: #00aba9;
}
.yellow .accent,
.yellow .accentBg,
.yellow .accentBgHover:hover,
.yellow .accentBgHover a:hover,
.yellow .live-tile > div,
.yellow .copy-tile > div,
.yellow .live-tile > .face,
.yellow .copy-tile > .face,
.yellow .live-tile > .slide,
.yellow .live-tile > .flip-back,
.yellow .live-tile > .flip-front,
.yellow .live-tile > .slide-back,
.yellow .live-tile > .slide-front,
.yellow .list-tile > div,
.yellow .flip-list > li > div {
  background-color: #d8c101;
}
.yellow .accentColor,
.yellow .accentHover:hover,
.yellow .accentHover a:hover,
.yellow .accentLink a,
.yellow .accentLink a:hover,
.yellow .accentLink a:visited,
.yellow a.accentLink,
.yellow a.accentLink:hover,
.yellow a.accentLink:visited {
  color: #d8c101;
}
.yellow .accentBorder {
  border-color: #d8c101;
}
.yellow .accentBorderBottom {
  border-bottom-color: #d8c101;
}
.yellow .accentBorderLeft {
  border-left-color: #d8c101;
}
.yellow .accentBorderRight {
  border-right-color: #d8c101;
}
.yellow .accentBorderTop {
  border-top-color: #d8c101;
}
.trans .accent,
.trans .accentBg,
.trans .accentBgHover:hover,
.trans .accentBgHover a:hover,
.trans .live-tile > div,
.trans .copy-tile > div,
.trans .live-tile > .face,
.trans .copy-tile > .face,
.trans .live-tile > .slide,
.trans .live-tile > .flip-back,
.trans .live-tile > .flip-front,
.trans .live-tile > .slide-back,
.trans .live-tile > .slide-front,
.trans .list-tile > div,
.trans .flip-list > li > div {
  background-color: transparent;
}
.trans .accentColor,
.trans .accentHover:hover,
.trans .accentHover a:hover,
.trans .accentLink a,
.trans .accentLink a:hover,
.trans .accentLink a:visited,
.trans a.accentLink,
.trans a.accentLink:hover,
.trans a.accentLink:visited {
  color: transparent;
}
.trans .accentBorder {
  border-color: transparent;
}
.trans .accentBorderBottom {
  border-bottom-color: transparent;
}
.trans .accentBorderLeft {
  border-left-color: transparent;
}
.trans .accentBorderRight {
  border-right-color: transparent;
}
.trans .accentBorderTop {
  border-top-color: transparent;
}
.white .accent,
.white .accentBg,
.white .accentBgHover:hover,
.white .accentBgHover a:hover,
.white .live-tile > div,
.white .copy-tile > div,
.white .live-tile > .face,
.white .copy-tile > .face,
.white .live-tile > .slide,
.white .live-tile > .flip-back,
.white .live-tile > .flip-front,
.white .live-tile > .slide-back,
.white .live-tile > .slide-front,
.white .list-tile > div,
.white .flip-list > li > div {
  background-color: #ffffff;
}
.white .accentColor,
.white .accentHover:hover,
.white .accentHover a:hover,
.white .accentLink a,
.white .accentLink a:hover,
.white .accentLink a:visited,
.white a.accentLink,
.white a.accentLink:hover,
.white a.accentLink:visited {
  color: #ffffff;
}
.white .accentBorder {
  border-color: #ffffff;
}
.white .accentBorderBottom {
  border-bottom-color: #ffffff;
}
.white .accentBorderLeft {
  border-left-color: #ffffff;
}
.white .accentBorderRight {
  border-right-color: #ffffff;
}
.white .accentBorderTop {
  border-top-color: #ffffff;
}
.black .accent,
.black .accentBg,
.black .accentBgHover:hover,
.black .accentBgHover a:hover,
.black .live-tile > div,
.black .copy-tile > div,
.black .live-tile > .face,
.black .copy-tile > .face,
.black .live-tile > .slide,
.black .live-tile > .flip-back,
.black .live-tile > .flip-front,
.black .live-tile > .slide-back,
.black .live-tile > .slide-front,
.black .list-tile > div,
.black .flip-list > li > div {
  background-color: #000000;
}
.black .accentColor,
.black .accentHover:hover,
.black .accentHover a:hover,
.black .accentLink a,
.black .accentLink a:hover,
.black .accentLink a:visited,
.black a.accentLink,
.black a.accentLink:hover,
.black a.accentLink:visited {
  color: #000000;
}
.black .accentBorder {
  border-color: #000000;
}
.black .accentBorderBottom {
  border-bottom-color: #000000;
}
.black .accentBorderLeft {
  border-left-color: #000000;
}
.black .accentBorderRight {
  border-right-color: #000000;
}
.black .accentBorderTop {
  border-top-color: #000000;
}
/* Local style overrides */
html body .amber.accent,
html body .amber.live-tile > .accent,
html body .amber.list-tile > .accent,
html body .amber.copy-tile > .accent,
html body .amber.live-tile > div,
html body .amber.copy-tile > div,
html body .amber.list-tile > div,
html body .amber.live-tile > .face,
html body .amber.list-tile > .face,
html body .amber.copy-tile > .face,
html body .amber.live-tile > .slide,
html body .amber.live-tile > .flip-back,
html body .amber.live-tile > .flip-front,
html body .amber.live-tile > .slide-back,
html body .amber.live-tile > .slide-front,
html body .amber.flip-list > li > .accent,
html body .amber.flip-list > li > .flip-back,
html body .amber.flip-list > li > .flip-front,
html body .amber.slide.accent,
html body .amber.slide-front.accent,
html body .amber.slide-back.accent,
html body .amber.flip-front.accent,
html body .amber.flip-back.accent {
  background-color: #f1a30b;
}
html body .amber.accentColor,
html body .amber.accentHover:hover,
html body .amber.accentHover a:hover,
html body .amber.accentLink a,
html body .amber.accentLink a:hover,
html body .amber.accentLink a:visited,
a.accentLinkhtml body .amber,
a.accentLinkhtml body .amber:hover,
a.accentLinkhtml body .amber:visited {
  color: #f1a30b;
}
html body .amber.accentBorder {
  border-color: #f1a30b;
}
html body .amber.accentBorderBottom {
  border-bottom-color: #f1a30b;
}
html body .amber.accentBorderLeft {
  border-left-color: #f1a30b;
}
html body .amber.accentBorderRight {
  border-right-color: #f1a30b;
}
html body .amber.accentBorderTop {
  border-top-color: #f1a30b;
}
html body .blue.accent,
html body .blue.live-tile > .accent,
html body .blue.list-tile > .accent,
html body .blue.copy-tile > .accent,
html body .blue.live-tile > div,
html body .blue.copy-tile > div,
html body .blue.list-tile > div,
html body .blue.live-tile > .face,
html body .blue.list-tile > .face,
html body .blue.copy-tile > .face,
html body .blue.live-tile > .slide,
html body .blue.live-tile > .flip-back,
html body .blue.live-tile > .flip-front,
html body .blue.live-tile > .slide-back,
html body .blue.live-tile > .slide-front,
html body .blue.flip-list > li > .accent,
html body .blue.flip-list > li > .flip-back,
html body .blue.flip-list > li > .flip-front,
html body .blue.slide.accent,
html body .blue.slide-front.accent,
html body .blue.slide-back.accent,
html body .blue.flip-front.accent,
html body .blue.flip-back.accent {
  background-color: #1ba1e2;
}
html body .blue.accentColor,
html body .blue.accentHover:hover,
html body .blue.accentHover a:hover,
html body .blue.accentLink a,
html body .blue.accentLink a:hover,
html body .blue.accentLink a:visited,
a.accentLinkhtml body .blue,
a.accentLinkhtml body .blue:hover,
a.accentLinkhtml body .blue:visited {
  color: #1ba1e2;
}
html body .blue.accentBorder {
  border-color: #1ba1e2;
}
html body .blue.accentBorderBottom {
  border-bottom-color: #1ba1e2;
}
html body .blue.accentBorderLeft {
  border-left-color: #1ba1e2;
}
html body .blue.accentBorderRight {
  border-right-color: #1ba1e2;
}
html body .blue.accentBorderTop {
  border-top-color: #1ba1e2;
}
html body .brown.accent,
html body .brown.live-tile > .accent,
html body .brown.list-tile > .accent,
html body .brown.copy-tile > .accent,
html body .brown.live-tile > div,
html body .brown.copy-tile > div,
html body .brown.list-tile > div,
html body .brown.live-tile > .face,
html body .brown.list-tile > .face,
html body .brown.copy-tile > .face,
html body .brown.live-tile > .slide,
html body .brown.live-tile > .flip-back,
html body .brown.live-tile > .flip-front,
html body .brown.live-tile > .slide-back,
html body .brown.live-tile > .slide-front,
html body .brown.flip-list > li > .accent,
html body .brown.flip-list > li > .flip-back,
html body .brown.flip-list > li > .flip-front,
html body .brown.slide.accent,
html body .brown.slide-front.accent,
html body .brown.slide-back.accent,
html body .brown.flip-front.accent,
html body .brown.flip-back.accent {
  background-color: #a05000;
}
html body .brown.accentColor,
html body .brown.accentHover:hover,
html body .brown.accentHover a:hover,
html body .brown.accentLink a,
html body .brown.accentLink a:hover,
html body .brown.accentLink a:visited,
a.accentLinkhtml body .brown,
a.accentLinkhtml body .brown:hover,
a.accentLinkhtml body .brown:visited {
  color: #a05000;
}
html body .brown.accentBorder {
  border-color: #a05000;
}
html body .brown.accentBorderBottom {
  border-bottom-color: #a05000;
}
html body .brown.accentBorderLeft {
  border-left-color: #a05000;
}
html body .brown.accentBorderRight {
  border-right-color: #a05000;
}
html body .brown.accentBorderTop {
  border-top-color: #a05000;
}
html body .cobalt.accent,
html body .cobalt.live-tile > .accent,
html body .cobalt.list-tile > .accent,
html body .cobalt.copy-tile > .accent,
html body .cobalt.live-tile > div,
html body .cobalt.copy-tile > div,
html body .cobalt.list-tile > div,
html body .cobalt.live-tile > .face,
html body .cobalt.list-tile > .face,
html body .cobalt.copy-tile > .face,
html body .cobalt.live-tile > .slide,
html body .cobalt.live-tile > .flip-back,
html body .cobalt.live-tile > .flip-front,
html body .cobalt.live-tile > .slide-back,
html body .cobalt.live-tile > .slide-front,
html body .cobalt.flip-list > li > .accent,
html body .cobalt.flip-list > li > .flip-back,
html body .cobalt.flip-list > li > .flip-front,
html body .cobalt.slide.accent,
html body .cobalt.slide-front.accent,
html body .cobalt.slide-back.accent,
html body .cobalt.flip-front.accent,
html body .cobalt.flip-back.accent {
  background-color: #0050ef;
}
html body .cobalt.accentColor,
html body .cobalt.accentHover:hover,
html body .cobalt.accentHover a:hover,
html body .cobalt.accentLink a,
html body .cobalt.accentLink a:hover,
html body .cobalt.accentLink a:visited,
a.accentLinkhtml body .cobalt,
a.accentLinkhtml body .cobalt:hover,
a.accentLinkhtml body .cobalt:visited {
  color: #0050ef;
}
html body .cobalt.accentBorder {
  border-color: #0050ef;
}
html body .cobalt.accentBorderBottom {
  border-bottom-color: #0050ef;
}
html body .cobalt.accentBorderLeft {
  border-left-color: #0050ef;
}
html body .cobalt.accentBorderRight {
  border-right-color: #0050ef;
}
html body .cobalt.accentBorderTop {
  border-top-color: #0050ef;
}
html body .crimson.accent,
html body .crimson.live-tile > .accent,
html body .crimson.list-tile > .accent,
html body .crimson.copy-tile > .accent,
html body .crimson.live-tile > div,
html body .crimson.copy-tile > div,
html body .crimson.list-tile > div,
html body .crimson.live-tile > .face,
html body .crimson.list-tile > .face,
html body .crimson.copy-tile > .face,
html body .crimson.live-tile > .slide,
html body .crimson.live-tile > .flip-back,
html body .crimson.live-tile > .flip-front,
html body .crimson.live-tile > .slide-back,
html body .crimson.live-tile > .slide-front,
html body .crimson.flip-list > li > .accent,
html body .crimson.flip-list > li > .flip-back,
html body .crimson.flip-list > li > .flip-front,
html body .crimson.slide.accent,
html body .crimson.slide-front.accent,
html body .crimson.slide-back.accent,
html body .crimson.flip-front.accent,
html body .crimson.flip-back.accent {
  background-color: #a20025;
}
html body .crimson.accentColor,
html body .crimson.accentHover:hover,
html body .crimson.accentHover a:hover,
html body .crimson.accentLink a,
html body .crimson.accentLink a:hover,
html body .crimson.accentLink a:visited,
a.accentLinkhtml body .crimson,
a.accentLinkhtml body .crimson:hover,
a.accentLinkhtml body .crimson:visited {
  color: #a20025;
}
html body .crimson.accentBorder {
  border-color: #a20025;
}
html body .crimson.accentBorderBottom {
  border-bottom-color: #a20025;
}
html body .crimson.accentBorderLeft {
  border-left-color: #a20025;
}
html body .crimson.accentBorderRight {
  border-right-color: #a20025;
}
html body .crimson.accentBorderTop {
  border-top-color: #a20025;
}
html body .cyan.accent,
html body .cyan.live-tile > .accent,
html body .cyan.list-tile > .accent,
html body .cyan.copy-tile > .accent,
html body .cyan.live-tile > div,
html body .cyan.copy-tile > div,
html body .cyan.list-tile > div,
html body .cyan.live-tile > .face,
html body .cyan.list-tile > .face,
html body .cyan.copy-tile > .face,
html body .cyan.live-tile > .slide,
html body .cyan.live-tile > .flip-back,
html body .cyan.live-tile > .flip-front,
html body .cyan.live-tile > .slide-back,
html body .cyan.live-tile > .slide-front,
html body .cyan.flip-list > li > .accent,
html body .cyan.flip-list > li > .flip-back,
html body .cyan.flip-list > li > .flip-front,
html body .cyan.slide.accent,
html body .cyan.slide-front.accent,
html body .cyan.slide-back.accent,
html body .cyan.flip-front.accent,
html body .cyan.flip-back.accent {
  background-color: #1ba0e1;
}
html body .cyan.accentColor,
html body .cyan.accentHover:hover,
html body .cyan.accentHover a:hover,
html body .cyan.accentLink a,
html body .cyan.accentLink a:hover,
html body .cyan.accentLink a:visited,
a.accentLinkhtml body .cyan,
a.accentLinkhtml body .cyan:hover,
a.accentLinkhtml body .cyan:visited {
  color: #1ba0e1;
}
html body .cyan.accentBorder {
  border-color: #1ba0e1;
}
html body .cyan.accentBorderBottom {
  border-bottom-color: #1ba0e1;
}
html body .cyan.accentBorderLeft {
  border-left-color: #1ba0e1;
}
html body .cyan.accentBorderRight {
  border-right-color: #1ba0e1;
}
html body .cyan.accentBorderTop {
  border-top-color: #1ba0e1;
}
html body .emerald.accent,
html body .emerald.live-tile > .accent,
html body .emerald.list-tile > .accent,
html body .emerald.copy-tile > .accent,
html body .emerald.live-tile > div,
html body .emerald.copy-tile > div,
html body .emerald.list-tile > div,
html body .emerald.live-tile > .face,
html body .emerald.list-tile > .face,
html body .emerald.copy-tile > .face,
html body .emerald.live-tile > .slide,
html body .emerald.live-tile > .flip-back,
html body .emerald.live-tile > .flip-front,
html body .emerald.live-tile > .slide-back,
html body .emerald.live-tile > .slide-front,
html body .emerald.flip-list > li > .accent,
html body .emerald.flip-list > li > .flip-back,
html body .emerald.flip-list > li > .flip-front,
html body .emerald.slide.accent,
html body .emerald.slide-front.accent,
html body .emerald.slide-back.accent,
html body .emerald.flip-front.accent,
html body .emerald.flip-back.accent {
  background-color: #008a00;
}
html body .emerald.accentColor,
html body .emerald.accentHover:hover,
html body .emerald.accentHover a:hover,
html body .emerald.accentLink a,
html body .emerald.accentLink a:hover,
html body .emerald.accentLink a:visited,
a.accentLinkhtml body .emerald,
a.accentLinkhtml body .emerald:hover,
a.accentLinkhtml body .emerald:visited {
  color: #008a00;
}
html body .emerald.accentBorder {
  border-color: #008a00;
}
html body .emerald.accentBorderBottom {
  border-bottom-color: #008a00;
}
html body .emerald.accentBorderLeft {
  border-left-color: #008a00;
}
html body .emerald.accentBorderRight {
  border-right-color: #008a00;
}
html body .emerald.accentBorderTop {
  border-top-color: #008a00;
}
html body .green.accent,
html body .green.live-tile > .accent,
html body .green.list-tile > .accent,
html body .green.copy-tile > .accent,
html body .green.live-tile > div,
html body .green.copy-tile > div,
html body .green.list-tile > div,
html body .green.live-tile > .face,
html body .green.list-tile > .face,
html body .green.copy-tile > .face,
html body .green.live-tile > .slide,
html body .green.live-tile > .flip-back,
html body .green.live-tile > .flip-front,
html body .green.live-tile > .slide-back,
html body .green.live-tile > .slide-front,
html body .green.flip-list > li > .accent,
html body .green.flip-list > li > .flip-back,
html body .green.flip-list > li > .flip-front,
html body .green.slide.accent,
html body .green.slide-front.accent,
html body .green.slide-back.accent,
html body .green.flip-front.accent,
html body .green.flip-back.accent {
  background-color: #339933;
}
html body .green.accentColor,
html body .green.accentHover:hover,
html body .green.accentHover a:hover,
html body .green.accentLink a,
html body .green.accentLink a:hover,
html body .green.accentLink a:visited,
a.accentLinkhtml body .green,
a.accentLinkhtml body .green:hover,
a.accentLinkhtml body .green:visited {
  color: #339933;
}
html body .green.accentBorder {
  border-color: #339933;
}
html body .green.accentBorderBottom {
  border-bottom-color: #339933;
}
html body .green.accentBorderLeft {
  border-left-color: #339933;
}
html body .green.accentBorderRight {
  border-right-color: #339933;
}
html body .green.accentBorderTop {
  border-top-color: #339933;
}
html body .indigo.accent,
html body .indigo.live-tile > .accent,
html body .indigo.list-tile > .accent,
html body .indigo.copy-tile > .accent,
html body .indigo.live-tile > div,
html body .indigo.copy-tile > div,
html body .indigo.list-tile > div,
html body .indigo.live-tile > .face,
html body .indigo.list-tile > .face,
html body .indigo.copy-tile > .face,
html body .indigo.live-tile > .slide,
html body .indigo.live-tile > .flip-back,
html body .indigo.live-tile > .flip-front,
html body .indigo.live-tile > .slide-back,
html body .indigo.live-tile > .slide-front,
html body .indigo.flip-list > li > .accent,
html body .indigo.flip-list > li > .flip-back,
html body .indigo.flip-list > li > .flip-front,
html body .indigo.slide.accent,
html body .indigo.slide-front.accent,
html body .indigo.slide-back.accent,
html body .indigo.flip-front.accent,
html body .indigo.flip-back.accent {
  background-color: #6a00ff;
}
html body .indigo.accentColor,
html body .indigo.accentHover:hover,
html body .indigo.accentHover a:hover,
html body .indigo.accentLink a,
html body .indigo.accentLink a:hover,
html body .indigo.accentLink a:visited,
a.accentLinkhtml body .indigo,
a.accentLinkhtml body .indigo:hover,
a.accentLinkhtml body .indigo:visited {
  color: #6a00ff;
}
html body .indigo.accentBorder {
  border-color: #6a00ff;
}
html body .indigo.accentBorderBottom {
  border-bottom-color: #6a00ff;
}
html body .indigo.accentBorderLeft {
  border-left-color: #6a00ff;
}
html body .indigo.accentBorderRight {
  border-right-color: #6a00ff;
}
html body .indigo.accentBorderTop {
  border-top-color: #6a00ff;
}
html body .lime.accent,
html body .lime.live-tile > .accent,
html body .lime.list-tile > .accent,
html body .lime.copy-tile > .accent,
html body .lime.live-tile > div,
html body .lime.copy-tile > div,
html body .lime.list-tile > div,
html body .lime.live-tile > .face,
html body .lime.list-tile > .face,
html body .lime.copy-tile > .face,
html body .lime.live-tile > .slide,
html body .lime.live-tile > .flip-back,
html body .lime.live-tile > .flip-front,
html body .lime.live-tile > .slide-back,
html body .lime.live-tile > .slide-front,
html body .lime.flip-list > li > .accent,
html body .lime.flip-list > li > .flip-back,
html body .lime.flip-list > li > .flip-front,
html body .lime.slide.accent,
html body .lime.slide-front.accent,
html body .lime.slide-back.accent,
html body .lime.flip-front.accent,
html body .lime.flip-back.accent {
  background-color: #a2c139;
}
html body .lime.accentColor,
html body .lime.accentHover:hover,
html body .lime.accentHover a:hover,
html body .lime.accentLink a,
html body .lime.accentLink a:hover,
html body .lime.accentLink a:visited,
a.accentLinkhtml body .lime,
a.accentLinkhtml body .lime:hover,
a.accentLinkhtml body .lime:visited {
  color: #a2c139;
}
html body .lime.accentBorder {
  border-color: #a2c139;
}
html body .lime.accentBorderBottom {
  border-bottom-color: #a2c139;
}
html body .lime.accentBorderLeft {
  border-left-color: #a2c139;
}
html body .lime.accentBorderRight {
  border-right-color: #a2c139;
}
html body .lime.accentBorderTop {
  border-top-color: #a2c139;
}
html body .magenta.accent,
html body .magenta.live-tile > .accent,
html body .magenta.list-tile > .accent,
html body .magenta.copy-tile > .accent,
html body .magenta.live-tile > div,
html body .magenta.copy-tile > div,
html body .magenta.list-tile > div,
html body .magenta.live-tile > .face,
html body .magenta.list-tile > .face,
html body .magenta.copy-tile > .face,
html body .magenta.live-tile > .slide,
html body .magenta.live-tile > .flip-back,
html body .magenta.live-tile > .flip-front,
html body .magenta.live-tile > .slide-back,
html body .magenta.live-tile > .slide-front,
html body .magenta.flip-list > li > .accent,
html body .magenta.flip-list > li > .flip-back,
html body .magenta.flip-list > li > .flip-front,
html body .magenta.slide.accent,
html body .magenta.slide-front.accent,
html body .magenta.slide-back.accent,
html body .magenta.flip-front.accent,
html body .magenta.flip-back.accent {
  background-color: #d80073;
}
html body .magenta.accentColor,
html body .magenta.accentHover:hover,
html body .magenta.accentHover a:hover,
html body .magenta.accentLink a,
html body .magenta.accentLink a:hover,
html body .magenta.accentLink a:visited,
a.accentLinkhtml body .magenta,
a.accentLinkhtml body .magenta:hover,
a.accentLinkhtml body .magenta:visited {
  color: #d80073;
}
html body .magenta.accentBorder {
  border-color: #d80073;
}
html body .magenta.accentBorderBottom {
  border-bottom-color: #d80073;
}
html body .magenta.accentBorderLeft {
  border-left-color: #d80073;
}
html body .magenta.accentBorderRight {
  border-right-color: #d80073;
}
html body .magenta.accentBorderTop {
  border-top-color: #d80073;
}
html body .mango.accent,
html body .mango.live-tile > .accent,
html body .mango.list-tile > .accent,
html body .mango.copy-tile > .accent,
html body .mango.live-tile > div,
html body .mango.copy-tile > div,
html body .mango.list-tile > div,
html body .mango.live-tile > .face,
html body .mango.list-tile > .face,
html body .mango.copy-tile > .face,
html body .mango.live-tile > .slide,
html body .mango.live-tile > .flip-back,
html body .mango.live-tile > .flip-front,
html body .mango.live-tile > .slide-back,
html body .mango.live-tile > .slide-front,
html body .mango.flip-list > li > .accent,
html body .mango.flip-list > li > .flip-back,
html body .mango.flip-list > li > .flip-front,
html body .mango.slide.accent,
html body .mango.slide-front.accent,
html body .mango.slide-back.accent,
html body .mango.flip-front.accent,
html body .mango.flip-back.accent {
  background-color: #f09609;
}
html body .mango.accentColor,
html body .mango.accentHover:hover,
html body .mango.accentHover a:hover,
html body .mango.accentLink a,
html body .mango.accentLink a:hover,
html body .mango.accentLink a:visited,
a.accentLinkhtml body .mango,
a.accentLinkhtml body .mango:hover,
a.accentLinkhtml body .mango:visited {
  color: #f09609;
}
html body .mango.accentBorder {
  border-color: #f09609;
}
html body .mango.accentBorderBottom {
  border-bottom-color: #f09609;
}
html body .mango.accentBorderLeft {
  border-left-color: #f09609;
}
html body .mango.accentBorderRight {
  border-right-color: #f09609;
}
html body .mango.accentBorderTop {
  border-top-color: #f09609;
}
html body .mauve.accent,
html body .mauve.live-tile > .accent,
html body .mauve.list-tile > .accent,
html body .mauve.copy-tile > .accent,
html body .mauve.live-tile > div,
html body .mauve.copy-tile > div,
html body .mauve.list-tile > div,
html body .mauve.live-tile > .face,
html body .mauve.list-tile > .face,
html body .mauve.copy-tile > .face,
html body .mauve.live-tile > .slide,
html body .mauve.live-tile > .flip-back,
html body .mauve.live-tile > .flip-front,
html body .mauve.live-tile > .slide-back,
html body .mauve.live-tile > .slide-front,
html body .mauve.flip-list > li > .accent,
html body .mauve.flip-list > li > .flip-back,
html body .mauve.flip-list > li > .flip-front,
html body .mauve.slide.accent,
html body .mauve.slide-front.accent,
html body .mauve.slide-back.accent,
html body .mauve.flip-front.accent,
html body .mauve.flip-back.accent {
  background-color: #765f89;
}
html body .mauve.accentColor,
html body .mauve.accentHover:hover,
html body .mauve.accentHover a:hover,
html body .mauve.accentLink a,
html body .mauve.accentLink a:hover,
html body .mauve.accentLink a:visited,
a.accentLinkhtml body .mauve,
a.accentLinkhtml body .mauve:hover,
a.accentLinkhtml body .mauve:visited {
  color: #765f89;
}
html body .mauve.accentBorder {
  border-color: #765f89;
}
html body .mauve.accentBorderBottom {
  border-bottom-color: #765f89;
}
html body .mauve.accentBorderLeft {
  border-left-color: #765f89;
}
html body .mauve.accentBorderRight {
  border-right-color: #765f89;
}
html body .mauve.accentBorderTop {
  border-top-color: #765f89;
}
html body .olive.accent,
html body .olive.live-tile > .accent,
html body .olive.list-tile > .accent,
html body .olive.copy-tile > .accent,
html body .olive.live-tile > div,
html body .olive.copy-tile > div,
html body .olive.list-tile > div,
html body .olive.live-tile > .face,
html body .olive.list-tile > .face,
html body .olive.copy-tile > .face,
html body .olive.live-tile > .slide,
html body .olive.live-tile > .flip-back,
html body .olive.live-tile > .flip-front,
html body .olive.live-tile > .slide-back,
html body .olive.live-tile > .slide-front,
html body .olive.flip-list > li > .accent,
html body .olive.flip-list > li > .flip-back,
html body .olive.flip-list > li > .flip-front,
html body .olive.slide.accent,
html body .olive.slide-front.accent,
html body .olive.slide-back.accent,
html body .olive.flip-front.accent,
html body .olive.flip-back.accent {
  background-color: #6d8764;
}
html body .olive.accentColor,
html body .olive.accentHover:hover,
html body .olive.accentHover a:hover,
html body .olive.accentLink a,
html body .olive.accentLink a:hover,
html body .olive.accentLink a:visited,
a.accentLinkhtml body .olive,
a.accentLinkhtml body .olive:hover,
a.accentLinkhtml body .olive:visited {
  color: #6d8764;
}
html body .olive.accentBorder {
  border-color: #6d8764;
}
html body .olive.accentBorderBottom {
  border-bottom-color: #6d8764;
}
html body .olive.accentBorderLeft {
  border-left-color: #6d8764;
}
html body .olive.accentBorderRight {
  border-right-color: #6d8764;
}
html body .olive.accentBorderTop {
  border-top-color: #6d8764;
}
html body .orange.accent,
html body .orange.live-tile > .accent,
html body .orange.list-tile > .accent,
html body .orange.copy-tile > .accent,
html body .orange.live-tile > div,
html body .orange.copy-tile > div,
html body .orange.list-tile > div,
html body .orange.live-tile > .face,
html body .orange.list-tile > .face,
html body .orange.copy-tile > .face,
html body .orange.live-tile > .slide,
html body .orange.live-tile > .flip-back,
html body .orange.live-tile > .flip-front,
html body .orange.live-tile > .slide-back,
html body .orange.live-tile > .slide-front,
html body .orange.flip-list > li > .accent,
html body .orange.flip-list > li > .flip-back,
html body .orange.flip-list > li > .flip-front,
html body .orange.slide.accent,
html body .orange.slide-front.accent,
html body .orange.slide-back.accent,
html body .orange.flip-front.accent,
html body .orange.flip-back.accent {
  background-color: #fa6801;
}
html body .orange.accentColor,
html body .orange.accentHover:hover,
html body .orange.accentHover a:hover,
html body .orange.accentLink a,
html body .orange.accentLink a:hover,
html body .orange.accentLink a:visited,
a.accentLinkhtml body .orange,
a.accentLinkhtml body .orange:hover,
a.accentLinkhtml body .orange:visited {
  color: #fa6801;
}
html body .orange.accentBorder {
  border-color: #fa6801;
}
html body .orange.accentBorderBottom {
  border-bottom-color: #fa6801;
}
html body .orange.accentBorderLeft {
  border-left-color: #fa6801;
}
html body .orange.accentBorderRight {
  border-right-color: #fa6801;
}
html body .orange.accentBorderTop {
  border-top-color: #fa6801;
}
html body .pink.accent,
html body .pink.live-tile > .accent,
html body .pink.list-tile > .accent,
html body .pink.copy-tile > .accent,
html body .pink.live-tile > div,
html body .pink.copy-tile > div,
html body .pink.list-tile > div,
html body .pink.live-tile > .face,
html body .pink.list-tile > .face,
html body .pink.copy-tile > .face,
html body .pink.live-tile > .slide,
html body .pink.live-tile > .flip-back,
html body .pink.live-tile > .flip-front,
html body .pink.live-tile > .slide-back,
html body .pink.live-tile > .slide-front,
html body .pink.flip-list > li > .accent,
html body .pink.flip-list > li > .flip-back,
html body .pink.flip-list > li > .flip-front,
html body .pink.slide.accent,
html body .pink.slide-front.accent,
html body .pink.slide-back.accent,
html body .pink.flip-front.accent,
html body .pink.flip-back.accent {
  background-color: #e671b8;
}
html body .pink.accentColor,
html body .pink.accentHover:hover,
html body .pink.accentHover a:hover,
html body .pink.accentLink a,
html body .pink.accentLink a:hover,
html body .pink.accentLink a:visited,
a.accentLinkhtml body .pink,
a.accentLinkhtml body .pink:hover,
a.accentLinkhtml body .pink:visited {
  color: #e671b8;
}
html body .pink.accentBorder {
  border-color: #e671b8;
}
html body .pink.accentBorderBottom {
  border-bottom-color: #e671b8;
}
html body .pink.accentBorderLeft {
  border-left-color: #e671b8;
}
html body .pink.accentBorderRight {
  border-right-color: #e671b8;
}
html body .pink.accentBorderTop {
  border-top-color: #e671b8;
}
html body .purple.accent,
html body .purple.live-tile > .accent,
html body .purple.list-tile > .accent,
html body .purple.copy-tile > .accent,
html body .purple.live-tile > div,
html body .purple.copy-tile > div,
html body .purple.list-tile > div,
html body .purple.live-tile > .face,
html body .purple.list-tile > .face,
html body .purple.copy-tile > .face,
html body .purple.live-tile > .slide,
html body .purple.live-tile > .flip-back,
html body .purple.live-tile > .flip-front,
html body .purple.live-tile > .slide-back,
html body .purple.live-tile > .slide-front,
html body .purple.flip-list > li > .accent,
html body .purple.flip-list > li > .flip-back,
html body .purple.flip-list > li > .flip-front,
html body .purple.slide.accent,
html body .purple.slide-front.accent,
html body .purple.slide-back.accent,
html body .purple.flip-front.accent,
html body .purple.flip-back.accent {
  background-color: #a200ff;
}
html body .purple.accentColor,
html body .purple.accentHover:hover,
html body .purple.accentHover a:hover,
html body .purple.accentLink a,
html body .purple.accentLink a:hover,
html body .purple.accentLink a:visited,
a.accentLinkhtml body .purple,
a.accentLinkhtml body .purple:hover,
a.accentLinkhtml body .purple:visited {
  color: #a200ff;
}
html body .purple.accentBorder {
  border-color: #a200ff;
}
html body .purple.accentBorderBottom {
  border-bottom-color: #a200ff;
}
html body .purple.accentBorderLeft {
  border-left-color: #a200ff;
}
html body .purple.accentBorderRight {
  border-right-color: #a200ff;
}
html body .purple.accentBorderTop {
  border-top-color: #a200ff;
}
html body .violet.accent,
html body .violet.live-tile > .accent,
html body .violet.list-tile > .accent,
html body .violet.copy-tile > .accent,
html body .violet.live-tile > div,
html body .violet.copy-tile > div,
html body .violet.list-tile > div,
html body .violet.live-tile > .face,
html body .violet.list-tile > .face,
html body .violet.copy-tile > .face,
html body .violet.live-tile > .slide,
html body .violet.live-tile > .flip-back,
html body .violet.live-tile > .flip-front,
html body .violet.live-tile > .slide-back,
html body .violet.live-tile > .slide-front,
html body .violet.flip-list > li > .accent,
html body .violet.flip-list > li > .flip-back,
html body .violet.flip-list > li > .flip-front,
html body .violet.slide.accent,
html body .violet.slide-front.accent,
html body .violet.slide-back.accent,
html body .violet.flip-front.accent,
html body .violet.flip-back.accent {
  background-color: #a200ff;
}
html body .violet.accentColor,
html body .violet.accentHover:hover,
html body .violet.accentHover a:hover,
html body .violet.accentLink a,
html body .violet.accentLink a:hover,
html body .violet.accentLink a:visited,
a.accentLinkhtml body .violet,
a.accentLinkhtml body .violet:hover,
a.accentLinkhtml body .violet:visited {
  color: #a200ff;
}
html body .violet.accentBorder {
  border-color: #a200ff;
}
html body .violet.accentBorderBottom {
  border-bottom-color: #a200ff;
}
html body .violet.accentBorderLeft {
  border-left-color: #a200ff;
}
html body .violet.accentBorderRight {
  border-right-color: #a200ff;
}
html body .violet.accentBorderTop {
  border-top-color: #a200ff;
}
html body .red.accent,
html body .red.live-tile > .accent,
html body .red.list-tile > .accent,
html body .red.copy-tile > .accent,
html body .red.live-tile > div,
html body .red.copy-tile > div,
html body .red.list-tile > div,
html body .red.live-tile > .face,
html body .red.list-tile > .face,
html body .red.copy-tile > .face,
html body .red.live-tile > .slide,
html body .red.live-tile > .flip-back,
html body .red.live-tile > .flip-front,
html body .red.live-tile > .slide-back,
html body .red.live-tile > .slide-front,
html body .red.flip-list > li > .accent,
html body .red.flip-list > li > .flip-back,
html body .red.flip-list > li > .flip-front,
html body .red.slide.accent,
html body .red.slide-front.accent,
html body .red.slide-back.accent,
html body .red.flip-front.accent,
html body .red.flip-back.accent {
  background-color: #e51400;
}
html body .red.accentColor,
html body .red.accentHover:hover,
html body .red.accentHover a:hover,
html body .red.accentLink a,
html body .red.accentLink a:hover,
html body .red.accentLink a:visited,
a.accentLinkhtml body .red,
a.accentLinkhtml body .red:hover,
a.accentLinkhtml body .red:visited {
  color: #e51400;
}
html body .red.accentBorder {
  border-color: #e51400;
}
html body .red.accentBorderBottom {
  border-bottom-color: #e51400;
}
html body .red.accentBorderLeft {
  border-left-color: #e51400;
}
html body .red.accentBorderRight {
  border-right-color: #e51400;
}
html body .red.accentBorderTop {
  border-top-color: #e51400;
}
html body .sienna.accent,
html body .sienna.live-tile > .accent,
html body .sienna.list-tile > .accent,
html body .sienna.copy-tile > .accent,
html body .sienna.live-tile > div,
html body .sienna.copy-tile > div,
html body .sienna.list-tile > div,
html body .sienna.live-tile > .face,
html body .sienna.list-tile > .face,
html body .sienna.copy-tile > .face,
html body .sienna.live-tile > .slide,
html body .sienna.live-tile > .flip-back,
html body .sienna.live-tile > .flip-front,
html body .sienna.live-tile > .slide-back,
html body .sienna.live-tile > .slide-front,
html body .sienna.flip-list > li > .accent,
html body .sienna.flip-list > li > .flip-back,
html body .sienna.flip-list > li > .flip-front,
html body .sienna.slide.accent,
html body .sienna.slide-front.accent,
html body .sienna.slide-back.accent,
html body .sienna.flip-front.accent,
html body .sienna.flip-back.accent {
  background-color: #7b3a3e;
}
html body .sienna.accentColor,
html body .sienna.accentHover:hover,
html body .sienna.accentHover a:hover,
html body .sienna.accentLink a,
html body .sienna.accentLink a:hover,
html body .sienna.accentLink a:visited,
a.accentLinkhtml body .sienna,
a.accentLinkhtml body .sienna:hover,
a.accentLinkhtml body .sienna:visited {
  color: #7b3a3e;
}
html body .sienna.accentBorder {
  border-color: #7b3a3e;
}
html body .sienna.accentBorderBottom {
  border-bottom-color: #7b3a3e;
}
html body .sienna.accentBorderLeft {
  border-left-color: #7b3a3e;
}
html body .sienna.accentBorderRight {
  border-right-color: #7b3a3e;
}
html body .sienna.accentBorderTop {
  border-top-color: #7b3a3e;
}
html body .steel.accent,
html body .steel.live-tile > .accent,
html body .steel.list-tile > .accent,
html body .steel.copy-tile > .accent,
html body .steel.live-tile > div,
html body .steel.copy-tile > div,
html body .steel.list-tile > div,
html body .steel.live-tile > .face,
html body .steel.list-tile > .face,
html body .steel.copy-tile > .face,
html body .steel.live-tile > .slide,
html body .steel.live-tile > .flip-back,
html body .steel.live-tile > .flip-front,
html body .steel.live-tile > .slide-back,
html body .steel.live-tile > .slide-front,
html body .steel.flip-list > li > .accent,
html body .steel.flip-list > li > .flip-back,
html body .steel.flip-list > li > .flip-front,
html body .steel.slide.accent,
html body .steel.slide-front.accent,
html body .steel.slide-back.accent,
html body .steel.flip-front.accent,
html body .steel.flip-back.accent {
  background-color: #657688;
}
html body .steel.accentColor,
html body .steel.accentHover:hover,
html body .steel.accentHover a:hover,
html body .steel.accentLink a,
html body .steel.accentLink a:hover,
html body .steel.accentLink a:visited,
a.accentLinkhtml body .steel,
a.accentLinkhtml body .steel:hover,
a.accentLinkhtml body .steel:visited {
  color: #657688;
}
html body .steel.accentBorder {
  border-color: #657688;
}
html body .steel.accentBorderBottom {
  border-bottom-color: #657688;
}
html body .steel.accentBorderLeft {
  border-left-color: #657688;
}
html body .steel.accentBorderRight {
  border-right-color: #657688;
}
html body .steel.accentBorderTop {
  border-top-color: #657688;
}
html body .teal.accent,
html body .teal.live-tile > .accent,
html body .teal.list-tile > .accent,
html body .teal.copy-tile > .accent,
html body .teal.live-tile > div,
html body .teal.copy-tile > div,
html body .teal.list-tile > div,
html body .teal.live-tile > .face,
html body .teal.list-tile > .face,
html body .teal.copy-tile > .face,
html body .teal.live-tile > .slide,
html body .teal.live-tile > .flip-back,
html body .teal.live-tile > .flip-front,
html body .teal.live-tile > .slide-back,
html body .teal.live-tile > .slide-front,
html body .teal.flip-list > li > .accent,
html body .teal.flip-list > li > .flip-back,
html body .teal.flip-list > li > .flip-front,
html body .teal.slide.accent,
html body .teal.slide-front.accent,
html body .teal.slide-back.accent,
html body .teal.flip-front.accent,
html body .teal.flip-back.accent {
  background-color: #00aba9;
}
html body .teal.accentColor,
html body .teal.accentHover:hover,
html body .teal.accentHover a:hover,
html body .teal.accentLink a,
html body .teal.accentLink a:hover,
html body .teal.accentLink a:visited,
a.accentLinkhtml body .teal,
a.accentLinkhtml body .teal:hover,
a.accentLinkhtml body .teal:visited {
  color: #00aba9;
}
html body .teal.accentBorder {
  border-color: #00aba9;
}
html body .teal.accentBorderBottom {
  border-bottom-color: #00aba9;
}
html body .teal.accentBorderLeft {
  border-left-color: #00aba9;
}
html body .teal.accentBorderRight {
  border-right-color: #00aba9;
}
html body .teal.accentBorderTop {
  border-top-color: #00aba9;
}
html body .yellow.accent,
html body .yellow.live-tile > .accent,
html body .yellow.list-tile > .accent,
html body .yellow.copy-tile > .accent,
html body .yellow.live-tile > div,
html body .yellow.copy-tile > div,
html body .yellow.list-tile > div,
html body .yellow.live-tile > .face,
html body .yellow.list-tile > .face,
html body .yellow.copy-tile > .face,
html body .yellow.live-tile > .slide,
html body .yellow.live-tile > .flip-back,
html body .yellow.live-tile > .flip-front,
html body .yellow.live-tile > .slide-back,
html body .yellow.live-tile > .slide-front,
html body .yellow.flip-list > li > .accent,
html body .yellow.flip-list > li > .flip-back,
html body .yellow.flip-list > li > .flip-front,
html body .yellow.slide.accent,
html body .yellow.slide-front.accent,
html body .yellow.slide-back.accent,
html body .yellow.flip-front.accent,
html body .yellow.flip-back.accent {
  background-color: #d8c101;
}
html body .yellow.accentColor,
html body .yellow.accentHover:hover,
html body .yellow.accentHover a:hover,
html body .yellow.accentLink a,
html body .yellow.accentLink a:hover,
html body .yellow.accentLink a:visited,
a.accentLinkhtml body .yellow,
a.accentLinkhtml body .yellow:hover,
a.accentLinkhtml body .yellow:visited {
  color: #d8c101;
}
html body .yellow.accentBorder {
  border-color: #d8c101;
}
html body .yellow.accentBorderBottom {
  border-bottom-color: #d8c101;
}
html body .yellow.accentBorderLeft {
  border-left-color: #d8c101;
}
html body .yellow.accentBorderRight {
  border-right-color: #d8c101;
}
html body .yellow.accentBorderTop {
  border-top-color: #d8c101;
}
html body .trans.accent,
html body .trans.live-tile > .accent,
html body .trans.list-tile > .accent,
html body .trans.copy-tile > .accent,
html body .trans.live-tile > div,
html body .trans.copy-tile > div,
html body .trans.list-tile > div,
html body .trans.live-tile > .face,
html body .trans.list-tile > .face,
html body .trans.copy-tile > .face,
html body .trans.live-tile > .slide,
html body .trans.live-tile > .flip-back,
html body .trans.live-tile > .flip-front,
html body .trans.live-tile > .slide-back,
html body .trans.live-tile > .slide-front,
html body .trans.flip-list > li > .accent,
html body .trans.flip-list > li > .flip-back,
html body .trans.flip-list > li > .flip-front,
html body .trans.slide.accent,
html body .trans.slide-front.accent,
html body .trans.slide-back.accent,
html body .trans.flip-front.accent,
html body .trans.flip-back.accent {
  background-color: transparent;
}
html body .trans.accentColor,
html body .trans.accentHover:hover,
html body .trans.accentHover a:hover,
html body .trans.accentLink a,
html body .trans.accentLink a:hover,
html body .trans.accentLink a:visited,
a.accentLinkhtml body .trans,
a.accentLinkhtml body .trans:hover,
a.accentLinkhtml body .trans:visited {
  color: transparent;
}
html body .trans.accentBorder {
  border-color: transparent;
}
html body .trans.accentBorderBottom {
  border-bottom-color: transparent;
}
html body .trans.accentBorderLeft {
  border-left-color: transparent;
}
html body .trans.accentBorderRight {
  border-right-color: transparent;
}
html body .trans.accentBorderTop {
  border-top-color: transparent;
}
html body .white.accent,
html body .white.live-tile > .accent,
html body .white.list-tile > .accent,
html body .white.copy-tile > .accent,
html body .white.live-tile > div,
html body .white.copy-tile > div,
html body .white.list-tile > div,
html body .white.live-tile > .face,
html body .white.list-tile > .face,
html body .white.copy-tile > .face,
html body .white.live-tile > .slide,
html body .white.live-tile > .flip-back,
html body .white.live-tile > .flip-front,
html body .white.live-tile > .slide-back,
html body .white.live-tile > .slide-front,
html body .white.flip-list > li > .accent,
html body .white.flip-list > li > .flip-back,
html body .white.flip-list > li > .flip-front,
html body .white.slide.accent,
html body .white.slide-front.accent,
html body .white.slide-back.accent,
html body .white.flip-front.accent,
html body .white.flip-back.accent {
  background-color: #ffffff;
}
html body .white.accentColor,
html body .white.accentHover:hover,
html body .white.accentHover a:hover,
html body .white.accentLink a,
html body .white.accentLink a:hover,
html body .white.accentLink a:visited,
a.accentLinkhtml body .white,
a.accentLinkhtml body .white:hover,
a.accentLinkhtml body .white:visited {
  color: #ffffff;
}
html body .white.accentBorder {
  border-color: #ffffff;
}
html body .white.accentBorderBottom {
  border-bottom-color: #ffffff;
}
html body .white.accentBorderLeft {
  border-left-color: #ffffff;
}
html body .white.accentBorderRight {
  border-right-color: #ffffff;
}
html body .white.accentBorderTop {
  border-top-color: #ffffff;
}
html body .black.accent,
html body .black.live-tile > .accent,
html body .black.list-tile > .accent,
html body .black.copy-tile > .accent,
html body .black.live-tile > div,
html body .black.copy-tile > div,
html body .black.list-tile > div,
html body .black.live-tile > .face,
html body .black.list-tile > .face,
html body .black.copy-tile > .face,
html body .black.live-tile > .slide,
html body .black.live-tile > .flip-back,
html body .black.live-tile > .flip-front,
html body .black.live-tile > .slide-back,
html body .black.live-tile > .slide-front,
html body .black.flip-list > li > .accent,
html body .black.flip-list > li > .flip-back,
html body .black.flip-list > li > .flip-front,
html body .black.slide.accent,
html body .black.slide-front.accent,
html body .black.slide-back.accent,
html body .black.flip-front.accent,
html body .black.flip-back.accent {
  background-color: #000000;
}
html body .black.accentColor,
html body .black.accentHover:hover,
html body .black.accentHover a:hover,
html body .black.accentLink a,
html body .black.accentLink a:hover,
html body .black.accentLink a:visited,
a.accentLinkhtml body .black,
a.accentLinkhtml body .black:hover,
a.accentLinkhtml body .black:visited {
  color: #000000;
}
html body .black.accentBorder {
  border-color: #000000;
}
html body .black.accentBorderBottom {
  border-bottom-color: #000000;
}
html body .black.accentBorderLeft {
  border-left-color: #000000;
}
html body .black.accentBorderRight {
  border-right-color: #000000;
}
html body .black.accentBorderTop {
  border-top-color: #000000;
}

.appbar {
  background-color: #212021;
  bottom: 0px;
  color: #fff;
  font-size: .9em;
  height: 60px;
  left: 0px;
  line-height: normal;
  margin: 0;
  max-height: 100%;
  overflow: hidden;
  padding: 10px 0;
  position: fixed;
  right: 0px;
  text-align: right;
  width: 100%;
  z-index: 100;
  -ms-touch-action: manipulation;
}
.appbar.top {
  bottom: auto !important;
  top: 0px;
}
.appbar a.etc,
.appbar a.etc:hover,
.appbar a.etc:visited {
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 32px;
  /* add some extra padding to make it easier to touch */

  height: 60px;
  position: absolute;
  right: 8px;
  text-decoration: none;
  top: 0px;
  width: 90px;
  z-index: 100;
}
.appbar .clear {
  clear: both;
}
.appbar .theme-options {
  display: block;
  float: left;
  margin: 0px;
  position: relative;
  width: 80%;
}
.appbar .theme-options > ul {
  padding-right: 8px;
}
.appbar .theme-options li {
  display: block;
  margin: 8px;
  float: left;
  line-height: 32px;
  height: 32px;
  width: 32px;
}
.appbar .theme-options li > a,
.appbar .theme-options li > a:hover,
.appbar .theme-options li > a:visited {
  display: block;
  padding: 0px;
  text-decoration: none;
  height: 32px;
  width: 32px;
}
.appbar .base-theme-options {
  display: block;
  float: left;
  margin: 0px;
  position: relative;
  width: 20%;
}
.appbar .base-theme-options > ul {
  padding-left: 2px;
}
.appbar .base-theme-options li {
  display: block;
  margin: 8px;
  float: left;
  line-height: 32px;
  height: 32px;
  width: 32px;
}
.appbar .base-theme-options li > a,
.appbar .base-theme-options li > a:hover,
.appbar .base-theme-options li > a:visited {
  display: block;
  padding: 0px;
  text-decoration: none;
  height: 32px;
  width: 32px;
}
.appbar .base-theme-options li > a.dark.accent {
  background-color: #000;
}
.appbar .theme-options h2,
.appbar .base-theme-options h2 {
  font-size: 2em;
  font-weight: normal;
  padding: 8px 8px 12px;
  text-align: left;
}
.appbar .charm-title {
  font-size: 10px;
  display: block;
  text-align: center;
}
.appbar .links {
  font-size: 2em;
  padding: 24px 0 0 8px;
  text-align: left;
}
.appbar .links a,
.appbar .links a:link {
  text-decoration: none;
}
.appbar.small {
  height: 28px;
}
.appbar.small a.etc,
.appbar.small a.etc:hover,
.appbar.small a.etc:visited {
  font-size: 16px;
  height: 60px;
  width: 90px;
}
.light .appbar {
  background-color: #dfdfdf;
  color: #000;
}
.dark .appbar {
  background-color: #212021;
}
.light .appbar-buttons li a {
  color: #000;
}
.light .appbar-buttons a > img {
  background-image: url('images/metroIcons_light.jpg');
}
.light .appbar a.etc,
.light .appbar a.etc:hover,
.light .appbar a.etc:visited {
  color: #000;
}
.small .appbar-buttons {
  /* default width is 3 buttons */

  width: 156px;
}
.small .appbar-buttons a > img {
  height: 32px;
  width: 32px;
}
.small .appbar-buttons.one {
  width: 52px;
}
.small .appbar-buttons.two {
  width: 104px;
}
.small .appbar-buttons.three {
  width: 156px;
}
.small .appbar-buttons.four {
  width: 208px;
}
.small .appbar-buttons.five {
  width: 260px;
}
.small .appbar-buttons.six {
  width: 312px;
}
.small .appbar-buttons.seven {
  width: 364px;
}
.small .appbar-buttons.eight {
  width: 416px;
}
.small .appbar-buttons.nine {
  width: 468px;
}
.small .appbar-buttons.ten {
  width: 520px;
}
.appbar-buttons {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  padding: 0 0 2px;
  text-align: right;
  /* default width is 3 buttons */

  width: 252px;
}
.appbar-buttons.one {
  width: 84px;
}
.appbar-buttons.two {
  width: 168px;
}
.appbar-buttons.three {
  width: 252px;
}
.appbar-buttons.four {
  width: 336px;
}
.appbar-buttons.five {
  width: 420px;
}
.appbar-buttons.six {
  width: 504px;
}
.appbar-buttons.seven {
  width: 588px;
}
.appbar-buttons.eight {
  width: 672px;
}
.appbar-buttons.nine {
  width: 756px;
}
.appbar-buttons.ten {
  width: 840px;
}
.appbar-buttons a > img {
  background-image: url('images/metroIcons.jpg');
  background-repeat: no-repeat;
  border: none;
  height: 64px;
  width: 64px;
}
.appbar-buttons a.small > img {
  height: 32px;
  width: 32px;
}
.appbar-buttons a.search > img {
  background-position: -12px -12px;
}
.appbar-buttons a.home > img {
  background-position: -104px -12px;
}
.appbar-buttons a.twitter > img {
  background-position: -196px -12px;
}
.appbar-buttons a.calendar > img {
  background-position: -288px -12px;
}
.appbar-buttons a.storm > img {
  background-position: -380px -12px;
}
.appbar-buttons a.down > img {
  background-position: -472px -12px;
}
.appbar-buttons a.camera > img {
  background-position: -12px -99px;
}
.appbar-buttons a.camcorder > img {
  background-position: -104px -99px;
}
.appbar-buttons a.qmark > img,
.appbar-buttons a.about > img {
  background-position: -196px -99px;
}
.appbar-buttons a.share > img {
  background-position: -288px -99px;
}
.appbar-buttons a.rain > img {
  background-position: -380px -99px;
}
.appbar-buttons a.cancel > img,
.appbar-buttons a.close > img {
  background-position: -472px -99px;
}
.appbar-buttons a.delete > img,
.appbar-buttons a.trash > img {
  background-position: -12px -188px;
}
.appbar-buttons a.tag > img {
  background-position: -104px -188px;
}
.appbar-buttons a.addcontact > img {
  background-position: -196px -188px;
}
.appbar-buttons a.save > img {
  background-position: -288px -188px;
}
.appbar-buttons a.snow > img {
  background-position: -380px -188px;
}
.appbar-buttons a.msg > img {
  background-position: -472px -188px;
}
.appbar-buttons a.email > img {
  background-position: -12px -274px;
}
.appbar-buttons a.clock > img {
  background-position: -104px -274px;
}
.appbar-buttons a.edit > img {
  background-position: -196px -274px;
}
.appbar-buttons a.circle > img {
  background-position: -288px -274px;
}
.appbar-buttons a.moon > img {
  background-position: -380px -274px;
}
.appbar-buttons a.calc > img {
  background-position: -12px -362px;
}
.appbar-buttons a.gear > img {
  background-position: -104px -362px;
}
.appbar-buttons a.plus > img {
  background-position: -196px -362px;
}
.appbar-buttons a.dot > img {
  background-position: -288px -362px;
}
.appbar-buttons a.restart > img,
.appbar-buttons a.return > img {
  background-position: -380px -362px;
}
.appbar-buttons a.add > img {
  background-position: -472px -362px;
}
.appbar-buttons a.phone > img {
  background-position: -12px -453px;
}
.appbar-buttons a.film > img {
  background-position: -104px -453px;
}
.appbar-buttons a.back > img {
  background-position: -196px -453px;
}
.appbar-buttons a.car > img {
  background-position: -288px -453px;
}
.appbar-buttons a.forward > img {
  background-position: -380px -453px;
}
.appbar-buttons a.selectmany > img {
  background-position: -472px -453px;
}
.appbar-buttons a.stop > img {
  background-position: -12px -538px;
}
.appbar-buttons a.contacts > img {
  background-position: -104px -538px;
}
.appbar-buttons a.select > img {
  background-position: -196px -538px;
}
.appbar-buttons a.sun > img {
  background-position: -288px -538px;
}
.appbar-buttons a.dpad > img {
  background-position: -380px -538px;
}
.appbar-buttons a.play > img {
  background-position: -472px -538px;
}
.appbar-buttons li {
  display: inline;
  padding: 0 10px;
  float: left;
  list-style: none;
}
.appbar-buttons li a {
  font-weight: bold;
  background-color: transparent;
  color: #fff;
}
.appbar-buttons li a:active,
.appbar-buttons ul#menu li.selected a,
.appbar-buttons .appbar-buttons li a:hover {
  text-decoration: none;
}
.small .appbar-buttons .search > img,
.appbar-buttons .small.search > img {
  background-position: -562px -6px;
}
.small .appbar-buttons .home > img,
.appbar-buttons .small.home > img {
  background-position: -608px -6px;
}
.small .appbar-buttons .twitter > img,
.appbar-buttons .small.twitter > img {
  background-position: -654px -6px;
}
.small .appbar-buttons .calendar > img,
.appbar-buttons .small.calendar > img {
  background-position: -700px -6px;
}
.small .appbar-buttons .storm > img,
.appbar-buttons .small.storm > img {
  background-position: -746px -6px;
}
.small .appbar-buttons .down > img,
.appbar-buttons .small.down > img {
  background-position: -792px -6px;
}
.small .appbar-buttons .camera > img,
.appbar-buttons .small.camera > img {
  background-position: -562px -50px;
}
.small .appbar-buttons .camcorder > img,
.appbar-buttons .small.camcorder > img {
  background-position: -608px -50px;
}
.small .appbar-buttons .qmark > img,
.appbar-buttons .small.qmark > img,
.small .appbar-buttons .about > img,
.appbar-buttons .small.about > img {
  background-position: -654px -50px;
}
.small .appbar-buttons .share > img,
.appbar-buttons .small.share > img {
  background-position: -700px -50px;
}
.small .appbar-buttons .rain > img,
.appbar-buttons .small.rain > img {
  background-position: -746px -50px;
}
.small .appbar-buttons .cancel > img,
.appbar-buttons .small.cancel > img,
.small .appbar-buttons .close > img,
.appbar-buttons .small.close > img {
  background-position: -792px -50px;
}
.small .appbar-buttons .delete > img,
.appbar-buttons .small.delete > img,
.small .appbar-buttons .trash > img,
.appbar-buttons .small.trash > img {
  background-position: -562px -94px;
}
.small .appbar-buttons .tag > img,
.appbar-buttons .small.tag > img {
  background-position: -608px -94px;
}
.small .appbar-buttons .addcontact > img,
.appbar-buttons .small.addcontact > img {
  background-position: -654px -94px;
}
.small .appbar-buttons .save > img,
.appbar-buttons .small.save > img {
  background-position: -700px -94px;
}
.small .appbar-buttons .snow > img,
.appbar-buttons .small.snow > img {
  background-position: -746px -94px;
}
.small .appbar-buttons .msg > img,
.appbar-buttons .small.msg > img {
  background-position: -792px -94px;
}
.small .appbar-buttons .email > img,
.appbar-buttons .small.email > img {
  background-position: -562px -137px;
}
.small .appbar-buttons .clock > img,
.appbar-buttons .small.clock > img {
  background-position: -608px -137px;
}
.small .appbar-buttons .edit > img,
.appbar-buttons .small.edit > img {
  background-position: -654px -137px;
}
.small .appbar-buttons .circle > img,
.appbar-buttons .small.circle > img {
  background-position: -700px -137px;
}
.small .appbar-buttons .moon > img,
.appbar-buttons .small.moon > img {
  background-position: -746px -137px;
}
.small .appbar-buttons .calc > img,
.appbar-buttons .small.calc > img {
  background-position: -562px -181px;
}
.small .appbar-buttons .gear > img,
.appbar-buttons .small.gear > img {
  background-position: -608px -181px;
}
.small .appbar-buttons .plus > img,
.appbar-buttons .small.plus > img {
  background-position: -654px -181px;
}
.small .appbar-buttons .dot > img,
.appbar-buttons .small.dot > img {
  background-position: -700px -181px;
}
.small .appbar-buttons .restart > img,
.appbar-buttons .small.restart > img,
.small .appbar-buttons .return > img,
.appbar-buttons .small.return > img {
  background-position: -746px -181px;
}
.small .appbar-buttons .add > img,
.appbar-buttons .small.add > img {
  background-position: -792px -181px;
}
.small .appbar-buttons .phone > img,
.appbar-buttons .small.phone > img {
  background-position: -562px -226px;
}
.small .appbar-buttons .film > img,
.appbar-buttons .small.film > img {
  background-position: -608px -226px;
}
.small .appbar-buttons .back > img,
.appbar-buttons .small.back > img {
  background-position: -654px -226px;
}
.small .appbar-buttons .car > img,
.appbar-buttons .small.car > img {
  background-position: -700px -226px;
}
.small .appbar-buttons .forward > img,
.appbar-buttons .small.forward > img {
  background-position: -746px -226px;
}
.small .appbar-buttons .selectmany > img,
.appbar-buttons .small.selectmany > img {
  background-position: -792px -226px;
}
.small .appbar-buttons .stop > img,
.appbar-buttons .small.stop > img {
  background-position: -562px -269px;
}
.small .appbar-buttons .contacts > img,
.appbar-buttons .small.contacts > img {
  background-position: -608px -269px;
}
.small .appbar-buttons .select > img,
.appbar-buttons .small.select > img {
  background-position: -654px -269px;
}
.small .appbar-buttons .sun > img,
.appbar-buttons .small.sun > img {
  background-position: -700px -269px;
}
.small .appbar-buttons .dpad > img,
.appbar-buttons .small.dpad > img {
  background-position: -746px -269px;
}
.small .appbar-buttons .play > img,
.appbar-buttons .small.play > img {
  background-position: -792px -269px;
}
/* Media queries */
@media screen and (max-width: 1025px) {
  .appbar .theme-options h2,
  .appbar .base-theme-options h2,
  .appbar .links {
    font-size: 1.7em;
    padding: 8px 8px 2px;
    text-align: left;
  }
  .appbar .base-theme-options li,
  .appbar .base-theme-options li a,
  .appbar .theme-options li,
  .appbar .theme-options li a {
    display: block;
    height: 32px;
    margin: 4px;
    width: 32px;
  }
}
@media screen and (max-width: 801px) {
  .appbar .theme-options h2,
  .appbar .base-theme-options h2,
  .appbar .links {
    font-size: 1.3em;
  }
  .appbar .theme-options > ul {
    padding-right: 6px;
  }
}
@media screen and (max-width: 641px) {
  .appbar .theme-options {
    width: 70%;
  }
  .appbar .base-theme-options {
    width: 30%;
  }
}

