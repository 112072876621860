/*------------------------------------------------------------------------------------*/
/*--------------------------------  GLOBAL CSS STYLE  --------------------------------*/

html { font-size: 100%; height: 100%; }
body {font-family: 'Open Sans', verdana, arial;font-weight: 300; margin-top: 40px;background: #DFE5E9;}
#wrapper { width: 100%;}
#main-content {width: 100%;padding:10px 20px;background-color: #DFE5E9;}
#sidebar{background-color: #2B2E33;}
ul {margin: 0;padding: 0;list-style: none;}

/****  Margin  ****/
.m-auto {margin:auto;}
.m-0 {margin:0 !important;}
.m-5 {margin:5px !important;}
.m-10 {margin:10px !important;}
.m-20 {margin:20px !important;}

/****  Margin Top  ****/
.m-t-0 {margin-top:0px !important;}
.m-t-5{margin-top:5px !important; }
.m-t-10{margin-top:10px !important;}
.m-t-20{margin-top:20px !important;}
.m-t-30{margin-top:30px !important;}
.m-t-40{margin-top:40px !important;}
.m-t-60{margin-top:60px !important;}

/****  Margin Bottom  ****/
.m-b-0 {margin-bottom:0px !important;}
.m-b-5 {margin-bottom:5px !important;}
.m-b-6 {margin-bottom:6px !important;}
.m-b-10 {margin-bottom:10px !important;}
.m-b-12 {margin-bottom:12px !important;}
.m-b-15 {margin-bottom:15px !important;}
.m-b-20 {margin-bottom:20px !important;}
.m-b-30 {margin-bottom:30px !important;}
.m-b-40 {margin-bottom:40px !important;}
.m-b-60 {margin-bottom:60px !important;}
.m-b-80 {margin-bottom:80px !important;}
.m-b-140{margin-bottom:140px !important;}
.m-b-80 {margin-bottom:80px !important;}
.m-b-100 {margin-bottom:100px !important;}
.m-b-245 {margin-bottom:245px !important;}
.m-b-245 {margin-bottom:245px !important;}
.m-b-250 {margin-bottom:250px !important;}
.m-b-m30 {margin-bottom:-30px !important;}
.m-b-m50 {margin-bottom:-50px !important;}

/****  Margin Left  ****/
.m-l-5 {margin-left:5px !important;}
.m-l-10 {margin-left:10px !important;}
.m-l-20 {margin-left:20px !important;}
.m-l-30 {margin-left:30px !important;}
.m-l-60 {margin-left:60px !important;}

/****  Margin Right  ****/
.m-r-5 {margin-right:5px !important;}
.m-r-10 {margin-right:10px !important;}
.m-r-20 {margin-right:20px !important;}
.m-r-30 {margin-right:30px !important;}
.m-r-60 {margin-right:60px !important;}

/****  Padding  ****/
.p-0 {padding:0 !important;}
.p-5 {padding:5px !important;}
.p-10 {padding:10px !important;}
.p-15 {padding:15px !important;}
.p-20 {padding:20px !important;}
.p-30 {padding:30px !important;}
.p-40 {padding:40px !important;}

/****  Padding Top  ****/
.p-t-0 {padding-top:0 !important;}
.p-t-10 {padding-top:10px !important;}
.p-t-20 {padding-top:20px !important;}

/****  Padding Bottom  ****/
.p-b-0 {padding-bottom:0 !important;}
.p-b-10 {padding-bottom:10px !important;}
.p-b-20 {padding-bottom:10px !important;}
.p-b-30 {padding-bottom:30px !important;}
 
/****  Padding Left  ****/
.p-l-5 {padding-left:5px !important;}
.p-l-10 {padding-left:10px !important;}
.p-l-20 {padding-left:20px !important;}
.p-l-30 {padding-left:30px !important;}
.p-l-40 {padding-left:40px !important;}
/* Padding Right  ****/
.p-r-5 {padding-right:5px !important;}
.p-r-10 {padding-right:10px !important;}
.p-r-20 {padding-right:20px !important;}
.p-r-30 {padding-right:30px !important;}

/****  Top  ****/
.t-0 {top:0; }
.t-5 {top:5px; }
.t-10 {top:10px; }
.t-15 {top:15px; }

/****  Bottom  ****/
.b-0 {bottom:0; }
.b-5 {bottom:5px; }
.b-10 {bottom:10px; }
.b-15 {bottom:15px; }

/****  Left  ****/
.l-0 {left:0; }
.l-5 {left:5px; }
.l-10 {left:10px; }
.l-15 {left:15px; }

/****  Right  ****/
.r-0 {right:0; }
.r-5 {right:5px; }
.r-10 {right:10px; }
.r-15 {right:15px; }

/****  Border Radius  ****/
.bd-0{-moz-border-radius: 0 !important;-webkit-border-radius: 0 !important;border-radius: 0 !important;}
.bd-3{-moz-border-radius: 3px !important;-webkit-border-radius: 3px !important;border-radius: 3px !important;}
.bd-6{-moz-border-radius: 6px !important;-webkit-border-radius: 6px !important;border-radius: 6px !important;}
.bd-9{-moz-border-radius: 9px !important;-webkit-border-radius: 9px !important;border-radius: 9px !important;}
.bd-50p{-moz-border-radius: 50% !important;-webkit-border-radius: 50% !important;border-radius: 50% !important;}

/****  Border Radius  ****/
.no-bd{border:none !important;box-shadow: none}
.border-bottom {border-bottom: 1px solid #EFEFEF !important;}
.border-top {border-top: 1px solid #EFEFEF !important;}
.bd-white{border-color:#fff !important;}
.bd-green{border-left: 3px solid #5CB85C;padding-left:20px;}
.bd-red{border-left: 3px solid #C9625F;padding-left:20px}
.bd-blue{border-left: 3px solid #3598DB;padding-left:20px}
.bd-t-red{border-top:4px solid #C9625F;}
.bd-t-green{border-top:4px solid #5CB85C;}
.bd-t-blue{border-top:4px solid #0090D9;}
.bd-t-dark{border-top:4px solid #2B2E33;}
.bd-t-purple{border-top:4px solid #B57EE0;}
.bd-l-red{border-left:4px solid #C9625F;}
.bd-l-green{border-left:4px solid #5CB85C;}
.bd-l-blue{border-left:4px solid #0090D9;}
.bd-l-dark{border-left:4px solid #2B2E33;}
.bd-l-purple{border-left:4px solid #B57EE0;}
.bd-b-red{border-bottom:4px solid #C9625F;}
.bd-b-green{border-bottom:4px solid #5CB85C;}
.bd-b-blue{border-bottom:4px solid #0090D9;}
.bd-b-dark{border-bottom:4px solid #2B2E33;}
.bd-b-purple{border-bottom:4px solid #B57EE0;}
/****  Background Colors  ****/
.bg-gray  {background-color:#b6b6b6 !important; color:#000 !important; }
.bg-gray-light  {background-color:#ECECEC !important; color:#000 !important; }
.bg-red {background-color:#C75757 !important;color:#fff !important;}
.bg-white {background-color:#fff !important; color:black !important;}
.bg-green  {background-color:#18a689 !important; color:#fff !important;}
.bg-blue  {background-color:#0090D9 !important ; color:#fff !important;}
.bg-orange {background-color:#f27835 !important ; color:#fff !important;}
.bg-yellow {background-color:#FFE077 !important ; color:#f27835 !important;}
.bg-purple  {background-color:#B57EE0 !important; color:#fff !important;}
.bg-dark  {background-color:#2B2E33 !important; color:#fff !important;}
.bg-purple-gradient  {background: #bf9bdd;background: -moz-radial-gradient(center, ellipse cover,  #bf9bdd 27%, #9e52dd 100%);background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(27%,#bf9bdd), color-stop(100%,#9e52dd));background: -webkit-radial-gradient(center, ellipse cover,  #bf9bdd 27%,#9e52dd 100%); background: -o-radial-gradient(center, ellipse cover,  #bf9bdd 27%,#9e52dd 100%);background: -ms-radial-gradient(center, ellipse cover,  #bf9bdd 27%,#9e52dd 100%);background: radial-gradient(ellipse at center,  #bf9bdd 27%,#9e52dd 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bf9bdd', endColorstr='#9e52dd',GradientType=1 ); }
.bg-opacity-20 {background-color: rgba(0, 0, 0, 0.2);}
.bg-calendar {background-color:rgb(0,144,217);border:1px solid black !important;border-radius:3px;font-weight:600;cursor:pointer;color:white;}
.bg-graycalendar  {background-color:#b6b6b6 !important; border:1px solid black !important;border-radius:3px;font-weight:600;cursor:pointer;color:white;}
.bg-darkcalendar  {background-color:#2B2E33 !important; border:1px solid black !important;border-radius:3px;font-weight:600;cursor:pointer;color:white;}
.bg-orangecalendar {background-color:#f27835 !important ; border:1px solid black !important;border-radius:3px;font-weight:600;cursor:pointer;color:white;}
.bg-dueToDoCalendar {background-color:red;border:1px solid black !important;border-radius:3px;font-weight:600;cursor:pointer;color:white;}
.bg-completeCalendar {background-color:green;border:1px solid black !important;border-radius:3px;font-weight:600;cursor:pointer;color:white;}
/****  Opacity  ****/
.opacity-0 {opacity:0;}
.opacity-20 {opacity:.2;}
.opacity-50 {opacity:.5;}
.opacity-70 {opacity:.7;}
.opacity-90 {opacity:.9;}

/****  Width  ****/
.width-0 {width:0 !important;}
.width-300 {width:300px !important;}
.min-width-40  {min-width: 40px}
.width-100p  {width:100% !important;}

/****  Height  ****/
.h-0 {height: 0 !important;overflow: hidden !important}
.h-20 {height: 20px !important;overflow: hidden !important}
.h-30 {height: 30px !important;overflow: hidden !important}
.h-40 {height: 40px !important;overflow: hidden !important}
.h-100 {height: 100px !important;overflow: hidden !important}
.h-150 {height: 150px !important;overflow: hidden !important}
.h-220 {height: 220px !important;overflow: hidden !important}
.h-250 {height: 250px !important;overflow: hidden !important}
.h-280 {height: 280px !important;overflow: hidden !important}
.h-300 {height: 300px !important;overflow: hidden !important}
.pos-rel{position:relative;}
.pos-abs {position:absolute;}
.dis-inline {display:inline;}
.dis-inline-b {display:inline-block;}
.dis-block {display:block !important;}
.f-left {float:left;}
.f-right {float:right;}
.cursor-pointer{cursor:pointer}
code  {padding: 2px 8px 2px 4px;font-size: 90%;color: #2A465C;background-color: #D5E9FF;white-space: nowrap;border-radius: 4px;}
.line-separator {border-right:1px solid #DBE2E7;}
img.img-left { border: 1px solid #ccc; float: left; margin-right: 15px; padding: 5px;}
img.img-right { border: 1px solid #ccc; float: right; margin-left: 15px; padding: 5px;}
.hide {opacity: 0}

/****  Custom Scrollbar Browser  ****/
::-webkit-scrollbar {width:10px;}  
::-webkit-scrollbar-track {background-color: #eaeaea;border-left: 1px solid #c1c1c1;}
::-webkit-scrollbar-thumb {background-color: #c1c1c1;}  
::-webkit-scrollbar-thumb:hover { background-color: #aaa; }  
::-webkit-scrollbar-track {border-radius: 0;box-shadow: none;border: 0;}
::-webkit-scrollbar-thumb {border-radius: 0;box-shadow: none;border: 0;} 

@media print{    
  body {margin-top: 0;}
  #main-content {padding: 0;background-color: transparent;}
  .no-print, .no-print *, .navbar, #sidebar{display: none !important;}
  .invoice {max-width: 100%;max-height: 100%;padding:0 !important;border:none;}
}

/*------------------------------------------------------------------------------------*/
/*-------------------------------  GENERAL TYPOGRAPHY  -------------------------------*/
@font-face {font-family: 'Carrois Gothic';font-style: normal;font-weight: 400;src: local('Carrois Gothic'), local('CarroisGothic-Regular'), url(../fonts/carrois.woff) format('woff');}
@font-face {font-family: 'Open Sans';font-style: normal;font-weight: 300;src: local('Open Sans Light'), local('OpenSans-Light'), url(../fonts/opensans-light.woff) format('woff');}
@font-face {font-family: 'Open Sans';font-style: normal;font-weight: 400;src: local('Open Sans'), local('OpenSans'), url(../fonts/opensans.woff) format('woff');}
@font-face {font-family: 'Open Sans';font-style: normal;font-weight: 600;src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(../fonts/opensans-semibold.woff) format('woff');}
@font-face {font-family: 'Open Sans';font-style: normal;font-weight: 700;src: local('Open Sans Bold'), local('OpenSans-Bold'), url(../fonts/opensans-bold.woff) format('woff');}

h1, h2, h3, h4, h5, h6 {font-family: 'Carrois Gothic', verdana, arial;font-weight: 300 !important;color:inherit}
h3 {color: #534E4E;}
a {color: #0090D9;transition: color 0.2s linear 0s,background-color 0.2s linear 0s  !important;}
a.active {color:#00A2D9 !important;text-decoration:underline;}
.carrois {font-family: 'Carrois Gothic', sans-serif;}
.uppercase {text-transform: uppercase}
.align-center {text-align: center;}
.align-left {text-align: left;}
.align-right {text-align: right;}
a, a:focus, a:hover, a:active {  outline: 0;}

/****  Font Size  ****/
.f-10{font-size:10px !important;}
.f-12{font-size:12px !important;}
.f-14{font-size:14px !important;}
.f-15{font-size:15px !important;}
.f-16{font-size:16px !important;}
.f-18{font-size:18px !important;}
.f-20{font-size:20px !important;}
.f-24{font-size:24px !important;}
.f-32{font-size:32px !important;}
.f-40{font-size:40px !important;}
.f-60{font-size:60px !important;}
.f-65{font-size:65px !important;}
.f-80{font-size:80px !important;}
.f-150{font-size:150px !important;}

/****  Font Weight  ****/
.w-300 {font-weight: 300 !important;}
.w-500 {font-weight:500 !important;}
.w-600 {font-weight:600 !important;}
.w-700 {font-weight:700 !important;}

/****  Font Color  ****/
.c-red{color: #cd6a6a !important;}
.c-blue{color:#00A2D9 !important;}
.c-purple {color:#B57EE0 !important;}
.c-brown{color:#9E7B2E !important;}
.c-orange{color:#ec8521 !important;}
.c-green {color:#18A689 !important;}
.c-gray-light {color:#dadada !important;}
.c-gray {color:#8F8F8F !important;}
.c-dark {color:#343434 !important;}
.c-white {color:#fff !important;}
.transparent-color {color: rgba(0, 0, 0, 0.2);}
.line-through{text-decoration: line-through;}
.t-ellipsis {text-overflow: ellipsis;display: block;white-space: nowrap;overflow: hidden;}
.asterisk {color: #D9534F}
.label {display: inline-block;}
.label-success {background-color: #18A689;}
/*------------------------------------------------------------------------------------*/
/*------------------------------------- HEADER ---------------------------------------*/

.navbar {min-height: 40px;margin-bottom: 20px;border: none;margin-bottom: 0;z-index: 90}
.navbar-inverse .navbar-brand {background: url('../img/logo.png') no-repeat center; width: 214px}
.navbar-center {color:#bebebe;display:inline-block;font-size:20px;position:absolute;text-align:center;width: 50%;margin-left: 25%;;left: 0;top: 6px;font-weight:600}
.navbar-nav > li > a {padding: 10px;}
.navbar a.sidebar-toggle{padding: 10px 20px 10px 5px;}
.navbar-inverse .navbar-brand {color: #fff;padding: 8px 15px 12px 22px;}
.navbar-inverse .navbar-brand strong{color: #00A2D9;}
.sidebar-toggle {cursor: pointer;color:#ADADAD;background-position:0 0;float:left; border-right:1px solid #27292d}
.sidebar-toggle:hover {  background-position:0 40px;color: white;}
.header-menu.navbar-nav > li > a {padding: 8px 4px 8px 14px;height: 40px;}
.header-menu .badge-header {border-radius: 8px;padding: 2px 5px;right: 8px;top: -5px;position: relative;font-size: 12px;}
.header-menu .header-icon {display: inline;max-width: 100%;height: 22px}
.header-menu .dropdown-menu {top:42px; right: 0;left: auto;min-width: 170px !important;max-width: 290px !important;width: 280px;margin:0;padding: 0}
.header-menu .dropdown-menu {border-top-left-radius: 3px !important;border-top-right-radius: 3px !important;}
.header-menu .dropdown-menu:after {border-bottom: 6px solid #2B2E33;border-left: 6px solid rgba(0, 0, 0, 0);border-right: 6px solid rgba(0, 0, 0, 0);content: "";display: inline-block;right: 17px;position: absolute;top: -6px;}
.header-menu .dropdown-menu .dropdown-header {background: #2B2E33;color: #fff;font-family: 'Carrois Gothic';font-size: 15px;padding: 8px 15px;}
.header-menu .dropdown-menu .dropdown-header:hover {background: #2B2E33;color: #fff;cursor:default;}
.header-menu .dropdown-menu .dropdown-header p {margin:0;}  
.header-menu .dropdown-menu li {background: none; padding:0;}
.header-menu .dropdown-menu li:hover {background:none;}
.header-menu .dropdown-menu li ul li{padding: 12px 10px 6px 10px;}
.header-menu .dropdown-menu li ul li:hover {background:#eaeaea;}
.header-menu .dropdown-menu li ul li a:hover{text-decoration: none;}
.header-menu .dropdown-menu li ul li .dropdown-time { color: #c4c4c4;font-size:12px;display: block;text-align: right;font-weight: 600;}
.header-menu .dropdown-menu .dropdown-footer {border-top: 1px solid #E2E2E2;color: #121212;font-family: 'Carrois Gothic';font-size: 12px;padding: 5px;background: #F8F8F8;}
.header-menu .dropdown-menu .dropdown-footer a{padding: 3px 5px;}
.header-menu .dropdown-menu .dropdown-footer a:hover{background:none;}
.header-menu .dropdown-menu > li > a.pull-left, .header-menu .dropdown-menu > li > a.pull-right {clear: none;}
.header-menu #messages-header .glyph-icon {color:#fff; font-size: 20px;margin-top: 2px;}
.header-menu #messages-header ul li img {height: 30px;margin-top: -5px;border-radius: 50%;}
.header-menu #messages-header .dropdown-body p {font-size: 12px; font-style: italic; margin:5px 0 5px;}
.header-menu #user-header li a:hover {text-decoration: none;background-color: #eaeaea}
.header-menu #user-header .dropdown-menu {width: 178px;}
.header-menu #user-header .dropdown-menu li a {padding: 8px 15px;display: block;font-size:13px;}
.header-menu #user-header .dropdown-menu li a i {padding-right:8px;}
.header-menu #user-header .dropdown-menu:after {border-bottom: 6px solid #eaeaea;border-left: 6px solid rgba(0, 0, 0, 0);border-right: 6px solid rgba(0, 0, 0, 0);content: "";display: inline-block;right: 17px;position: absolute;top: -6px;}
.header-menu #user-header .dropdown-menu .dropdown-footer{background-color:#E9E9E9;}
.header-menu #user-header .dropdown-menu li.dropdown-footer {padding:0;}
.header-menu #user-header .dropdown-menu .dropdown-footer a{color:#575757; font-size:16px; display:inline-block;width: 43px;padding: 8px;text-align: center}
.header-menu #user-header .dropdown-menu .dropdown-footer a:hover{background-color: #dcdcdc;}
.header-menu #user-header .dropdown-menu .dropdown-footer a i {padding-right: 0;}
.header-menu #notifications-header .glyph-icon {color:#fff; font-size:22px;margin-top: 2px;}
.header-menu .notification {position: absolute;top: 7px;left: 10px;display: inline-block;width: 8px;height: 8px;border-radius: 50%;}
.navbar-inverse.navbar-mob-view .header-menu.xs{display: none;}
.notification-danger {background-color: #ED5466;}
.chat-popup {margin-top: 3px;padding: 5px 0;right:0;top: 35px;position: absolute;z-index: 20;cursor: pointer;}
.chat-popup .arrow-up {width: 0;height: 0;border-left: 5px solid transparent;	border-right: 5px solid transparent;border-bottom: 5px solid #0090D9;right: 10px;position: absolute;top: 0;}
.chat-popup-inner {width:140px;border-radius: 4px 4px 4px 4px;padding: 8px;text-decoration: none;font-size: 12px;-webkit-box-shadow: 0 1px 1px rgba(255, 255, 255, 0.);box-shadow: 0 1px 1px rgba(255, 255, 255, 0.2);}
.chat-input
@media (max-width: 479px) {
  .chat-input {display: none}
}
/*------------------------------------------------------------------------*/
/*------------------------------- SIDEBAR --------------------------------*/

/****  Sidebar Common Styles  ****/
#sidebar {left: 0; position: fixed;height:100%;z-index: 30;}
.arrow { float: right;}
.fa.arrow:before {content: "\f105";display: inline-block; -webkit-transition: all .15s linear;-moz-transition: all .15s linear;-o-transition: .15s linear;transition: all .15s linear;}
.active > a > .fa.arrow:before {content: "\f105"; -moz-transform: rotate(90deg);-o-transform: rotate(90deg);-webkit-transform: rotate(90deg); -ms-transform: rotate(90deg);transform: rotate(90deg);}
.sidebar-nav {top: 0;width: 100%;list-style: none;margin: 0;padding: 0;}
.sidebar-nav > li {  position: relative;display: block;}
.sidebar-nav li ul li{  position: relative;display: block;}
.sidebar-nav a {color:#adadad;display: block;text-decoration: none;font-weight:600;}
.sidebar-nav a:hover {color:#fff;}
.sidebar-nav a i { padding-right:10px; -webkit-transition: color .15s linear; -moz-transition: color .15s linear; -o-transition: color .15s linear; transition: color .15s linear;}
.sidebar-nav a:hover i {color:#3187D6;}
.sidebar-nav a i.glyph-icon, .sidebar-nav a i.fa {font-size: 18px}
.sidebar-nav li.current a:hover i {color:#fff;}
.sidebar-nav li.current a{color: #fff;background-color: #16191F;border-left:3px solid #00A2D9;}
.sidebar-nav li.current.hasSub a{border-left:none;}
.sidebar-nav li.current li.current a{color: #fff;background-color: #16191F;border-left:3px solid #00A2D9;}
.sidebar-nav>li.current>a:after {width: 0; height: 0; border-top: 9px solid transparent;border-bottom: 9px solid transparent; border-right:9px solid #DFE5E9;content: "";position: absolute;top: 50%;margin-top: -9px;right: 0;z-index: 5;}
.sidebar-nav li.new-current a{color: #fff;background-color: #16191F;border-left:3px solid #00A2D9;}
.sidebar-nav li.new-current.hasSub a{border-left:none;}
.sidebar-nav li.new-current li.current a{color: #fff;background-color: #16191F;border-left:3px solid #00A2D9;}
#sidebar ul.submenu a{background-color: #34383F; padding: 11px 28px 11px 45px; font-size:13px; text-align: left}
#sidebar ul.submenu a:after {z-index: 1;width: 8px;height: 8px;border-radius: 50%;background-color: red;left: -12px;top: 13px;bottom: auto;border-color: rgba(0, 0, 0, 0);-webkit-box-shadow: 0 0 0 2px red;box-shadow: 0 0 0 2px red;}


/****  Large Sidebar  ****/
.sidebar-large #wrapper {padding-left: 250px;}
.sidebar-large #sidebar { width: 260px;overflow: hidden;}
.sidebar-large #sidebar .sidebar-img {max-width: 26px;max-height:26px;padding-right: 8px}
.sidebar-large .sidebar-nav li a{padding:9px 20px 9px 28px;}
.sidebar-large .sidebar-nav li.last{margin-bottom: 245px !important;}
.sidebar-large .sidebar-nav>li.current.hasSub>a:after {display: none}
.sidebar-large .sidebar-nav>li.current li.current>a:after {width: 0;height: 0;border-style: solid;border-width: 9px 9px 9px 0;border-color: rgba(226, 226,226, 0) #DFE5E9 rgba(226, 226,226, 0)rgba(226, 226,226, 0);content: "";position: absolute;top: 50%;margin-top: -9px;right: 0;z-index: 5}

/****  Footer Sidebar  ****/
.footer-widget {position: fixed;bottom: 0px;display: block;padding: 0;background-color: #2B2E33;width: 260px;clear: both;z-index: 1000;}
.footer-gradient {background: url('../img/gradient.png') repeat-x;width: 100%;height: 27px;margin-top: -27px} 
.footer-widget i {font-size: 14px;color: #5E646D;}
.footer-widget a:hover i{color: #F7F7F7}
.footer-widget .sidebar-gradient-img {width:100%;height:20px;margin-top:-20px;display:block}
#sidebar-charts {display: block;border-bottom: 1px solid #3C3C3C;width: 250px; padding: 0;z-index: 1000;}
.sidebar-charts-inner{padding:15px 15px 10px 20px;height:53px;border-bottom: 1px solid #20262B;}
.sidebar-charts-left{float:left;text-align:left;margin-top: -7px;}
.sidebar-charts-right{float:right;text-align: right}
.sidebar-chart-title {color:#fff;font-size: 11px; text-transform: uppercase;opacity: 0.3}
.sidebar-chart-number {color:#fff;font-size: 18px;opacity: 0.7;font-family: 'Carrois Gothic';}
#sidebar-charts hr.divider, li.divider {border: 0;height: 1px;margin-bottom:0;margin-top:0;background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgi…gd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g');background: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, rgba(205, 205, 205, 0)), color-stop(50%, #4D4D4D), color-stop(100%, rgba(205, 205, 205, 0)));background: -webkit-linear-gradient(left, rgba(205, 205, 205, 0), #4D4D4D, rgba(205, 205, 205, 0));background: -moz-linear-gradient(left, rgba(205, 205, 205, 0), #4D4D4D, rgba(205, 205, 205, 0));background: -o-linear-gradient(left, rgba(205, 205, 205, 0),#4D4D4D, rgba(205, 205, 205, 0));background: linear-gradient(left, rgba(205, 205, 205, 0), #4D4D4D, rgba(205, 205, 205, 0));position: relative;}
.sidebar-footer {padding: 0}
.sidebar-footer .progress{position: relative;top: 15px;width: 70%;margin-bottom: 5px;}
.sidebar-footer .pull-left {width:25%;text-align: center; padding:10px 0;}
.sidebar-footer .pull-left:hover {background-color: #373a41;}
.sidebar-footer i {font-size: 16px}

/****  Medium Sidebar  ****/
.sidebar-medium #wrapper {padding-left: 125px;}
.sidebar-medium #sidebar {width: 125px;position: fixed;}
.sidebar-medium #sidebar .sidebar-img {max-height: 40px;max-width:40px;display: block;margin: auto;margin-bottom:6px;}
.sidebar-medium .sidebar-nav li a{padding:18px 20px 12px 20px;text-align:center}
.sidebar-medium #sidebar li a span.arrow, .sidebar-medium .sidebar-nav li a .label, .sidebar-medium .sidebar-nav li a .badge{display: none;}
.sidebar-medium .sidebar-nav li a i{display: block; font-size:25px;padding-right: 0}
.sidebar-medium .sidebar-nav li.active i{color: #3187D6;}
.sidebar-medium .sidebar-nav li.current a i{color: #3187D6;}
.sidebar-medium .sidebar-nav li a i.glyph-icon{font-size:30px;}
.sidebar-medium .sidebar-nav li a:hover i{opacity:1}
.sidebar-medium li.m-b-245 {margin-bottom:0px !important}
.sidebar-medium #sidebar ul li ul {position: fixed;left: 125px;width: 220px;margin-top: -70px; }
.sidebar-medium  #sidebar ul.submenu a{background-color: #00A2D9;color: #121212}
.sidebar-medium  #sidebar ul.submenu li a:hover{color:#fff}
.sidebar-medium  #sidebar ul.submenu li.current a{color:#fff;border-left: 0;font-weight: 600}
.sidebar-medium #sidebar li.active:after {width: 0;height: 0;border-style: solid;border-width: 9px 9px 9px 0;border-color: rgba(0, 162,217, 0) #00A2D9 rgba(226, 226,226, 0)rgba(226, 226,226, 0);content: "";position: absolute;top: 50%;margin-top: -9px;right: 0;z-index: 5}
.sidebar-medium .footer-widget {display: none}

/****  Thin Sidebar  ****/
.sidebar-thin #wrapper {padding-left: 50px;}
.sidebar-thin #sidebar {width: 50px;position: fixed;overflow: auto;}
.sidebar-thin #sidebar .sidebar-img {max-width: 20px;max-height:20px;}
.sidebar-thin #sidebar li a span{display: none;}
.sidebar-thin #sidebar li ul li a span{display: block;}
.sidebar-thin .sidebar-nav li a{line-height: normal;padding:10px 10px;text-align: center;}
.sidebar-thin .sidebar-nav li a i{padding-right: 0}
.sidebar-thin .sidebar-nav li.current a i{color:#3187D6;}
.sidebar-thin li.m-b-245 {margin-bottom:0px !important}
.sidebar-thin #sidebar ul li ul {position: fixed;left: 50px;width: 220px;margin-top: -35px; }
.sidebar-thin #sidebar ul.submenu a{background-color: #00A2D9;color:#121212;}
.sidebar-thin  #sidebar ul.submenu li.current a{color:#fff;border-left: 0;font-weight: 600}
.sidebar-thin #sidebar li.active:after {width: 0;height: 0;border-style: solid;border-width: 9px 9px 9px 0;border-color: rgba(0, 162,217, 0) #00A2D9 rgba(226, 226,226, 0)rgba(226, 226,226, 0);content: "";position: absolute;top: 50%;margin-top: -9px;right: 0;z-index: 5;}
.sidebar-thin .footer-widget {display: none}

/****  Hidden Sidebar  ****/
.sidebar-hidden #sidebar {width: 100%;position: relative;overflow-y:hidden;height: 0;}
.sidebar-hidden .sidebar-nav {position: relative}
.sidebar-hidden .sidebar-nav li a{line-height: normal;padding:12px 10px;}
.sidebar-hidden li.m-b-245 {margin-bottom:0px !important}
.sidebar-hidden .footer-widget {display: none}

/****  Responsive Sidebar  ****/
@media (min-width: 769px) and (max-width: 1200px) {
  .sidebar-large #wrapper {padding-left: 125px;}
  .sidebar-large #sidebar {width: 125px;position: absolute;}
  .sidebar-large #sidebar .sidebar-img {max-height: 40px;max-width:40px;display: block;margin: auto;margin-bottom:6px;}
  .sidebar-large .sidebar-nav li a{padding:18px 20px 12px 20px;text-align:center}
  .sidebar-large #sidebar li a span.arrow, .sidebar-large .sidebar-nav li a .label, .sidebar-large .sidebar-nav li a .badge{display: none;}
  .sidebar-large .sidebar-nav li a i{display: block; font-size:25px;padding-right: 0}
  .sidebar-large .sidebar-nav li.active i{color: #3187D6;}
  .sidebar-large .sidebar-nav li.current a i{color: #3187D6;}
  .sidebar-large .sidebar-nav li a i.glyph-icon{font-size:30px;}
  .sidebar-large .sidebar-nav li a:hover i{opacity:1}
  .sidebar-large #sidebar ul li ul {position: absolute;left: 125px;width: 220px;margin-top: -70px; }
  .sidebar-large  #sidebar ul.submenu a{background-color: #00A2D9;color: #121212}
  .sidebar-large  #sidebar ul.submenu li a:hover{color:#fff}
  .sidebar-large  #sidebar ul.submenu li.current a{color:#fff;border-left: 0;font-weight: 600}
  .sidebar-large #sidebar li.active:after {width: 0;height: 0;border-style: solid;border-width: 9px 9px 9px 0;border-color: rgba(0, 162,217, 0) #00A2D9 rgba(226, 226,226, 0)rgba(226, 226,226, 0);content: "";position: absolute;top: 50%;margin-top: -9px;right: 0;z-index: 5}
  .sidebar-large .footer-widget {display: none}
}

@media (min-width: 480px) and (max-width: 768px) {
  .sidebar-toggle {display: none}
  .sidebar-large #wrapper, .sidebar-medium #wrapper {padding-left: 50px;}
  .sidebar-large #sidebar, .sidebar-medium #sidebar {width: 50px;position: absolute;}
  .sidebar-large #sidebar .sidebar-img, .sidebar-medium #sidebar .sidebar-img {max-width: 20px;max-height:20px;}
  .sidebar-large #sidebar li a span, .sidebar-medium #sidebar li a span{display: none;}
  .sidebar-large #sidebar li ul li a span, .sidebar-medium #sidebar li ul li a span{display: block;}
  .sidebar-large .sidebar-nav li a, .sidebar-medium .sidebar-nav li a{line-height: normal;padding:10px 10px;text-align: center;}
  .sidebar-large .sidebar-nav li a i, .sidebar-medium .sidebar-nav li a i{padding-right: 0}
  .sidebar-large #sidebar ul li ul, .sidebar-medium #sidebar ul li ul {position: fixed;left: 50px;width: 220px;margin-top: -35px; }
  .sidebar-large #sidebar ul.submenu a, .sidebar-medium #sidebar ul.submenu a{background-color: #00A2D9;color:white;}
  .sidebar-large #sidebar li.active:after, .sidebar-medium #sidebar li.active:after {width: 0;height: 0;border-style: solid;border-width: 9px 9px 9px 0;border-color: rgba(0, 162,217, 0) #00A2D9 rgba(226, 226,226, 0)rgba(226, 226,226, 0);content: "";position: absolute;top: 50%;margin-top: -9px;right: 0;}
  .sidebar-large .footer-widget, .sidebar-medium .footer-widget {display: none}
  .footer-widget {display: none}
  .navbar-center { display: none;}
  .navbar-header { float:left;}
  .navbar-right { float:right;}
}

@media (max-width: 479px) {
  .sidebar-large #wrapper {padding-left: 0;}
  .sidebar-large #sidebar {width: 100%;height:0;position: relative;overflow-y:hidden;}
  /* .sidebar-large #sidebar li a span{display: block;} */
  .sidebar-nav > li.m-b-245{margin-bottom: 0 !important;}
  .sidebar-large .sidebar-nav li a {line-height: normal;padding:12px 10px;}
  .footer-widget {display: none}
  .sidebar-toggle { display: none}
  .navbar-header { float:none;}
  .navbar-toggle {padding: 8px 10px;margin-top: 3px;margin-right: 15px;margin-bottom: 3px;background: #272727;border: 1px solid #8A8A8A;z-index: 2000;}
  .navbar-right, .navbar-center { display: none;}
}

/*----------------------------------- END SIDEBAR ------------------------------------*/
/*------------------------------------------------------------------------------------*/




/*------------------------------------------------------------------------------------*/
/*---------------------------------  PAGE ELEMENTS  ----------------------------------*/
.page-title .fa {font-size: 35px;margin-right: 5px;padding: 5px 12px;border: 1px solid #9399A2;color: #6B6E74;-moz-border-radius: 50%;-webkit-border-radius: 50%;border-radius: 50%;width: 48px;height: 48px;}

/****  Buttons  ****/
.btn{font-size:15px;font-weight:400;line-height:1.4;border-radius:4px;-webkit-font-smoothing:subpixel-antialiased;-webkit-transition:border .25s linear, color .25s linear, background-color .25s linear;transition:border .25s linear, color .25s linear, background-color .25s linear;padding: 7px 24px}
.btn:hover,.btn:focus{outline:none}
.btn:active,.btn.active{outline:none;-webkit-box-shadow:none;box-shadow:none}
.btn.disabled,.btn[disabled],fieldset[disabled] .btn{background-color:#bdc3c7;color:rgba(255,255,255,0.75);opacity:0.7;filter:alpha(opacity=70)}
.btn-sm{padding: 2px 10px !important;margin-left:10px;}
.buttons-page .btn {margin-bottom:10px;}
.btn-info{color:#fff;background-color:#5dade2}
.btn-info:hover,.btn-info:focus,.btn-info:active,.btn-info.active,.open .dropdown-toggle.btn-info{color:#fff;background-color:#3498db;border-color:#3498db}
.btn-info:active,.btn-info.active,.open .dropdown-toggle.btn-info{background:#2c81ba;border-color:#2c81ba}
.btn-info.disabled,.btn-info[disabled],fieldset[disabled] .btn-info,.btn-info.disabled:hover,.btn-info[disabled]:hover,fieldset[disabled] .btn-info:hover,.btn-info.disabled:focus,.btn-info[disabled]:focus,fieldset[disabled] .btn-info:focus,.btn-info.disabled:active,.btn-info[disabled]:active,fieldset[disabled] .btn-info:active,.btn-info.disabled.active,.btn-info[disabled].active,fieldset[disabled] .btn-info.active{background-color:#3498db;border-color:#3498db}
.btn-default{color:#121212 !important;background-color: #F5F5F5;border-color: #D4DEE0;}
.btn-default:hover,.btn-default:focus,.btn-default:active,.btn-default.active,.open .dropdown-toggle.btn-default{color:#121212;background-color:#ebebeb; border-color:#D4DEE0}
.btn-default:active,.btn-default.active,.open .dropdown-toggle.btn-default{background:#e1e1e1;border-color:#c9d5d8}
.btn-default.disabled,.btn-default[disabled],fieldset[disabled] .btn-default,.btn-default.disabled:hover,.btn-default[disabled]:hover,fieldset[disabled] .btn-default:hover,.btn-default.disabled:focus,.btn-default[disabled]:focus,fieldset[disabled] .btn-default:focus,.btn-default.disabled:active,.btn-default[disabled]:active,fieldset[disabled] .btn-default:active,.btn-default.disabled.active,.btn-default[disabled].active,fieldset[disabled] .btn-default.active{background-color:#d3d3d3;border-color:#d3d3d3}
.btn-white{background-color:#fff;border:1px solid #E0E0E0}
.btn-white:hover,.btn-white:focus,.btn-white:active,.btn-white.active,.open .dropdown-toggle.btn-white{color:#333;background-color:#f4f4f4;border-color:#d6d6d6}
.btn-white:active,.btn-white.active,.open .dropdown-toggle.btn-white{background:#d3d3d3;border-color:#d3d3d3}
.btn-white.disabled,.btn-white[disabled],fieldset[disabled] .btn-white,.btn-white.disabled:hover,.btn-white[disabled]:hover,fieldset[disabled] .btn-white:hover,.btn-white.disabled:focus,.btn-white[disabled]:focus,fieldset[disabled] .btn-white:focus,.btn-white.disabled:active,.btn-white[disabled]:active,fieldset[disabled] .btn-white:active,.btn-white.disabled.active,.btn-white[disabled].active,fieldset[disabled] .btn-white.active{background-color:#E0E0E0;border-color:#E0E0E0}
.btn-blue{color:#fff;background-color:#00A2D9}
.btn-blue:hover,.btn-blue:focus,.btn-blue:active,.btn-blue.active,.open .dropdown-toggle.btn-blue{color:#fff;background-color:#008fc0;border-color:#008fc0}
.btn-blue:active,.btn-blue.active,.open .dropdown-toggle.btn-blue{background:#007ca7;border-color:#007ca7}
.btn-blue.disabled,.btn-blue[disabled],fieldset[disabled] .btn-blue,.btn-blue.disabled:hover,.btn-blue[disabled]:hover,fieldset[disabled] .btn-blue:hover,.btn-blue.disabled:focus,.btn-blue[disabled]:focus,fieldset[disabled] .btn-blue:focus,.btn-blue.disabled:active,.btn-blue[disabled]:active,fieldset[disabled] .btn-blue:active,.btn-blue.disabled.active,.btn-blue[disabled].active,fieldset[disabled] .btn-blue.active{background-color:#008fc0;border-color:#008fc0}
.btn-danger{color:#fff;background-color:#C75757}
.btn-danger:hover,.btn-danger:focus,.btn-danger:active,.btn-danger.active,.open .dropdown-toggle.btn-danger{color:#fff;background-color:#B32E2A;border-color:#B32E2A}
.btn-danger:active,.btn-danger.active,.open .dropdown-toggle.btn-danger{background:#9e2925;border-color:#9e2925}
.btn-danger.disabled,.btn-danger[disabled],fieldset[disabled] .btn-danger,.btn-danger.disabled:hover,.btn-danger[disabled]:hover,fieldset[disabled] .btn-danger:hover,.btn-danger.disabled:focus,.btn-danger[disabled]:focus,fieldset[disabled] .btn-danger:focus,.btn-danger.disabled:active,.btn-danger[disabled]:active,fieldset[disabled] .btn-danger:active,.btn-danger.disabled.active,.btn-danger[disabled].active,fieldset[disabled] .btn-danger.active{background-color:#B32E2A;border-color:#B32E2A}
.btn-dark{color:#fff;background-color:#2B2E33}
.btn-dark:hover,.btn-dark:focus,.btn-dark:active,.btn-dark.active,.open .dropdown-toggle.btn-dark{color:#fff;background-color:#1f2225;border-color:#1f2225}
.btn-dark:active,.btn-dark.active,.open .dropdown-toggle.btn-dark{background:#131517;border-color:#131517}
.btn-dark.disabled,.btn-dark[disabled],fieldset[disabled] .btn-dark,.btn-dark.disabled:hover,.btn-dark[disabled]:hover,fieldset[disabled] .btn-dark:hover,.btn-dark.disabled:focus,.btn-dark[disabled]:focus,fieldset[disabled] .btn-dark:focus,.btn-dark.disabled:active,.btn-dark[disabled]:active,fieldset[disabled] .btn-dark:active,.btn-dark.disabled.active,.btn-dark[disabled].active,fieldset[disabled] .btn-dark.active{background-color:#1f2225;border-color:#1f2225}
.btn-success{color:#fff;background-color:#18A689}
.btn-success:hover,.btn-success:focus,.btn-success:active,.btn-success.active,.open .dropdown-toggle.btn-success{color:#fff;background-color:#159077;border-color:#159077}
.btn-success:active,.btn-success.active,.open .dropdown-toggle.btn-success{background:#127964;border-color:#127964}
.btn-success.disabled,.btn-success[disabled],fieldset[disabled] .btn-success,.btn-success.disabled:hover,.btn-success[disabled]:hover,fieldset[disabled] .btn-success:hover,.btn-success.disabled:focus,.btn-success[disabled]:focus,fieldset[disabled] .btn-success:focus,.btn-success.disabled:active,.btn-success[disabled]:active,fieldset[disabled] .btn-success:active,.btn-success.disabled.active,.btn-success[disabled].active,fieldset[disabled] .btn-success.active{background-color:#159077;border-color:#159077}
.btn.btn-transparent{background-color: rgba(0, 0, 0, 0);}
.btn-default.btn-transparent {color: #333;border: 1px solid #D3D7DB !important;}
.btn-primary.btn-transparent {color: #2489C5;border: 1px solid #2489C5 !important;}
.btn-info.btn-transparent {color: #5BC0DE;border: 1px solid #5BC0DE !important;}
.btn-warning.btn-transparent {color: #F0AD4E;border: 1px solid #F0AD4E !important;}
.btn-danger.btn-transparent {color: #D9534F;border: 1px solid #D9534F !important;}
.btn-success.btn-transparent {color: #5CB85C;border: 1px solid #5CB85C !important;}
.btn-dark.btn-transparent{color: #2B2E33 !important;border: 1px solid #2B2E33 !important; }
.btn-default.btn-transparent:hover {color: #333;border: 1px solid #c5cad0;background-color: rgba(197, 202, 208, 0.2)}
.btn-primary.btn-transparent:hover {color: #258cd1;border: 1px solid #258cd1;background-color: rgba(37, 140, 209, 0.1)}
.btn-info.btn-transparent:hover {color: #46b8da;border: 1px solid #46b8da;background-color: rgba(70, 184, 218, 0.1)}
.btn-warning.btn-transparent:hover {color: #eea236;border: 1px solid #eea236;background-color: rgba(238, 162, 54, 0.1)}
.btn-danger.btn-transparent:hover {color: #d43f3a;border: 1px solid #d43f3a;background-color: rgba(212, 63, 58, 0.1)}
.btn-success.btn-transparent:hover {color: #4cae4c;border: 1px solid #4cae4c;background-color: rgba(76, 174, 76, 0.1);}
.btn-dark.btn-transparent:hover{color: #1f2225;border: 1px solid #1f2225;background-color: rgba(31, 34, 37, 0.1);}
.btn.btn-rounded{border-radius:50px;}
.btn-group .btn {margin-right:0;}
.btn-group-vertical {margin-right:20px;}
.btn-group-vertical .btn {margin-bottom:0;}
.btn-block i{margin-top: .2em;}
.btn-icon {padding:7px 11px;height: 35px;width: 35px;}
.btn-icon i {width: 11px;}
.btn.btn-lg{padding: 12px 48px;font-size:15px;}
.btn.btn-lg:hover{color:white;}

.label-dark {background-color: rgba(0, 0, 0, 0.6);padding: .4em .8em .5em;}

/****  Pagination  ****/
.pagination > li > a, .pagination > li > span {border: none;}
.pagination > li:first-child > a,.pagination > li:first-child > span { border-bottom-left-radius: 3px; border-top-left-radius: 3px;}
.pagination > li:last-child > a,.pagination > li:last-child > span {   border-bottom-right-radius: 3px;   border-top-right-radius: 3px;}
.pagination > li > a,.pagination > li > span { color: #636e7b;}
.pagination > li > a:hover,.pagination > li > span:hover,.pagination > li > a:focus,.pagination > li > span:focus { background-color: #e4e7ea;}
.pagination li { margin-left: 5px;  display: inline-block; float: left;}
.pagination li:first-child {  margin-left: 0;}
.pagination li a {  -moz-border-radius: 3px;  -webkit-border-radius: 3px;  border-radius: 3px;}
.pagination > .active > a,.pagination > .active > span,.pagination > .active > a:hover,.pagination > .active > span:hover,.pagination > .active > a:focus,
.pagination > .active > span:focus { background-color: #2B2E33; border-color: #2B2E33;}
ul.pagination > .disabled > span, ul.pagination > .disabled > a, ul.pagination > .disabled > a i, ul.pagination > .disabled > a:hover, ul.pagination > .disabled > a:focus {opacity: 0 !important;}
.pagination > li:last-child > a, .pagination > li:last-child > span, .pagination > li:first-child > a, .pagination > li:first-child > span {padding: 6px !important;}

/****  Panels  ****/
.panel-default>.panel-heading{padding: 15px 15px 8px 15px;background-color: #fff ;border-color: #fff;}
.panel-title{display: inline-block;font-size: 18px;font-weight: 400;margin: 5px 0 5px 0;padding: 0;width: 50%;font-family: 'Carrois Gothic', sans-serif;}
.bg-red .panel-title{color:#fff;}
.panel-tools{display: inline-block;padding: 0;margin: 0;margin-top: 0px;}
.panel-tools a{margin-left:6px;}
.panel-body{background-color: #FFF;padding: 26px;}
.sortable .panel, .sortable {cursor:move;}
.panels_draggable .ui-sortable {min-height: 100px}

/****  Forms  ****/
.form-control {display: inline-block;width: 100%;height: auto;padding: 8px 12px;font-size: 13px;color: #555;background-color: #FFF !important;border: 1px solid #D3D3D3;border-radius: 2px;-moz-transition: all 0.2s linear 0s, box-shadow 0.2s linear 0s;-o-transition: all 0.2s linear 0s, box-shadow 0.2s linear 0s;transition: all 0.2s linear 0s, box-shadow 0.2s linear 0s;-webkit-box-shadow: none;box-shadow: none;}
.form-control.input-lg {height: 46px;padding: 10px 16px;font-size: 18px;line-height: 1.33;border-radius: 6px;}
.form-control..input-sm {height: 30px;padding: 5px 10px;font-size: 12px;line-height: 1.5;border-radius: 3px;}
.form-control:focus {  border-color: #A0BDDA;  background-color:#e9ebef;  -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none;}
.div_checkbox{position:relative;margin-top:0px;}
.input-icon {position: relative;}
.input-icon i {width: 16px;height: 16px;font-size: 16px;color: #e5eaec;display: block;text-align: center;position: absolute;margin:10px;}
.input-icon input {padding-left: 34px !important;}
.input-icon.right  input {padding-right: 34px !important; padding-left:10px !important;}
.input-icon.right i {right: 17px;margin:10px 0 10px;}
.input-icon.right .fa-check {display: block;}
.input-icon.right .parsley-success .fa-check {display: block}
.selectpicker li {list-style-type: none !important}
.switch-toggle {margin-bottom: 10px}

/****  Slider  ****/
.danger .slider-selection {background-color: #C75757;}
.primary .slider-selection {background-color: #428BCA;}
.success .slider-selection {background-color: #18A689;}
.warning .slider-selection {background-color: #F0AD4E}
.danger .slider-track, .primary .slider-track, .success .slider-track, .warning .slider-track  {background-color: #f0f0f0;}
.danger .slider-handle, .primary .slider-handle, .success .slider-handle, .warning .slider-handle{background-color: #fff;background-image: none;-webkit-box-shadow: inset 0 2px 1px -1px #FFF, 0px 1px 3px rgba(0, 0, 0, 0.4);-moz-box-shadow: inset 0 2px 1px -1px #fff, 0px 1px 3px rgba(0, 0, 0, 0.4);box-shadow: inset 0 2px 1px -1px #FFF,0px 1px 3px rgba(0, 0, 0, 0.4);}
label {font-weight: 400;}
.form-group .tips{font-style: italic;color:#A8A8A8;}
.form-group strong{font-weight:600;}
.form-control.password {background: #FFF url(../img/small/key_small.png) no-repeat 95% center;-webkit-transition:all 0s ease;-moz-transition:all 0s ease;-ms-transition:all 0s ease;-o-transition:all 0s ease;transition:all 0s ease;}
.form-control.user {background: #FFF url(../img/small/user_small.png) no-repeat 95% center;-webkit-transition:all 0s ease;-moz-transition:all 0s ease;-ms-transition:all 0s ease;-o-transition:all 0s ease;transition:all 0s ease;}

/****  Forms Validation ****/
input.parsley-success, textarea.parsley-success {color: #468847 !important; background-color: #DFF0D8 !important; border: 1px solid #D6E9C6 !important;}
input.parsley-error, textarea.parsley-error {color: #B94A48 !important;background-color: #F2DEDE !important;border: 1px solid #EED3D7 !important;}
.parsley-error-list {color: #D9534F;font-size: 12px;padding-top: 5px;}

/****  Forms Wizard ****/
.form-wizard .ui-radio label {background: transparent !important}
.form-wizard .ui-checkbox input,.form-wizard .ui-radio input {left: .6em;margin: -17px 0 0 0;}
.form-wizard .ui-checkbox .ui-btn,.form-wizard .ui-radio .ui-btn {z-index: 1;}
.form-wizard .ui-checkbox-off:after, .form-wizard .ui-btn.ui-radio-off:after {filter: Alpha(Opacity=0);opacity: 0;}

/****  Icons  ****/
#main-content.icons-panel li a{color:#333;font-size:16px;padding:10px 25px;}
.icons-panel li.active {margin-left: -1px;}
.icons-panel .tab-pane {background-color: #FFF;padding: 10px 25px;}
#glyphicons li {display: inline-block;text-align: left;color: #808080;height: 60px;line-height: 31px;vertical-align: bottom;}
#glyphicons span.glyphicon {font-size: 1.6em;color:#366992;}
#glyphicons .glyphicon {font-size: 1.5em;margin-bottom:0;color:#AAA;padding-right: 15px;}
a.zocial{margin:10px 10px;}
div.social-btn {display:inline-block; width:300px;overflow:hidden;}
div.social-btn-small {display:inline-block; width:65px;overflow:hidden;}
.icons-panel .fa-item{color:#366992;padding-right: 15px;height: 60px;}
.icons-panel i.fa{font-size: 1.6em;color:#366992;padding-right: 15px;}
.icons-panel .glyphicon-item {color:#366992;padding-right: 15px;height: 60px;}
.icons-panel .glyphicon-item span{padding-right: 20px}
/****  Icons  ****/
#entypo div.col-md-3 {color: #808080;height: 58px;}
#entypo .icon:before {color:#366992;}

/*****  Alerts & Notifications  ****/
.alert.bg-blue, .alert.bg-green, .alert.bg-purple, .alert.bg-gray-light, .alert.bg-gray, .alert.bg-white, .alert.bg-red {border-radius: 2px;}
.alert-success { border:1px solid #b8d0af;}
.badge-dark{background-color: #2B2E33 !important;}
.badge-white{background-color: #fff !important;color:#2B2E33 !important;}
.badge-default{background-color: #999 !important;}
.badge-primary{background-color: #3598DB !important;}
.badge-success{background-color: #378037 !important ;}
.badge-info{background-color: #5BC0DE !important;}
.badge-warning{background-color: #F0AD4E !important;}
.badge-danger {background-color: #D9534F !important;}

/****  Popover  ****/
.popover-dark {border: 1px solid #42474f;background-color: #2B2E33}
.popover-dark.top .arrow:after {border-top-color: #2B2E33;}
.popover-dark.bottom .arrow:after {border-bottom-color: #2B2E33;}
.popover-dark.left .arrow:after {border-left-color: #2B2E33;}
.popover-dark.right .arrow:after {border-right-color: #2B2E33;}
.popover-dark .popover-title {background-color: #3A3A3A;color:#fff;border:none; border-bottom: 1px solid #42474f;} 
.popover-dark .popover-content {background-color: #2B2E33;color:#fff;padding: 9px 14px;}

/****  Progressbar  ****/
.progress.progress-bar-thin {height: 5px;}
.progress.progress-bar-large {height: 20px;}
.progress.progress-bar-large .progress-bar {line-height: 20px;}
.progress-bar-success {background-color: #18A689;}

/****  Modals  ****/
.modal-full {width:98%;}
.modal-footer.text-center{text-align:center;}
.modal-panel .btn{margin-bottom:10px;}
ul.list-unstyled{margin:0;padding:0;list-style:none}
.nv-axisMaxMin{color:red !important;}

/****  Panel  ****/
.panel-content {padding:0;}
.panel-stat {position:relative;overflow: hidden;border:none;}
.panel-stat h3 { color:#fff;}
.panel-stat .icon {color: rgba(0, 0, 0, 0.1);position: absolute;right: 5px;bottom: 45px;z-index: 1;}
.panel-stat .bg-dark .icon {color: rgba(255, 255, 255, 0.1);}
.panel-stat .icon i {font-size: 100px;line-height: 0;margin: 0;padding: 0;vertical-align: bottom;}
.panel .stat-num {font-size: 36px;font-weight: bold;}
.panel-stat .stat-title {opacity: 0.7;text-transform: uppercase;}
@media (min-width: 768px) and (max-width: 1200px) {
  .panel-stat h1 {font-size: 24px}
  .panel-stat h3 {font-size: 18px}
}
.panel-icon  {text-align:center;}
.panel-icon .panel-body {font-size:80px;padding:15px 35px}
.panel-icon .panel-footer {border-top: none; background-color: rgba(0, 0, 0, 0.1) !important;padding:1px 15px;}
.hover-effect:hover .panel-body.bg-red{ -moz-transition: all 0.3s linear 0s;-o-transition: all 0.3s linear 0s;transition: all 0.3s linear 0s;background-color: #c24848 !important}
.hover-effect:hover .panel-footer{ -moz-transition: all 0.3s linear 0s;-o-transition: all 0.3s linear 0s;transition: all 0.3s linear 0s;background-color: rgba(0, 0, 0, 0.2) !important; }
.bg-transparent, .bg-transparent .panel-body, .bg-transparent .panel-footer{background:none !important;}
.bg-transparent .panel-body{padding:0 35px;font-size:95px;}

/****  Datepicker  ****/
.datepicker { padding: 0px; border-radius: 2px; font-size: 13px; }
.datepicker th, .datepicker td { padding:4px 12px !important; text-align: center;}
.datepicker table tr td.day:hover {background: #eeeeee;cursor: pointer;}
.datepicker table tr td.active,.datepicker table tr td.active:hover,.datepicker table tr td.active.disabled,.datepicker table tr td.active.disabled:hover {background-color: #006dcc;color: #fff;}
.datepicker thead tr:first-child th,.datepicker tfoot tr:first-child th {cursor: pointer;}
.datepicker thead tr:first-child th:hover,.datepicker tfoot tr:first-child th:hover {background: #eeeeee;}
.datepicker thead tr .datepicker-switch { color: #6f7b8a;  }
.datepicker thead tr .dow { color: #00A2D9; text-transform: uppercase; font-size: 11px; }
.datepicker tbody tr .odd { color: #d0d3d8; }
.datepicker thead tr .next:before {color: #00A2D9;font-family: 'FontAwesome';content: "\f054";}
.datepicker thead tr .prev:before {color: #00A2D9;font-family: 'FontAwesome';content: "\f053";}
.datepicker table tr td.old, .datepicker table tr td.new { color: #d0d3d8; }
.datepicker table tr td.active, .datepicker table tr td.active:hover, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled:hover { background-image: none; text-shadow: none; font-weight: 600; }
.datepicker table tr td.today, .datepicker table tr td.today:hover, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover { background-color: #e5e9ec; background-image: none; color: #fff; }
.datepicker table tr td.day:hover { background: #eeeeee; opacity: 0.65; }
.datepicker table tr td.active:hover, .datepicker table tr td.active:hover:hover, .datepicker table tr td.active.disabled:hover, 
.datepicker table tr td.active.disabled:hover:hover, .datepicker table tr td.active:active, .datepicker table tr td.active:hover:active, 
.datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active.active, 
.datepicker table tr td.active.active:hover, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled.active:hover, 
.datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active.disabled.disabled, .datepicker table tr td.active.disabled.disabled:hover, .datepicker table tr td.active[disabled], .datepicker table tr td.active[disabled]:hover, .datepicker table tr td.active.disabled[disabled], .datepicker table tr td.active.disabled[disabled]:hover{ background-color: #00A2D9; }
.datepicker table tr td span.active, .datepicker table tr td span.active:hover, .datepicker table tr td span.active.disabled, 
.datepicker table tr td span.active.disabled:hover { background-image: none; border: none; text-shadow: none; }
.datepicker table tr td span.active:hover, .datepicker table tr td span.active:hover:hover, .datepicker table tr td span.active.disabled:hover, 
.datepicker table tr td span.active.disabled:hover:hover, .datepicker table tr td span.active:active, .datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active.active,
.datepicker table tr td span.active.active:hover, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled.active:hover, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active.disabled.disabled, .datepicker table tr td span.active.disabled.disabled:hover, .datepicker table tr td span.active[disabled], .datepicker table tr td span.active[disabled]:hover, .datepicker table tr td span.active.disabled[disabled], .datepicker table tr td span.active.disabled[disabled]:hover { background-color: #00A2D9; }
.datepicker table tr td span { border-radius: 4px 4px 4px 4px; }
.datepicker-inline { width: auto; }
.datepicker table {border:1px solid #eee;}
.datepicker td span{display:block;width:47px;height:54px;line-height:54px;float:left;margin:2px;cursor:pointer;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px}
.datepicker-months {max-width: 282px}
.datepicker-years {max-width: 282px}

/****  Tabs  ****/
.dropdown-menu a {color:#121212;}
.dropdown-menu a:hover {background:none;}
.dropdown-menu li a:hover {background:#f5f5f5;}
.nav.nav-tabs > li > a {background-color: #CFDAE0;color:#121212;}
.nav.nav-tabs > li.active > a {background-color: white;color:#121212}
.tab-content {background-color: #FFF;padding: 15px;}
.nav.nav-tabs.nav-dark > li > a {background-color: #2B2E33;color:#dadada;}
.nav.nav-tabs > li.active > a {background-color: white;color:#121212}
.tab-content {background-color: #FFF;padding: 15px;}
.nav-tabs.nav-dark .dropdown-menu {background-color: #2B2E33;}
.nav.nav-tabs.nav-dark .dropdown-menu a {color: #dadada;}
.nav.nav-tabs.nav-dark .dropdown-menu li:hover {background:#373a41;}
.tab_left > .tab-content  { overflow: hidden;}
.tab_left > .nav-tabs {    float: left; border-right: 1px solid #DDD; margin-right:0;}
.tab_left  > .nav-tabs > li {    float: none;}
.tab_left  > .nav-tabs > li > a {   margin-right: 0; min-width: 80px; border: none;}
.tab_left > .nav-tabs { }
.tab_left > .nav-tabs > li > a {    border-radius: 0;margin-right: -1px;}
.tab_left > .nav-tabs > li > a:hover, .tabs-left > .nav-tabs > li > a:focus {    border: none;}
.tab_right > .tab-content  { overflow: hidden;}
.tab_right > .nav-tabs {    float: right;    margin-left:0px; border-left: 1px solid #DDD;}
.tab_right  > .nav-tabs > li {    float: none;}
.tab_right  > .nav-tabs > li > a {   margin-left: 0; min-width: 80px; border: none;}
.tab_right > .nav-tabs > li > a {    border-radius: 0;margin-left: -1px;}
.tab_right > .nav-tabs > li > a:hover, .tabs-left > .nav-tabs > li > a:focus {    border: none;}

/****  Accordion  ****/
.panel-accordion .panel-title {width: 100%;margin-bottom: 0;}
.panel-accordion .panel-default>.panel-heading {padding: 0;background-color: #FFF;border-color: #FFF;}
.panel-accordion h4 {margin-top: 0}
.panel-accordion  a {color: #121212;display:inline-block;width: 100%;padding: 15px}
.panel-accordion a {background-color: #FFF;color:#121212;}
.panel-accordion a.collapsed {background-color: #CFDAE0;color:#121212}
.panel-accordion.dark-accordion  a {background-color: #FFF;color:#121212;}
.panel-accordion.dark-accordion  a.collapsed {background-color: #2B2E33;color:#dadada}
.panel-accordion.dark-accordion .panel-heading .panel-title > a.collapsed:after {color: #767B80;}
.panel-accordion  a:hover {text-decoration:none;}
.panel-heading .panel-titl e > a:after {font-family: 'FontAwesome';content: "\f068";float: right;color: #DFE5E9;}
.panel-heading .panel-title > a.collapsed:after {font-family: 'FontAwesome';content: "\f067";float: right;color: #88A5C0;}

/****  Notifications  ****/
.notification_position{border: 2px dashed #DFE5E9;width:90%;height:250px;position: relative;}
.notification_position .bit { background-color: #DFE5E9;cursor: pointer;position: absolute; }
.notification_position .bit:hover { background-color: #d0d9df; }
.notification_position .bit.top, .notification_position .bit.bottom {  height: 22%;  width: 40%;  margin: 0 30%; }
.notification_position .bit.medium { top: 40%;height:21%; margin: 0 30%;width:40%;}
.notification_position .bit.right, .notification_position .bit.left {  height: 22%;  width: 20%;  margin-left: 0;  margin-right: 0; }
.notification_position .bit.top { top: 0; }
.notification_position .bit.bottom { bottom: 0; }
.notification_position .bit.right { right: 0; }
.notification_position .bit.left { left: 0; }

/****  Nestable & Sortable List  ****/
.dragged {position: absolute;  top: 0; left:-500px; opacity: 0.5;  z-index: 2000; }
ul.vertical {  margin: 0 0 9px 0; padding-left:0; max-width:600px;}
ul.vertical li {display: block; margin: 5px; padding: 5px 9px;border-radius:3px; color: #222;font-weight: 600;background: #DFE5E9;}
ul.vertical li:hover {cursor:pointer; background: #d3dce1;}
ul.vertical li.placeholder {position: relative; margin: 0; padding: 0; border: none; }   
ul.vertical li.placeholder:before { position: absolute; content: ""; width: 0; height: 0; margin-top: -5px; left: -5px; top: -4px;  border: 5px solid transparent;border-left-color: red; border-right: none; }
ul {  list-style-type: none; }
ul i.icon-move { cursor: pointer; }  
ul li.highlight { background: #333333; color: #999999; }
ul.nested_with_switch, ul.nested_with_switch ul { border: 1px solid #eeeeee; }
ul.nested_with_switch.active, ul.nested_with_switch ul.active { border: 1px solid #333333; }
ul.nested_with_switch li, ul.simple_with_animation li, ul.default li {  cursor: pointer; }
.switch-container { display: block;  margin-left: auto;  margin-right: auto;  width: 80px; }
.navbar-sort-container { height: 200px; }
ul.nav li, ul.nav li a { cursor: pointer; }
ul.nav .divider-vertical { cursor: default; }
ul.nav li.dragged { background-color: #2c2c2c; }
ul.nav li.placeholder { position: relative; }
ul.nav li.placeholder:before {  content: ""; position: absolute; width: 0; height: 0; border: 5px solid transparent;border-top-color: #00A2D9; top: -6px; margin-left: -5px; border-bottom: none; }
ul.nav ul.dropdown-menu li.placeholder:before {  border: 5px solid transparent; border-left-color: red;  margin-top: -5px;margin-left: none;  top: 0;  left: 10px; border-right: none; }
.sortable_table tr {  cursor: pointer; }
.sortable_table tr.placeholder {  height:37px; margin: 0; padding: 0;  border: none; }
.sortable_table tr.placeholder td{ background: #CBD5DB !important; }
.sortable_table tr.dragged td{ background: #CBD5DB !important; }
.sortable_table tr.placeholder:before {   position: absolute; width: 0; height: 0; border: 5px solid transparent; border-left-color: red; margin-top: -5px; left: -5px;border-right: none; }
.sorted_head th {  cursor: pointer; }
.sorted_head th.placeholder { display: block; background: #CBD5DB;  position: relative; width: 0; height: 0; margin: 0;  padding: 0; }  
.sorted_head th.placeholder:before { content: ""; position: absolute;  width: 0; height: 0; border: 5px solid transparent; border-top-color: red; top: -6px; margin-left: -5px;  border-bottom: none; }
.ui-sortable-placeholder{background-color:#DFE5E9 !important;visibility:visible !important;border: 1px dashed #b6bcbf;}
.dd { position: relative; display: block; margin: 0; padding: 0; max-width: 600px; list-style: none; font-size: 13px; line-height: 20px; }
.dd-list { display: block; position: relative; margin: 0; padding: 0; list-style: none; }
.dd-list .dd-list { padding-left: 30px; }
.dd-collapsed .dd-list { display: none; }
.dd-item,.dd-empty,.dd-placeholder { display: block; position: relative; margin: 0; padding: 0; min-height: 20px; font-size: 13px; line-height: 20px; }
.dd-handle { display: block; height: 30px; margin: 5px 0; padding: 5px 10px; color: #6F7B8A; text-decoration: none; font-weight: 600; border: 1px solid #e5e9ec;   background: #DFE5E9;    -webkit-border-radius: 3px;border-radius: 3px; box-sizing: border-box; -moz-box-sizing: border-box;}
.dd-handle:hover {  background-color:#d3dce1;cursor:pointer; }
.dd-item > button { display: block; position: relative; cursor: pointer; float: left; width: 25px; height: 20px; margin: 5px 0; padding: 0; text-indent: 100%; white-space: nowrap; overflow: hidden; border: 0; background: transparent; font-size: 12px; line-height: 1; text-align: center; font-weight: bold; }
.dd-item > button:before { content: '+'; display: block; position: absolute; width: 100%; text-align: center; text-indent: 0; }
.dd-item > button[data-action="collapse"]:before { content: '-'; }
.dd-placeholder,.dd-empty { margin: 5px 0; padding: 0; min-height: 30px; background: #f2fbff; border: 1px dashed #b6bcbf; box-sizing: border-box; -moz-box-sizing: border-box; }
.dd-empty { border: 1px dashed #bbb; min-height: 100px; background-color: #e5e5e5;background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff); background-image:  -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff),  -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);background-image:  linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);background-size: 60px 60px;background-position: 0 0, 30px 30px;}
.dd-dragel { position: absolute; pointer-events: none; z-index: 9999; }
.dd-dragel > .dd-item .dd-handle { margin-top: 0; }
.dd-dragel .dd-handle {  -webkit-box-shadow: 2px 4px 6px 0 rgba(0,0,0,.1); box-shadow: 2px 4px 6px 0 rgba(0,0,0,.1);}
.nestable-lists { display: block; clear: both; padding: 0; width: 100%; border: 0; }
#nestable-menu { padding: 0; margin: 20px 0; }
#nestable-output,#nestable2-output { width: 100%; height: 7em; font-size: 0.75em; line-height: 1.333333em; font-family: Consolas, monospace; padding: 5px; box-sizing: border-box; -moz-box-sizing: border-box; }
.dark .dd-handle { color: #6F7B8A; border: none; background: #d9e0e4;}.dark  .dd-handle:hover { background: #d1dade;color: #505458; }
.dark  .dd-item > button:before {color: #8E9AA2; }
.dd-hover > .dd-handle { background: #2ea8e5 !important; }
.dd3-content { display: block; height: 30px; margin: 5px 0; padding: 5px 10px 5px 40px; color: #333; text-decoration: none; font-weight: bold; border: 1px solid #ccc;background: #fafafa; background: -webkit-linear-gradient(top, #fafafa 0%, #eee 100%); background: -moz-linear-gradient(top, #fafafa 0%, #eee 100%);background: linear-gradent(top, #fafafa 0%, #eee 100%);-webkit-border-radius: 3px; border-radius: 3px; box-sizing: border-box; -moz-box-sizing: border-box;}
.dd3-content:hover { color: #2ea8e5; background: #fff; }
.dd-dragel > .dd3-item > .dd3-content { margin: 0; }
.dd3-item > button { margin-left: 30px; }
.dd3-handle { position: absolute; margin: 0; left: 0; top: 0; cursor: pointer; width: 30px; text-indent: 100%; white-space: nowrap; overflow: hidden;border: 1px solid #aaa;  background: #ddd; background: -webkit-linear-gradient(top, #ddd 0%, #bbb 100%); background: -moz-linear-gradient(top, #ddd 0%, #bbb 100%);    background: linear-gradient(top, #ddd 0%, #bbb 100%);    border-top-right-radius: 0;border-bottom-right-radius: 0;}
.dd3-handle:before { content: '≡'; display: block; position: absolute; left: 0; top: 3px; width: 100%; text-align: center; text-indent: 0; color: #fff; font-size: 20px; font-weight: normal; }
.dd3-handle:hover { background: #ddd; }
.nestable-dark .dd-handle { background: #30353D; color:#dadada; }
.nestable-dark .dd-item > button  { color:#dadada; }

/****  Tables  ****/
th .div_checkbox{margin-top: -20px;} 
table .progress{margin-bottom:0;}
table tr.selected td{background-color: #f7fed5 !important}
table>tbody>tr.selected:hover>td, .table-hover>tbody>tr.selected:hover>th {background-color: #f4fec1 !important}
.table th {text-transform: uppercase;}
.table-striped td:last-child {-webkit-border-radius: 3px;-moz-border-radius: 3px;border-radius: 3px;}
.table-striped td:last-child {-webkit-border-radius: 3px;-moz-border-radius: 3px;border-radius: 3px;}
.table-hover thead th {border-bottom:2px solid #F0F4F8;}
.table-hover td {border-bottom:2px solid #F0F4F8;}
.table-bordered thead th{background-color:#F0F4F8;}
.table-hover > tbody > tr:hover > td, .table-hover > tbody > tr:hover > th {background-color: #F5F5F5;color:#000;}
table.dataTable thead .sorting_desc:before {content: "\f107";font-family: fontAwesome;float: right;color:#C75757 ;}
table.dataTable thead .sorting_asc:before { content: "\f106";font-family: fontAwesome;float: right;color:#C75757}
.table-red .pagination > .active > a,.table-red .pagination > .active > span,.table-red .pagination > .active > a:hover,.table-red .pagination > .active > span:hover,.table-red .pagination > .active > a:focus,.table-red .pagination > .active > span:focus {background-color: #C75757;border-color: #C75757;}
.table-red .pagination > .active > span:focus { background-color: #C75757;border-color: #C75757;}
.table-red div.dataTables_info {color: #D64545;}
#main-content .table a {/* color:#fff; */margin-bottom: 5px}
div.dataTables_info {padding-top: 8px;color: #8BA0B6;font-size: 13px;}
.dataTables_filter {float:left; }
.filter-right .dataTables_filter {float:right;}
.DTTT_container {float:right; }
.DTTT_container .btn{margin-left: 10px }
.dataTable .fa-plus-square-o {cursor:pointer;}
.dataTable .sorting_asc, .dataTable .sorting_desc, .dataTable .sorting {cursor:pointer;}
@media (max-width: 767px){
  .table-responsive {border: none;}
}

/****  Full Calendar  ****/
.calender-options-wrapper { padding: 13px; padding: 20px; }
.calender-options-wrapper .events-wrapper { margin-top: 50px; }
.calender-options-wrapper .events-heading { font-size: 13px; color: #fff; border-bottom: 1px solid rgba(255,255,255,0.25); padding-bottom: 14px; margin-bottom: 20px }
.calender-options-wrapper .external-event { font-size: 12px; color: #fff; background-color: #d44443; display: block; padding: 5px 8px; border-radius: 3px; width: 100%; margin-bottom: 8px; cursor: move; }
.fc-view { margin-top: 15px; }
table.fc-border-separate { margin-top: 20px; }
.fc-grid th { text-transform: uppercase; padding-bottom: 10px; }
.fc-border-separate th, .fc-border-separate td { border-width: 0px; border-bottom: 1px solid #e5e9ec; }
.fc-border-separate tr.fc-last th, .fc-border-separate tr.fc-last td { /*border-right-width: 0px;*/ }
.fc-border-separate td.fc-last { border-right-width: 0px; }
.fc-border-separate tr.fc-last td { border-bottom-width: 0px; }
.fc-grid .fc-day-number { padding: 10px; }
.fc-state-highlight { background-color: transparent; }
.fc-state-highlight .fc-day-number { background-color: #292929; border-radius: 2px; color: #fff; margin-top: 10px; }
.fc-ltr .fc-event-hori.fc-event-start, .fc-rtl .fc-event-hori.fc-event-end { margin-top: 10px; }
.fc table thead tr th { font-size: 0.9em; }
.fc-button-prev, .fc-button-next{ background-color: #0090D9 !important;color:#FFF !important;font-weight:400;}
.fc-button-prev:hover, .fc-button-next:hover{ background-color: #0688CA !important;}
..fc-border-separate tbody tr.fc-first td div { max-height:80px !important;}
.fc-event-title {font-size: 12px;}
#external-events h4 {  font-size: 16px; margin-top: 0;padding-top: 1em;  }
.external-event {  margin: 10px 0;padding: 6px;  cursor: pointer;    padding: 6px;    border-radius: 3px  }
.fc-event-time{display:none;}
#external-events p input {    margin: 0;    vertical-align: middle;  }

/****  Google Map  ****/
.map-panel .panel-body {padding: 0;}
.map-panel #instructions li {display:block;height:21px;padding-left:15px;color:#6175A0;}
.map{  display: block;  width: 100%;  height: 350px;  margin: 0 auto; }
.overlay_arrow{  left:50%;  margin-left:-16px;  width:0;  height:0;  position:absolute;}
.overlay_arrow.above{  bottom:-15px;  border-left:16px solid transparent;  border-right:16px solid transparent;  border-top:16px solid #336699;}
.overlay_arrow.below{  top:-15px;  border-left:16px solid transparent;  border-right:16px solid transparent;  border-bottom:16px solid #336699;}
.map.large {height: 500px;}
.map_geocoding{position: absolute;background-color: #FFF;padding: 20px;top: 5px;left: 20%;width: 50%;}

/****  Charts  ****/
.flot-chart {display: block; height: 400px;}
.flot-chart-content {width: 100%;height: 100%;}
.jqstooltip { position: absolute;left: 0px;top: 0px;visibility: hidden;background: rgb(0, 0, 0) transparent;
  background-color: rgba(0,0,0,0.6);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000)";color: white;
  font: 10px arial, san serif;text-align: left;white-space: nowrap;padding: 5px;border: 1px solid white;z-index: 10000;border:none;}
.jqsfield { color: white;font: 10px arial, san serif;text-align: left;}
#tooltip{position: absolute; padding: 2px; background-color: #cbe4f6; z-index: 999; padding:2px 8px;opacity: 0.9; top: 458px; left: 565px; display: none;}
#flot-tooltip { font-size: 12px; font-family: Verdana, Arial, sans-serif; position: absolute; display: none; padding: 2px; background-color: #4f4f4f; color:#fff;opacity: 0.8; }
@media (max-width: 480px) {
  .nvd3.nv-legend {display: none}
  #d3_chart1  {margin-top:-200px;}
}


/*------------------------------------------------------------------------------------*/
/*----------------------------------- CUSTOM PAGES -----------------------------------*/

/****  Messages  ****/
#main-content.page-mailbox { padding: 0 15px 0 0;}
.page-mailbox .col-md-4{ padding-right:  0;}
.page-mailbox .col-lg-8{ padding-left:  0;padding-right:  0}
.page-mailbox .panel{ margin-bottom:  0}
#messages-list, #message-detail {overflow: hidden}
.message-action-btn i {font-size: 20px;line-height: 40px;}
.icon-rounded { text-align: center; border-radius: 50%; width: 40px;height:40px;border:1px solid #BDBDBD;color:#bdbdbd;display: inline-block}
.icon-rounded:hover {border:1px solid #121212;color:#121212;cursor: pointer}
.icon-rounded.heart:hover{border:1px solid #D9534F;color:#D9534F}
.icon-rounded.heart-red  {border:1px solid #D9534F;color:#D9534F}
.icon-rounded.gear:hover{border:1px solid #3F97C9;color:#3F97C9}
.panel-body.messages { padding: 0;}
.border-bottom {border-bottom: 1px solid #EFEFEF !important;}
#main-content .messages a.btn {color:#B3B3B3;}
.message-item{border:none;border-bottom: 1px solid #ECEBEB !important; margin:0;padding:10px 15px;display: block}
.message-item h5{margin-top:0;}
.message-item p{margin-bottom:0;height: 20px;overflow: hidden;}
.message-item .message-checkbox{height:30px;}
.message-active {color:#fff;background-color:#00A2D9 }
a.message-item.message-active:hover{color:#fff;background-color:#008fc0;}
a.message-item:hover{text-decoration:none;background-color:#eaeaea;}
.messages .message-item img{border-radius: 3px;}
.message-item-right{padding-left:33px;}
.withScroll {height: auto;overflow: auto;overflow:hidden;}
.messages .mCustomScrollBox {overflow: hidden !important;}
.message-result .message-item{padding-left:0;}
.message-result .message-item-right{padding-left:0;}
.message-title{margin-top:0;}
.message-body{padding:0 20px 20px 20px;}
.message-attache .media {float:left; padding-right:30px;margin-top: 0;padding-bottom:30px;}
.message-attache {border-top: 1px solid #ECEBEB !important;padding-top:10px;}
.message-between {height: 8px;background-color: #E7E7E7;}
.footer-message { padding-top:20px;}
.send-message .form-control {height:34px;}
@media (min-width: 992px){
  .email-go-back {display: none;}
}
@media (max-width: 991px){
  .email-hidden-sm {display: none;}
  .email-go-back {display: inline-block;}
}

/****  Image Croping  ****/
.jcrop-holder #preview-pane {display: block; position: absolute; z-index: 20; top: 10px; right: 10px; padding: 6px; border: 1px rgba(0,0,0,.4) solid; background-color: white;-webkit-border-radius: 6px; -moz-border-radius: 6px; border-radius: 6px; -webkit-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);-moz-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2); box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);}
#style-button{display: block; width: 282px;margin:auto; margin-top:10px;padding: 6px; border: 1px rgba(0,0,0,.4) solid; background-color: white;-webkit-border-radius: 6px; -moz-border-radius: 6px; border-radius: 6px; -webkit-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);-moz-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2); box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);}
#preview-pane .preview-container { width: 250px; height: 170px; overflow: hidden;}
.image-croping img{ display: block; height: auto; max-width: 100%;}

/****  Media Manager Page  ****/
.media-manager .panel-body {padding:10px;}
.media-header {list-style: none;margin: 15px;padding: 0;margin-bottom: 20px;background: #Fff;-moz-border-radius: 3px;-webkit-border-radius: 3px;border-radius: 3px;
-webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);}
.media-header li {display: inline-block;padding: 0;font-size: 12px;color: #666;}
.media-header li a {padding: 15px 15px 15px 20px;display: inline-block;border-right: 1px solid #EEE;margin-right: -5px;}
.media-header li:hover a {background-color:#008fc0;color:#fff;cursor:pointer;}
.media-header li:hover a{text-decoration: none;color:#fff;}
.media-header a.active {background-color:#00A2D9;color:#fff !important;text-decoration: none}
.media-header .pull-right {background-color:#00A2D9;color:#fff;}
.media-header .pull-right:hover {background-color:#008fc0;}
.media-header .pull-right a{color:#fff;}
.media-header li:hover a.active, .media-header a.active:hover{color: #fff !important}
.media-checkbox {position: relative;}
.media-checkbox label {padding-left:26px;margin-bottom: 0;}
.media-type {color: #121212;}
.media-type a{color: #121212;padding-right:10px;}
.media-title a{ color:#00A2D9;font-weight: 600;}
.media-manager .thmb.checked { border-color: #ccc;}
.media-manager .media-group { position: absolute; top: 14px;  right: 14px;  display: none;}
.col-xs-6:hover .media-group { display: block;}
.media-manager .dropdown-toggle { border: 1px solid #CCC !important;padding: 0 4px; line-height: normal; background: #fff; -moz-border-radius: 2px; -webkit-border-radius: 2px;  border-radius: 2px;}
.media-manager .media-menu { min-width: 128px;}
.media-manager .media-menu a { color: #333;}
.media-manager .media-menu i { margin-right: 5px; color: #999; width: 15px; font-size: 12px;}
.media-manager .media-menu li{ margin: 0 5px; border-radius:3px;}
.media-manager .media-menu li:hover{ background-color:#DFE5E9 !important}
.media-manager .media-menu li:hover i { color: #00A2D9;}
.media-manager .col-xs-6 { padding-right:0;}
.media-manager .glyphicon { margin-right:10px; }
.media-manager .panel-body { padding:10px; }
.media-manager .panel-body table tr td { padding-left: 15px }
.media-manager .panel-body .table {margin-bottom: 0px; }
#main-content .media-manager .table a {color: #121212;}
.media-manager .panel-heading .panel-title > a:after {display:none;}
.media-manager .panel-heading .panel-title > a.collapsed:after {display:none;}
.media-menu  .panel-body { padding:0; }
.media-menu .panel-heading:hover{ cursor:pointer;}
.media-menu .panel-heading:hover .collapsed{ background-color: #F5F6F7 !important;}
.media-menu.dropdown-menu > li > a {padding: 3px 20px 3px 10px;}
.media-menu table td:hover{background-color: #f1f1f1;}
.media-menu table a:hover{text-decoration: none;}

/****  Gallery Page  ****/
.gallery {padding-top: 20px}
.gallery .mix{position:relative;display:none;font-weight:400;color:#fff;height: auto;overflow: visible}
.gallery .mix .thumbnail{background: url('../img/gradient-big.png') repeat-x;}
.gallery.list .mix{width:100%;height: 160px}
.thumbnail {position: relative;padding: 0px;border-width: 0px;border-radius: 3px;margin-bottom: 30px;}
.thumbnail>.overlay {position: absolute;z-index: 4;border-radius: 3px;top: 0px;bottom: 0px;left: 0px;right: 0px;background-color: rgba(0, 0, 0, 0.4);opacity: 0;-webkit-transition: opacity 0.3s ease;-moz-transition: opacity 0.3s ease;-o-transition: opacity 0.3s ease;transition: opacity 0.3s ease;}
.thumbnail:hover>.overlay {opacity: 1;}
.thumbnail>.overlay>.thumbnail-actions {position: absolute;top: 50%;margin-top: -20px;width: 100%;text-align: center;}
.thumbnail>.overlay>.thumbnail-actions i{margin-left: -2px;margin-right: 2px}
.thumbnail>.overlay>.thumbnail-actions .btn{-webkit-transition: all .2s ease-in !important;-moz-transition: all .2s ease-in !important;-o-transition: all .2s ease-in !important;transition: all .2s ease-in !important;-webkit-transform: scale(0);-moz-transform: scale(0);-o-transform: scale(0);-ms-transform: scale(0);transform: scale(0);}
.thumbnail:hover>.overlay>.thumbnail-actions .btn{-webkit-transform: scale(1);-moz-transform: scale(1);-o-transform: scale(1) ;-ms-transform: scale(1);transform: scale(1);}
.thumbnail .thumbnail-meta h5{margin: 0;}
.thumbnail .thumbnail-meta small{color: #CFCFCF;}
.thumbnail .thumbnail-meta{position: absolute;z-index: 6;top: auto;bottom:0;left: 0px;right: 0px;color: #FFF;padding: 10px;background:url('../img/gradient-big.png') repeat-x bottom; }
.thumbnail{background:#C7D6E9;}
.mfp-fade.mfp-bg {opacity: 0;-webkit-transition: all 0.2s ease-out;-moz-transition: all 0.2s ease-out;transition: all 0.2s ease-out;}
.mfp-fade.mfp-bg.mfp-ready {opacity: 0.8;}
.mfp-fade.mfp-bg.mfp-removing {opacity: 0;}
.mfp-fade.mfp-wrap .mfp-content {opacity: 0;-webkit-transition: all 0.2s ease-out;-moz-transition: all 0.52s ease-out;transition: all 0.2s ease-out;}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {opacity: 1;}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {opacity: 0;}

/****  FAQ Page  ****/
#faq {padding-left: 30px;padding-right: 30px}
.faq .mix{position:relative;margin-bottom:10px;display:none;}
.faq.gallery .mix {color: #121212;}
.faq.list .mix{width:100%;height: 160px}
.faq .mix.panel{border:none;box-shadow: none;padding:0;width: 100%;}

/****  Blog Page  ****/
.categories-list li{padding:8px 0 8px 0;}
.categories-list a{color:#C75757;}
.categories-list li i{padding-right:10px; color:#C75757;}
.blog-sidebar {font-weight:600 !important;}
.blog-sidebar h4{font-weight:500 !important;}
.blog-results a:hover, .blog-result a:hover{color:#C75757 !important;text-decoration: none}
.blog-result h1 {color:#C75757;}

/****  Timeline Page  ****/
.timeline {list-style: none;padding: 20px 0 20px;position: relative;}
.timeline-options  {font-size:22px;border-bottom:2px solid #fff;margin-bottom:15px;padding-top:30px;font-weight:600;margin-left: 5px;margin-right: 5px;}
.timeline-options .col-md-3 {padding-bottom: 10px}
.timeline-options .btn {font-size:15px;}
.timeline-btn-day {text-align: center}
.timeline:before {top: 0;bottom: 0;position: absolute;content: " ";width: 7px;left: 50%;margin-left: -3.5px;background: #00a2d9;background: -moz-linear-gradient(top, #00a2d9 0%, #dddddd 55%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#00a2d9), color-stop(55%,#dddddd));background: -webkit-linear-gradient(top, #00a2d9 0%,#dddddd 55%);background: -o-linear-gradient(top, #00a2d9 0%,#dddddd 55%);background: -ms-linear-gradient(top, #00a2d9 0%,#dddddd 55%);background: linear-gradient(to bottom, #00a2d9 0%,#dddddd 55%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a2d9', endColorstr='#dddddd',GradientType=0 );}
.timeline > li {margin-bottom: 20px;position: relative;}
.timeline-heading .pull-left{width:50%;}
.timeline-heading .pull-right{font-size:18px;display:none;}
.timeline-panel:hover .timeline-heading .pull-right{display:block;}
.timeline-heading{height:75px;}
.timeline-heading .pull-right a:hover{color:#00a2d9;}
.timeline-heading .pull-right i{padding-left:12px;}
.timeline > li:before,.timeline > li:afttimeline-paneler {content: " ";display: table;}
.timeline > li:after {clear: both;}
.timeline > li:before,.timeline > li:after {content: " ";display: table;}
.timeline > li:after {clear: both;}
.timeline > li > .timeline-panel {background: #FFF;width: 46%;float: left;border: 1px solid #d4d4d4;border-radius: 2px;padding: 0;position: relative;-webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);}
.timeline > li > .timeline-panel .timeline-heading {padding:20px 20px 15px 20px;}
.timeline > li > .timeline-panel .timeline-body {padding:20px;}
.timeline > li > .timeline-panel:before {position: absolute;top: 26px;right: -15px;display: inline-block;border-top: 15px solid transparent;border-left: 15px solid #ccc;border-right: 0 solid #ccc;border-bottom: 15px solid transparent;content: " ";}
.timeline > li > .timeline-panel:after {position: absolute;top: 27px;right: -14px;display: inline-block;border-top: 14px solid transparent;border-left: 14px solid #fff;border-right: 0 solid #fff;border-bottom: 14px solid transparent;content: " ";}
.timeline > li > .timeline-badge {color: #fff;width: 26px;height: 26px;line-height: 26px;font-size: 1.4em;text-align: center;position: absolute;top: 16px;left: 50%;margin-left: -13px;border:4px solid #DFE5E9;z-index: 20;border-top-right-radius: 50%;border-top-left-radius: 50%;border-bottom-right-radius: 50%;border-bottom-left-radius: 50%;}
.timeline > li.timeline-inverted > .timeline-panel {float: right;}
.timeline-info {float: left !important;width:48%;padding-top: 16px;}
.timeline-info .fa{font-size:34px;}
.timeline-info-time {float:right;width:40px;}
.timeline-info-hour {font-size:12px; font-weight: 500}
.timeline-info-type{float:right; padding-right:10px;font-size:23px;font-weight:700;}
.timeline-info.inverted {float: right !important;width:48%;padding-top: 16px;}
.timeline-info.inverted .timeline-info-time {float:left;width:40px;}
.timeline-info.inverted .timeline-info-type{float:left; font-size:23px;font-weight:700;}
.timeline > li > .timeline-panel .timeline-body.media  {margin-top: 0;padding-top:10px;}
.timeline > li.timeline-inverted > .timeline-panel:before {border-left-width: 0;border-right-width: 15px;left: -15px;right: auto;}
.timeline > li.timeline-inverted > .timeline-panel:after {border-left-width: 0;border-right-width: 14px;left: -14px;right: auto;}
.timeline-badge.primary {background-color: #2e6da4 !important;}
.timeline-badge.success {background-color: #3f903f !important;}
.timeline-badge.warning {background-color: #f0ad4e !important;}
.timeline-badge.danger {background-color: #d9534f !important;}
.timeline-badge.info {background-color: #5bc0de !important;}
.timeline-title {margin-top: 0;color: inherit;}.timeline-body > p,.timeline-body > ul {margin-bottom: 0;}
.timeline-body > p + p {margin-top: 5px;}
.timeline-day{font-weight:700;font-size:1.1em;}
.timeline-day-number{float: left;height: 40px;font-size: 44px;font-weight: 700;padding-right: 8px;margin-top: -10px;font-family: arial;}
.timeline-month {font-weight:600;}
@media (max-width: 767px) {
  ul.timeline:before {left: 40px;}
  .timeline-btn-day {text-align: left;}
  ul.timeline > li > .timeline-panel {width: calc(100% - 90px);width: -moz-calc(100% - 90px);width: -webkit-calc(100% - 90px);}
  ul.timeline > li > .timeline-badge {left: 28px;margin-left: 0;top: 16px;}ul.timeline > li > .timeline-panel {float: right;}
  ul.timeline > li > .timeline-panel:before {border-left-width: 0;border-right-width: 15px;left: -15px;right: auto;}
  ul.timeline > li > .timeline-panel:after {border-left-width: 0;border-right-width: 14px;left: -14px;right: auto;}
  .timeline-info.inverted {float: left !important;padding-left:92px;width: 100%;}
  .timeline-info {float: left !important;padding-left:92px;width: 100%;}
  .timeline-info .float-right{float: left !important;}
}

/****  Profil Page  ****/
#main-content.page-profil {padding: 0 15px 0;background: #FFF;}
.page-profil .col-md-3 {padding-left: 0;padding-right:0;background-color: #fff; }
.page-profil .col-md-9 {background-color: #fff;}
.page-profil .profil-content{border-left:1px solid #EBEBEB;}
.profil-sidebar img {padding-right:0;}
.page-profil h4  {font-weight: 600; width:50%;}
.profil-sidebar h5 {font-size:14px;font-weight: 600;}
.profil-sidebar-element {padding-bottom:10px;padding-top:15px;border-bottom:1px solid #EBEBEB;}
.profil-sidebar-element:last-child {border-bottom:none;}
.profil-sidebar-element:first-child {padding-top: 0;}
.sidebar-number  {color: #00A2D9;font-size: 36px;font-weight: 500;margin-top: -16px;}
.profil-sidebar img.img-small-friend  {width: 100%;padding-right: 0;margin-bottom: 10px;}
.more-friends {width:100%;height:100%;}
.profil-sidebar .col-md-2 {padding-left:5px;padding-right: 5px}
.profil-sidebar .btn {text-align: left;padding-left: 10px}
.profil-sidebar .btn i{padding-right:8px}
.profil-sidebar .fa-ellipsis-h{position: absolute;top: 8px;left: 17px;color: #1892BB;font-size: 39px;}
.profil-sidebar #stars .fa {color: #1892BB;font-size: 18px;padding-right: 10px}
.page-profil .profil-review  .col-md-9 {padding-left:0;}
.page-profil .col-md-9 {padding-left:40px;}
.profil-presentation {font-size:18px;padding:0 15px 0 15px;}
.section-box h2 { margin-top:0px;}
.section-box h2 a { font-size:15px; }
.glyphicon-heart { color:#e74c3c;}
.profil-content a:hover  {color: #00A2D9 !important;}
.separator { padding-right:5px;padding-left:5px; }
.section-box hr {margin-top: 0;margin-bottom: 5px;border: 0;border-top: 1px solid #EBEBEB;}
.page-profil .stars {float:left;}
.page-profil .stars .fa,.page-profil .fa-comments-o {color: #1892BB;font-size: 15px;padding-right:5px}
.profil-groups {padding-bottom:10px;}
.profil-groups img {width:100%;margin-bottom: 4px;}
.profil-group {padding-left: 10px !important;}
.profil-groups .thumb{padding-left: 2px;padding-right: 2px;} 
.profil-review {margin-bottom: 20px}
.panel-image img.panel-image-preview { width: 100%; border-radius: 4px 4px 0px 0px;max-height:335px;overflow: hidden}
.panel-heading ~ .panel-image img.panel-image-preview { border-radius: 0px;}
.panel-image ~ .panel-footer a {padding: 0px 10px;font-size: 1.3em;color: rgb(100, 100, 100);}
.col-md-3.profil-sidebar .img-responsive {width: 100%;}

/****  Members Page  ****/
.member {border: 1px solid #EBEBEB;padding: 15px;margin-top: 15px;margin-bottom: 10px;-moz-box-shadow: 1px 1px 1px rgba(0,1,1,0.03);-webkit-box-shadow: 1px 1px 1px rgba(0, 1, 1, 0.03);box-shadow: 1px 1px 1px rgba(0, 1, 1, 0.03);-moz-transition: all 300ms ease-in-out;-o-transition: all 300ms ease-in-out;-webkit-transition: all 300ms ease-in-out;transition: all 300ms ease-in-out;-webkit-border-radius: 3px;-moz-border-radius: 3px;border-radius: 3px;margin-right: 10px}
.member:hover{background: rgba(235, 235, 235, 0.4);box-shadow: 1px 1px 1px rgba(0, 1, 1, 0.07);-moz-box-shadow: 1px 1px 1px rgba(0,1,1,0.06);-webkit-box-shadow: 1px 1px 1px rgba(0, 1, 1, 0.07);}
.member-entry img {max-height: 160px}
@media (max-width:1760px) {
 .col-md-4.member-entry {width: 50%}
}
@media (max-width:1300px) {
 .col-md-4.member-entry {width: 100%}
}
@media (max-width:650px) {
 .member-entry .pull-right{text-align: left !important;float:none !important}
 .member-entry .pull-left{float:none !important}
}

/****  Invoice Page  ****/
.invoice-page textarea { border: 0; overflow: hidden; resize: none;margin-bottom: -25px;background: rgba(0, 0, 0, 0);}
.invoice-page textarea:hover, textarea:focus, .invoice-page #items td.total-value textarea:hover, .invoice-page #items td.total-value textarea:focus, .invoice-page .delete:hover { background-color:#c8e0f8; }
..invoice-page td {overflow: hidden}
.invoice-page .delete-wpr { position: relative; }
.invoice-page .delete { display: block; color: #fff !important;border: none !important; text-decoration: none; position: absolute;background: #C75757 ;border-radius: 3px; font-weight: bold; padding: 0px 3px; border: 1px solid; top: 18px;left:0px;font-size: 16px; }
.invoice-page .delete:hover {background: #c14444  }
.invoice-page .delete i {padding:3px;}
#addrow {color:#428BCA !important;text-decoration: none;font-size: 16px;}
@media (max-width:700px) {
  .invoice-page .btn {width: 100%}
  .invoice-page .pull-right{text-align: left !important;float:none !important;width:100%;}
  .invoice-page .pull-left{float:none !important;width:100%;}
}


/****  Comments Page  ****/
.page-comment .comment {background: rgba(223, 229, 233, 0.2);border: 1px solid #EBEBEB;padding: 15px;padding-bottom:0 !important;margin-top: 15px;margin-bottom: 10px;-moz-box-shadow: 1px 1px 1px rgba(0,1,1,0.03);-webkit-box-shadow: 1px 1px 1px rgba(0, 1, 1, 0.03);box-shadow: 1px 1px 1px rgba(0, 1, 1, 0.03);-moz-transition: all 300ms ease-in-out;-o-transition: all 300ms ease-in-out;-webkit-transition: all 300ms ease-in-out;transition: all 300ms ease-in-out;-webkit-border-radius: 3px;-moz-border-radius: 3px;border-radius: 3px;margin-right: 10px}
.page-comment .comment:hover{background: rgba(223, 229, 233, 0.5);box-shadow: 1px 1px 1px rgba(0, 1, 1, 0.07);-moz-box-shadow: 1px 1px 1px rgba(0,1,1,0.06);-webkit-box-shadow: 1px 1px 1px rgba(0, 1, 1, 0.07);}
.page-comment .comment.selected {background: rgba(223, 229, 233, 0.3);border: 1px solid #c5c5c5;}
.page-comment .comments-list {padding-top: 0}
.page-comment .comments-list .comment-checkbox { width:3%;float:left;position: relative;margin-top: 2px;}
.page-comment .comments-list .comment-content { width:97%;float:right;}
.page-comment .comments-list .comment-content .comment-header{ margin-bottom: 10px}
.page-comment .comments-list .comment-content .comment-text{ padding-bottom: 10px;}
.page-comment .comment-footer {display: none;border-top: 1px solid #eaeaea;padding-bottom: 20px;padding-top: 10px;}
@media (max-width:1200px) {
  .comments-list .comment-checkbox { width:5%;}
  .comments-list .comment-content { width:95%;}
  .page-comment .modal-dialog {width: 75% !important;margin:auto;margin-top: 20px}
}
@media (max-width:769px) {
  .comments-list .comment-checkbox { width:10%;}
  .comments-list .comment-content { width:90%;}
  .page-comment .btn-white, .page-comment .btn-primary, .page-comment .btn-dark {width: 100%}
}

/****  Pricing Table Page  ****/
.pricing-tables h3 {margin: 0 0 8px;font-size: 35px;text-align: center;letter-spacing: -1px;}
.pricing-tables .lead {font-size: 25px;font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;text-align: center;margin-bottom: 0px;}

/* Pricing Table 1 */
.pricing-1 {padding-bottom:40px;padding-top:40px;padding-left: 10%;padding-right: 10%}
.pricing-1 .plans {border:3px solid #ebedee;}
.pricing-1 .plan{width:33.33%;float:left;position:relative;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;text-align:center;color:#bdc3c7;font-size:18px;font-weight:400;padding:0; -webkit-transition: all .2s linear;-moz-transition: all .2s linear;-o-transition: .2s linear;transition: all .2s linear;}
.pricing-1 .plan:hover {color: #383838;}
.pricing-1 .plan-left .plan-header {border-right:3px solid #4A4E55;}
.pricing-1 .plan-left .description {border-right:3px solid #EBEDEE;}
.pricing-1 .plan-right .plan-header {border-left:3px solid #4A4E55;}
.pricing-1 .plan-right .description {border-left:3px solid #EBEDEE;}
.pricing-1 .plan b{color:#7f8c8d;}
.pricing-1 .plan .plan-header {padding:25px;background:#34383F; color: #fff}
.pricing-1 .plan .title{font-family: 'Carrois Gothic';color:#fff;font-size:22px;font-weight:500;margin-bottom:15px; text-transform: uppercase;}
.pricing-1 .plan .price {margin-bottom:10px;color: #CACACA;}
.pricing-1 .plan .price .amount{font-size: 4em;margin-top: -22px;display: inline-block;}
.pricing-1 .offer {padding:8px 25px; font-size:0.8em;border:2px solid #505661; border-radius:4px; font-weight:500; text-transform: uppercase;-webkit-transition: all .2s linear;-moz-transition: all .2s linear;-o-transition: .2s linear;transition: all .2s linear;cursor:pointer;}
.pricing-1 .offer:hover {border:2px solid #505661; color:#34383F; background: #eaeaea;border: 2px solid #eaeaea;}
.pricing-1 .plan .description{text-align:left;padding-top:10px;border-top:2px solid #ebedee;line-height:28px;font-weight:400;margin:0}
.pricing-1 .plan .description div{padding-left:10%;padding-right: 10%}
.pricing-1 .plan .plan-item{border-bottom:1px solid #eaeaea;padding-top:10px;padding-bottom: 15px;font-size: 1em}
.pricing-1 .plan .plan-item .glyph-icon {width: 60px}
.pricing-1 .plan .description b{font-weight:600}
.pricing-1 .plan .btn{min-width:170px}
@media (max-width:1300px) {
  .pricing-1 .offer {font-size: 0.6em;padding: 8px 10px;}
  .pricing-1 .plan .price .amount {font-size: 2em}
  .pricing-1 .plan .plan-item {font-size: 0.8em}
}
@media (max-width:1000px) {
  .pricing-1 .plan {width: 100%;}
  .pricing-1 .plan-left .description, .pricing-1 .plan-right .description {border-left: none; border-right: none}
}
/* Pricing Table 2 */
.pricing-2 {padding-bottom:40px;padding-top:10px;padding-left: 80px;padding-right: 80px;}
.pricing-2 .plan{position:relative;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;border:3px solid #ebedee;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;text-align:center;color:#bdc3c7;font-size:18px;font-weight:400;padding:25px 0; -webkit-transition: all .2s linear;-moz-transition: all .2s linear;-o-transition: .2s linear;transition: all .2s linear;}
.pricing-2 .plan:hover {border:3px solid #159077;color:#383838;}
.pricing-2 .plan b{color:#7f8c8d}
.pricing-2 .plan .title{color:#2c3e50;font-size:24px;font-weight:500;margin-bottom:8px}
.pricing-2 .plan .description{padding-top:22px;border-top:2px solid #ebedee;line-height:28px;font-weight:400;margin:26px 0}
.pricing-2 .plan .description b{font-weight:600}
.pricing-2 .plan .description .plan-item{padding-bottom: 10px}
.pricing-2 .plan .btn{min-width:170px}
@media (max-width:1300px) {
  .pricing-2 .offer {font-size: 0.6em;padding: 8px 10px;}
  .pricing-2 .plan .price .amount {font-size: 2em}
  .pricing-2 .plan .plan-item {font-size: 0.8em}
}
@media (max-width:1000px) {
  .pricing-2 .col-sm-4 {width: 100%;margin-bottom: 20px}
}

/* Pricing Table 3 */
.pricing-3 {padding-bottom:40px;padding-top:10px;padding-left: 15%;padding-right: 15%}
.pricing-3 .plan{width:33.33%;float:left;position:relative;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;text-align:center;color:#bdc3c7;font-size:18px;font-weight:400;padding:0; -webkit-transition: all .2s linear;-moz-transition: all .2s linear;-o-transition: .2s linear;transition: all .2s linear;}
.pricing-3 .plan:hover {color: #383838;}
.pricing-3 .plan-right {border-left:3px solid #EBEDEE;}
.pricing-3 .plan-left {border-right:3px solid #EBEDEE;}
.pricing-3 .plan img { max-width: 30%;margin:auto;margin-bottom: 20px;opacity: 0.6;-webkit-transition: all .2s linear;-moz-transition: all .2s linear;-o-transition: .2s linear;transition: all .2s linear;}
.pricing-3 .plan:hover img { opacity: 1}
.pricing-3 .plan b{color:#7f8c8d;}
.pricing-3 .plan .plan-header {padding:25px 25px 0 25px; color: #383838}
.pricing-3 .plan .title{color:#383838;font-size:22px;font-weight:600;margin-bottom:5px;}
.pricing-3 .plan .price {color: #AFB4B9;}
.pricing-3 .plan .price .amount{font-size: 30px;margin-top: -22px;display: inline-block;}
.pricing-3 .plan .description{text-align:left;padding-top:10px;line-height:28px;font-weight:400;margin:0}
.pricing-3 .plan .description div{padding-left:10%;padding-right: 10%}
.pricing-3 .plan .plan-item{text-align: center;margin-bottom: 3px}
.pricing-3 .plan .description b{font-weight:600}
.pricing-3 .plan .btn{min-width:170px;margin-top: 20px; font-weight: 600}
.pricing-3 .plan .btn.disabled {background-color: #d9dcde;color:#949da4;}
@media (max-width:1360px) {
  .pricing-3 {padding-left: 2%;padding-right: 2%}
  .pricing-3 .offer {font-size: 0.6em;padding: 8px 10px;}
  .pricing-3 .plan .price .amount {font-size: 2em}
  .pricing-3 .plan .plan-item {font-size: 0.8em}
}
@media (max-width:1000px) {
  .pricing-3 .plan {width: 100%;}
  .pricing-3 .plan, .pricing-3 .plan-left, .pricing-3 .plan-right {border-left: none; border-right: none;border-bottom: 3px solid #EBEDEE;padding-bottom: 20px}
}

/****  Settings Page  ****/
#settings table td {position: relative;}
#settings table th {font-weight: normal;text-transform: none}
#settings table td .control-label div{margin-top: 18px;}
#settings table td .control-label  {padding-left: 30px;padding-top: 5px}
.profile-classic .row {line-height:25px;margin-bottom: 15px;}
.profile img {max-height: 180px;margin-top: 15px;margin-left: 20px}

/****  Dashboard  ****/
.dashboard .panel-stat .glyph-icon {opacity: 0.5; font-size: 55px}
.dashboard .panel-stat .glyph-icon.flaticon-educational, .dashboard .panel-stat .glyph-icon.flaticon-incomes{ font-size: 65px}
.dashboard .panel-stat .col-xs-6:last-child {text-align: right}
@media (max-width:1500px) {
  .dashboard .fc-header-right {display:block;}
  .dashboard .fc-header-center {text-align: center; padding-right: 40px}
  .dashboard .panel-stat .glyph-icon {font-size: 42px}
  .dashboard .panel-stat .glyph-icon.flaticon-educational, .dashboard .panel-stat .glyph-icon.flaticon-incomes{ font-size: 52px}
}
@media (max-width:1250px) {
  .dashboard .panel-stat .glyph-icon {font-size: 30px}
  .dashboard .panel-stat .glyph-icon.flaticon-educational, .dashboard .panel-stat .glyph-icon.flaticon-incomes{ font-size: 38px}
}
@media (max-width:992px) {
  .dashboard .panel-stat .glyph-icon {font-size: 50px}
  .dashboard .panel-stat .glyph-icon.flaticon-educational, .dashboard .panel-stat .glyph-icon.flaticon-incomes{ font-size: 60px}
  .dashboard .panel-stat .col-xs-9 {text-align: right}
}


/****  404 Error Page  ****/
.error-page {background: rgb(223,229,233);background: -moz-radial-gradient(center, ellipse cover,  rgba(223,229,233,1) 2%, rgba(223,229,233,1) 2%, rgba(178,192,202,1) 100%);background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(2%,rgba(223,229,233,1)), color-stop(2%,rgba(223,229,233,1)), color-stop(100%,rgba(178,192,202,1)));background: -webkit-radial-gradient(center, ellipse cover,  rgba(223,229,233,1) 2%,rgba(223,229,233,1) 2%,rgba(178,192,202,1) 100%);background: -o-radial-gradient(center, ellipse cover,  rgba(223,229,233,1) 2%,rgba(223,229,233,1) 2%,rgba(178,192,202,1) 100%);background: -ms-radial-gradient(center, ellipse cover,  rgba(223,229,233,1) 2%,rgba(223,229,233,1) 2%,rgba(178,192,202,1) 100%);background: radial-gradient(ellipse at center,  rgba(223,229,233,1) 2%,rgba(223,229,233,1) 2%,rgba(178,192,202,1) 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dfe5e9', endColorstr='#b2c0ca',GradientType=1 );padding: 0;overflow: hidden;}
.error-main{text-align: center;margin-top:20%;color: #2B2E33}
.error-page h1{font-size:120px;text-align:center;font-weight:600;}
.error-page a {color: #2B2E33;}
.error-page a:hover {text-decoration: none;color:#616873;}
.error-page .footer{position: absolute;bottom: 30px;width: 100%;}
.error-page .copyright{font-size: 12px;text-align: center;}
.error-page .btn {padding: 8px 50px;}

/****  Login Page, Sign Up Page, Password Recover Page, Lockscreen Page  ****/
body.login{background: rgb(223,229,233);background: -moz-radial-gradient(center, ellipse cover,  rgba(223,229,233,1) 2%, rgba(223,229,233,1) 2%, rgba(178,192,202,1) 100%);background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(2%,rgba(223,229,233,1)), color-stop(2%,rgba(223,229,233,1)), color-stop(100%,rgba(178,192,202,1)));background: -webkit-radial-gradient(center, ellipse cover,  rgba(223,229,233,1) 2%,rgba(223,229,233,1) 2%,rgba(178,192,202,1) 100%);background: -o-radial-gradient(center, ellipse cover,  rgba(223,229,233,1) 2%,rgba(223,229,233,1) 2%,rgba(178,192,202,1) 100%);background: -ms-radial-gradient(center, ellipse cover,  rgba(223,229,233,1) 2%,rgba(223,229,233,1) 2%,rgba(178,192,202,1) 100%);background: radial-gradient(ellipse at center,  rgba(223,229,233,1) 2%,rgba(223,229,233,1) 2%,rgba(178,192,202,1) 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dfe5e9', endColorstr='#b2c0ca',GradientType=1 );color:#FFF}
.alert{width:100%;margin:20px auto; box-shadow:2px 4px 7px -9px #000}
.login h2, .login h3 {font-family: 'Open Sans';}
#login-block{padding-top:50px;padding-bottom:25px}
#login-block h3{color:#FFF;text-align:center;font-size:1.5em;opacity:0.8;text-shadow:2px 2px 2px #000;font-weight:400}
.login-box{position:relative;max-width:480px;background:#222D3A;-webkit-border-radius:3px;-moz-border-radius:3px;border-radius:3px;padding-bottom:20px;margin-top:80px}
.login-logo{text-align:center;padding:15px 0 10px}
.login-logo img{border:0;display:inline-block}
.login-form form p{width:80%;text-align:center;margin:5px auto 10px}
.login-box hr{width:70%;border-top:1px solid rgba(0,0,0,0.13);border-bottom:1px solid rgba(255,255,255,0.15);margin:10px auto 20px}
.page-icon{width:100px;height:100px;-webkit-border-radius:100px;-moz-border-radius:100px;border-radius:100px;background:#12181f;text-align:center;margin:-60px auto 0}
.page-icon img{vertical-align:middle;-moz-transition:all .5s ease-in-out;-webkit-transition:all .5s ease-in-out;-o-transition:all .5s ease-in-out;-ms-transition:all .5s ease-in-out;transition:all .5s ease-in-out;opacity:0.6;width:48px;height:48px;margin:25px 0 0}
.rotate-icon{-moz-transform:rotate(45deg);-webkit-transform:rotate(45deg);-o-transform:rotate(45deg);-ms-transform:rotate(45deg);transform:rotate(45deg)}
.login-box .page-icon{-webkit-animation-delay:.8s;-moz-animation-delay:.8s;-o-animation-delay:.8s;animation-delay:.8s}
.login-form input, .login-form select{display:block;width:70%;background:#fefefe;border:0;color:#6c6c6c;border-radius:2px;margin:0 auto 15px;padding:8px}
#submit-form {margin: 20px auto;display: block}
.login-form .ui-checkbox {display:block;width:70%;margin:0 auto 15px;}
.login-form .ui-checkbox input {width:22px;margin-left: -7px;}
.multiple-select{width:70%;margin:0 auto 15px}
.login-form select.select-third{width:31%;margin-right:3.5%;box-sizing: border-box;float:left;}
.login-form select.select-third:last-child{margin-right:0;}
.login-form .input-icon {width: 70%;margin-left: 15%;}
.btn.btn-login{padding: 7px 30px;display:block;border: 1px solid rgba(0, 0, 0, 0);color:#FFF;text-transform:uppercase;background:#12181f;-webkit-transition:all .5s ease-in-out;-moz-transition:all .5s ease-in-out;-o-transition:all .5s ease-in-out;transition:all .5s ease-in-out;margin:20px auto}
.btn.btn-login:hover{border:1px solid #FFF;opacity:0.8}
.agreement {margin-left:80px;}
.btn.btn-reset{width:180px}
.login-links{text-align:center}
.login-links a{color:#FFF;display:inline-block;-webkit-transition:all .5s ease;-moz-transition:all .5s ease;-ms-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;opacity:0.7;padding:5px}
.login-links a:hover{opacity:1;text-decoration:none}
label.checkbox{width:70%;display:block;margin:0 auto}
label.checkbox input{width:14px;background:none;border:0;margin:4px 0 0;padding:0}
#footer-text,#footer-text a{text-align:center;color:#999;opacity:1}
.social-login{margin:10px 0 5px}
.social-login .btn{text-align:center;color:#FFF;text-shadow:1px 1px 1px #333;width:95%;margin:5px auto;padding:5px;}
.btn.btn-facebook{background:#385496;background-size:100%;font-size:12px}
.btn.btn-facebook:hover{background:#4365B4}
.btn.btn-twitter{background:#4A9EFF;font-size:12px}
.btn.btn-twitter:hover{background:#73B4FF}
.fb-login,.twit-login{-webkit-animation-delay:.9s;-moz-animation-delay:.9s;-o-animation-delay:.9s;animation-delay:.9s;padding:5px}
@media only screen and (max-width: 479px) {  
  #login-block{ padding-top: 10px; padding-bottom: 25px;  }
}
@media only screen and (min-width: 480px) and (max-width: 767px){
  #login-block {margin: 0 auto;   width: 420px;  } 
}
.lockscreen {text-align: center}
.lockscreen .page-icon img {width: 100px;height: 100px;margin: 0;border-radius: 50%;opacity: 1}
.lockscreen i {margin-top: 20px;opacity: 0.5;font-size:35px}
.lockscreen h3 {margin-top: 5px}
.login .form-input {width: 70%;margin-left: 15%}
body.login.lockscreen{background:#aab5a4;color:#FFF}

/****  Coming Soon / Underconstruction Page  ****/
#coming-block {overflow-x: hidden;}
#coming-block .countdown-container h1 {margin-bottom: 50px;margin-top: 20px}
#coming-block h1{font-family: 'Open Sans', verdana, arial;font-size: 46px;}
#coming-block .social a {color:#121212;width: 35px;height: 35px;display: inline-block;box-shadow: 0 0 0 2px #FFF;border-radius: 50%;line-height: 35px;margin: 0 10px 10px 10px;font-size: 1em;}
@media only screen and (min-width: 769px){
  #coming-block .social a {width: 50px;height: 50px;line-height: 50px;font-size: 1.5em;color:#121212; }
}
#coming-block .social a:hover {color: #5691BB;background: #fff;}
#coming-block .coming-form {margin-bottom: 90px;margin-top: 60px}
#coming-block .coming-form input {display: block;width:350px;background: #FEFEFE;border: 0;color: #6C6C6C;border-radius: 2px;margin: 0 auto 15px;padding: 8px;}
#coming-block .btn.btn-coming {width:350px; display: block;border: 1px solid rgba(0, 0, 0, 0);color: #FFF;text-transform: uppercase;background: #12181F;-webkit-transition: all .5s ease-in-out;-moz-transition: all .5s ease-in-out;-o-transition: all .5s ease-in-out;transition: all .5s ease-in-out;margin: 10px auto 40px;}
#coming-block .btn.btn-coming:hover {border: 1px solid #FFF;opacity: .8;}
#coming-block .copyright {margin-top: 30px}
#coming-block .countdown-container {margin-top: 50px;margin-bottom: 30px}
#coming-block .clock-item .inner {height: 0px;padding-bottom: 100%;position: relative; width: 100%;}
#coming-block .clock-canvas {background-color: rgba(255, 255, 255, .1);border-radius: 50%;height: 0px;padding-bottom: 100%;}
#coming-block .text {color: #fff;  font-size: 30px;font-weight: bold; margin-top: -50px;position: absolute;top: 50%;text-align: center;text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);width: 100%;}
#coming-block .text .val {font-size: 50px;}
#coming-block .text .type-time {font-size: 20px;}
@media (max-width: 1300px) {
  #coming-block .countdown-container {margin:50px 50px 20px 50px}
  #coming-block .text .val {font-size: 30px;}
  #coming-block .text .type-time {font-size: 16px;}
}
@media (min-width: 768px) and (max-width: 991px) {
  #coming-block .clock-item {margin-bottom: 30px; }
}
@media (max-width: 769px) {
  #coming-block .clock-item {margin: 0px 30px 30px 30px;}
  #coming-block .text .val {font-size: 40px;}
  #coming-block .text .type-time {font-size: 20px;}  
  #coming-block .countdown-container {margin: 0 30px 20px 30px;}
}

/****  Notes Page  ****/
#main-content.page-notes { padding: 0 15px 0 0;}
.page-notes .col-lg-3, .page-notes .col-md-4 { padding-right:  0;}
.page-notes .col-lg-9, .page-notes .col-md-8{ padding-left:  0;padding-right:  0}
.page-notes .panel{ margin-bottom:0;border-radius: 0}
.page-notes small {color:rgba(255, 255, 255, 0.45);font-weight: 500;}
#notes-list {background-color: #FFBBBB;border:none !important;}
#notes-list, #note-detail {overflow: hidden; }
#notes-list .note-desc { color:#CFCFCF;margin-bottom: 10px}
#notes-list a {background: #C75757; color:#fff;}
#add-note {margin-right: 10px;float: none;padding:10px 30px;background: #852E2E;font-weight:500;color:#eaeaea;width: 100%;display: block}
#add-note:hover {background: #722727}
#add-note i {padding-right: 15px}
#add-note:hover {cursor:pointer;}
.panel-body.notes { padding: 0;}
.border-bottom {border-bottom: 1px solid #EFEFEF !important;}
.note-item{border:none;border-bottom: 2px solid #944C4C !important; margin:0;padding:10px 15px;display: block}
.note-item h5{margin-top:0;}
.note-item p{margin-bottom:0;height: 20px;overflow: hidden;}
.note-active {color:#fff;background-color:#00A2D9 }
a.note-item.note-active:hover{color:#fff;background-color:#008fc0;}
a.note-item:hover{text-decoration:none;background-color:#eaeaea;}
.notes h3 {margin-top: 5px}
.notes h3 small{color:#B96F6F;}
.notes .input-group {background: #FFF;}
.note-item-right{padding-left:33px;}
.note-write {padding:0;}
.withScroll {height: auto;overflow: auto;overflow:hidden;}
.notes .mCustomScrollBox {overflow: hidden !important;}
.note-title{margin-top:0;}
.note-body{padding:0 20px 20px 20px;}
.note-write {position: relative;background: -webkit-linear-gradient(top, #F0F0F0 0%, #FFF 5%) 0 0; background: -moz-linear-gradient(top, #f0f0f0 0%, white 5%) 0 0;background: linear-gradient(top, #f0f0f0 0%, white 5%) 0 0; -webkit-background-size: 100% 40px;-moz-background-size: 100% 40px;-ms-background-size: 100% 40px;background-size: 100% 40px;}
.note-write:before{content:'';position:absolute;width:0;top:0;left:39px;bottom:0;border-left:1px solid rgba(0, 0, 0, 0.3)}
.note-write textarea {font-size:18px;border: none;background-color: rgba(0, 0, 0, 0) !important;height: 100%;line-height:40px;min-height: 300px;padding: 0 0 0 20px;}
@media (min-width: 992px){
  .note-go-back {display: none;}
}
@media (max-width: 769px){
  #notes-list {background-color: #fff;}
}
@media (max-width: 991px){
  .panel-body.notes {overflow-x:hidden;}
  .note-hidden-sm {display: none;}
  .note-hidden-sm {display: none;}
  .note-go-back {display: inline-block;margin-left: 15px}
  .note-go-back i{font-size: 20px;padding-top: 9px;}
  .note-go-back.icon-rounded {border: 1px solid #7A7A7A;color: #7A7A7A;}
}

/****  Forum Page  ****/
.forum-category, .forum-filter, .forum-questions {border: none}
.forum-category .panel-body{padding:0;}
.forum-category .panel-body li{padding:10px;border-bottom: 1px solid #eaeaea;font-weight: 500}
.forum-category .panel-body li:hover{background: #eaeaea; cursor: pointer}
.forum-category .panel-body li:last-child{border-bottom: none}
.forum-filter .panel-footer a {display:block;width:100%;padding:10px 25px; text-align: center;background-color: #428BCA;color:#fff;}
.forum-filter .panel-footer a:hover {background-color: #357ebd;text-decoration: none}
.forum-questions {display:none;}
.forum-questions .message-item {padding: 20px 30px;}
.forum-questions .message-item:hover {background: #efefef; cursor: pointer}
.forum-questions .message-item h4 {margin-bottom: 5px;}
.forum-questions .message-item .label {font-size: 11px}
.forum-questions .message-item p {text-overflow:ellipsis;white-space:nowrap;}
.forum-answer {display:none;}
.forum-answer .answer-info {margin-top: 12px}
.forum-answer p {margin-bottom: 10px;height: auto}
.forum-answer .badge{ padding: 5px 10px;}

/****  eCommerce Dashboard  ****/
.ecommerce_dashboard .panel-stat .glyph-icon {opacity: 0.5; font-size: 55px}
.ecommerce_dashboard .panel-stat .glyph-icon.flaticon-educational, .ecommerce_dashboard .panel-stat .glyph-icon.flaticon-incomes{ font-size: 65px}
.ecommerce_dashboard .panel-stat .col-xs-6:last-child {text-align: right}
@media (max-width:1500px) {
  .ecommerce_dashboard .fc-header-right {display:none;}
  .ecommerce_dashboard .fc-header-center {text-align: right; padding-right: 40px}
  .ecommerce_dashboard .panel-stat .glyph-icon {font-size: 42px}
  .ecommerce_dashboard .panel-stat .glyph-icon.flaticon-educational, .ecommerce_dashboard .panel-stat .glyph-icon.flaticon-incomes{ font-size: 52px}
}

@media (max-width:992px) {
  .ecommerce_dashboard .panel-stat .glyph-icon {font-size: 50px}
  .ecommerce_dashboard .panel-stat .glyph-icon.flaticon-educational, .ecommerce_dashboard .panel-stat .glyph-icon.flaticon-incomes{ font-size: 60px}
}

/****  Posts  ****/
.posts .top-page {padding-bottom: 10px;margin-bottom: 10px}
.posts .top-page .pull-right{margin-top: 8px}
.posts .top-menu{border-bottom: 1px solid #FFF;margin-top: 10px;margin-bottom: 10px;padding-bottom: 10px;}
.posts table {margin-top: 15px;background: #fff;-moz-border-radius: 3px;-webkit-border-radius: 3px;border-radius: 3px;overflow: hidden;}
.posts thead {background:#2B2E33; color:#eaeaea; }
.posts thead .ui-checkbox input {margin: -20px 0 0 0;}
.posts tbody .ui-checkbox input {margin: -15px 0 0 0;}
.posts .table>tbody>tr>td {vertical-align: middle;}
.posts #main-content .table a {margin-bottom: auto;}
.posts table.dataTable thead .sorting_asc:before, .posts table.dataTable thead .sorting_desc:before {color: #0090D9;}
.posts .table th {text-transform: none;font-weight: normal;}
.posts .label-default:hover {cursor: pointer;background-color: #868585; }
.posts a.edit:hover {background-color: #18A689;color:#fff !important;}
.posts a.delete:hover {background-color: #C75757;color:#fff !important;}
.posts .dataTables_filter {float: right}
.posts .filter-checkbox {position: absolute;top:10px;}
.posts .filter-checkbox .bootstrap-select {margin-bottom:0;}
.posts .table>thead>tr>th {padding:16px 8px;font-size: 16px}
.posts .table>tbody>tr>td {padding:16px 8px;}

.post .page-title a {display: inline-block;margin-bottom: 15px;margin-top: 15px;color: #888B91;}
.post .page-title a span {display: inline-block;margin-top: 14px;}
.post .page-title a:hover i, .post .page-title a:hover span {color:#0451A6;}
.post .page-title .fa {margin-right: 13px;padding: 5px 15px;float:left;}
.post h5 {margin-top: 30px}
.post #cke_1_contents {min-height: 300px}
.post .post-column-left {padding:10px 10px 20px 35px;}
.post .post-column-right {background-color: #2B2E33;padding:20px;margin:10px;color:#eaeaea;-moz-border-radius: 3px;-webkit-border-radius: 3px;border-radius: 3px;overflow: hidden;}
.post .post-column-right h3 {color: #A6ACB1;}
.post .ui-radio .ui-btn {background-color: #2B2E33;}
.post .ui-btn.ui-radio-off:after {opacity: 1}
.post .dropzone {min-height: 160px;}
.post .filter-option .label {margin-right: 5px}

/****  Events  ****/
.events .top-page {padding-bottom: 10px;margin-bottom: 10px}
.events .top-page .pull-right{margin-top: 8px}
.events .top-menu{border-bottom: 1px solid #fff;margin-top: 10px;margin-bottom: 10px;padding-bottom: 10px;}
.events .top-menu .btn:hover {color: #fff !important;background-color: #2B2E33;}
.events table {margin-top: 10px;background: #fff;-moz-border-radius: 3px;-webkit-border-radius: 3px;border-radius: 3px;overflow: hidden;}
.events .table-responsive thead {background:#2B2E33; color:#eaeaea; }
.events thead .ui-checkbox input {margin: -20px 0 0 0;}
.events tbody .ui-checkbox input {margin: -15px 0 0 0;}
.events .table>tbody>tr>td {vertical-align: middle;}
.events #main-content .table a {margin-bottom: auto;}
.events table.dataTable thead .sorting_asc:before, .events table.dataTable thead .sorting_desc:before {color: #0090D9;}
.events .table th {text-transform: none;font-weight: normal;}
.events .label-default:hover {cursor: pointer;background-color: #868585; }
.events a.edit:hover, .event a.edit:hover {background-color: #18A689;color:#fff !important;}
.events a.delete:hover, .event a.delete:hover {background-color: #C75757;color:#fff !important;}
.events .dataTables_filter {float: right}
.events .table>thead>tr>th {padding:16px 8px;font-size: 16px}
.events .table>tbody>tr>td {padding:16px 8px;}
.events .events-filter {background-color: #fff; padding:20px 10px 10px 10px;-moz-border-radius: 3px;-webkit-border-radius: 3px;border-radius: 3px;}
.events .range_inputs .btn {width: 100%;}
.events .range_inputs .btn-success {margin-top:20px;color:#fff;background-color:#C75757;border-color: #C75757}
.events .range_inputs .btn-success:hover,.events .range_inputs .btn-success:focus,.events .range_inputs .btn-success:active{color:#fff;background-color:#B32E2A;border-color:#B32E2A}
.events .bootstrap-select:not([class*="span"]):not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {min-width: 0;}
.dropdown-menu>li>a.no-option {padding: 0;height:0;}
.events #reportrange {color:#838383;}
.event-date {margin:auto;background-color: #E0E0E0;border-bottom: 10px solid #FFF;width: 55px;padding: 0;text-align: center;vertical-align: middle;text-transform: uppercase;}
.event-month { font-size: 12px;   padding-top: 2px }
.event-day { font-size: 25px; color: #414141; line-height: 24px;   }
.event-day-txt { font-size: 12px; font-weight: bold; color: #8d8a8a; padding-bottom: 3px}
.event-dots { color: #acacac; }
span.dots {background-image: url('../img/events/dots.png');background-position: center;background-repeat: no-repeat;width: 5px;height: 5px;}
.events .btn-rounded .fa,  .event .btn-rounded .fa{margin-top: 7px;}
.event .page-title a {display: inline-block;margin-bottom: 15px;margin-top: 15px;color: #888B91;}
.event .page-title a span {display: inline-block;margin-top: 14px;}
.event .page-title a:hover i, .event .page-title a:hover span {color:#0451A6;}
.event .page-title .fa {margin-right: 13px;padding: 5px 15px;float:left;}
.event h5 {margin-top: 30px}
.event #cke_1_contents {min-height: 300px}
.event .post-column-left {padding:10px 10px 20px 35px;}
.event .post-column-right {background-color: #2B2E33;padding:20px;margin:10px;color:#eaeaea;-moz-border-radius: 3px;-webkit-border-radius: 3px;border-radius: 3px;overflow: hidden;}
.event .post-column-right h3 {color: #A6ACB1;}
.event .ui-radio .ui-btn {background-color: #2B2E33;}
.event .ui-btn.ui-radio-off:after {opacity: 1}
.event .dropzone {min-height: 160px;}
.event .filter-option .label {margin-right: 5px}

/* Blog Dashboard */
.blog-stat .panel-heading {border-bottom:1px solid #eaeaea;}
.blog-stat .panel-body {padding-top: 10px}
.blog-stat .panel-footer {padding-bottom: 3px;padding-top: 23px}
.blog-stat h5 {font-size: 16px;}
.blog-stat p {font-size: 16px;}
.blog-dashboard .tab-content .table>thead>tr>th {padding:16px 8px;font-size: 14px}
.blog-dashboard .tab-content .table>tbody>tr>td {padding:16px 8px;}


.blog-dashboard table {margin-top: 10px;background: #fff;-moz-border-radius: 3px;-webkit-border-radius: 3px;border-radius: 3px;overflow: hidden;}

.blog-dashboard .table>tbody>tr>td {vertical-align: middle;}
.blog-dashboard #main-content .table a {margin-bottom: auto;}
.blog-dashboard .label-default:hover {cursor: pointer;background-color: #868585; }
.blog-dashboard a.edit:hover, .event a.edit:hover {background-color: #18A689;color:#fff !important;}
.blog-dashboard a.delete:hover, .event a.delete:hover {background-color: #C75757;color:#fff !important;}
.blog-dashboard .btn-rounded .fa{margin-top: 7px;}
/*--------------------------------  END CUSTOM PAGES  --------------------------------*/
/*------------------------------------------------------------------------------------*/


/*------------------------------------------------------------------------------------*/
/*------------------------------------  WIDGETS  -------------------------------------*/
.widget-fullwidth{margin-left: -20px;margin-right: -20px;margin-top: -10px;height: 100%; }
.widget-body{padding: 20px;}
.widget h2{display: inline-block;font-size: 16px;font-weight: 400;margin: 0;padding: 0;margin-bottom: 7px;width: 60%;}

/**** Weather Widget ****/
.panel-weather .panel-body {padding: 0;position: relative;overflow: hidden; color:#fff;}
.panel-weather img.weather-bg {position: absolute;}
.panel-weather .weather-body {padding: 0}
@font-face {font-family: 'weather';src: url('icons/weather/artill_clean_icons-webfont.eot');src: url('icons/weather/artill_clean_icons-webfont.eot?#iefix') format('embedded-opentype'),url('icons/weather/artill_clean_icons-webfont.woff') format('woff'),url('icons/weather/artill_clean_icons-webfont.ttf') format('truetype'),url('icons/weather/weatherfont/artill_clean_icons-webfont.svg#artill_clean_weather_iconsRg') format('svg');font-weight: normal;font-style: normal;}
#weather { width: 100%; margin: 0px auto; text-align: center; text-transform: uppercase;}
#weather [class^="icon-"]:before, #weather [class*=" icon-"]:before  {color: #fff;font-family: weather;font-size: 80px;font-weight: normal;margin-left: 0;font-style: normal;line-height: 1.0;}
.icon-0:before { content: ":"; }.icon-1:before { content: "p"; }.icon-2:before { content: "S"; }.icon-3:before { content: "Q"; }.icon-4:before { content: "S"; }.icon-5:before { content: "W"; }.icon-6:before { content: "W"; }.icon-7:before { content: "W"; }.icon-8:before { content: "W"; }.icon-9:before { content: "I"; }.icon-10:before { content: "W"; }.icon-11:before { content: "I"; }.icon-12:before { content: "I"; }.icon-13:before { content: "I"; }.icon-14:before { content: "I"; }.icon-15:before { content: "W"; }.icon-16:before { content: "I"; }.icon-17:before { content: "W"; }.icon-18:before { content: "U"; }.icon-19:before { content: "Z"; }.icon-20:before { content: "Z"; }.icon-21:before { content: "Z"; }.icon-22:before { content: "Z"; }.icon-23:before { content: "Z"; }.icon-24:before { content: "E"; }.icon-25:before { content: "E"; }.icon-26:before { content: "3"; }.icon-27:before { content: "a"; }.icon-28:before { content: "A"; }.icon-29:before { content: "a"; }.icon-30:before { content: "A"; }.icon-31:before { content: "6"; }.icon-32:before { content: "1"; }.icon-33:before { content: "6"; }.icon-34:before { content: "1"; }.icon-35:before { content: "W"; }.icon-36:before { content: "1"; }.icon-37:before { content: "S"; }.icon-38:before { content: "S"; }.icon-39:before { content: "S"; }.icon-40:before { content: "M"; }.icon-41:before { content: "W"; }.icon-42:before { content: "I"; }.icon-43:before { content: "W"; }.icon-44:before { content: "a"; }.icon-45:before { content: "S"; }.icon-46:before { content: "U"; }.icon-47:before { content: "S"; }
.weather-body h1 {margin-top: 0}
.weather-currently {color:#fff;text-align: right;font-weight: bold}
.big-img-weather{padding-left:30px !important;}
.big-img-weather:before{font-size:170px !important;}
.weather-left {text-align: center}
#weather h2 {margin: 0 0 8px;color: #fff;font-weight: 300;text-align: center;text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);}
#weather ul {margin: 0;padding: 0;}
#weather li {background: #fff;background: rgba(255,255,255,0.90);padding: 20px;display: inline-block;border-radius: 5px;}
#weather .currently {margin: 0 20px;}
.weather-place { font-size:24px;width: 50%;text-align: right;}
.weather-place i{ font-size:50px;float: right}
.weather-footer {position: absolute;bottom: 0;left:15px; right:15px;background-color: rgba(0, 0, 0, 0.6)}
.weather-footer-block { padding:5px;border-right: 1px solid #000000;font-weight: 500;}
.weather-footer-block:last-child {border-right:none;}
@media (max-width:768px) {
  .big-img-weather:before {font-size: 80px !important;}
  #weather [class^="icon-"]:before, #weather [class*=" icon-"]:before {font-size: 40px;}
}
.user-comment{display: block;margin-bottom: 10px;padding: 0 15px;}

/**** Chat Widget ****/
.chat{list-style: none;margin: 0;padding: 0;}
.chat .header{background:none;border:none;width:auto;position:relative;min-height:0;height:auto; z-index: 1}
.chat li{margin-bottom: 10px;padding-bottom: 5px;border-bottom: 1px solid #D6D6D6;}
.chat li.left .chat-body{margin-left: 60px;}
.chat li.right .chat-body{margin-right: 60px;}
.chat li .chat-body p{margin: 0;color: #777777;}
.chat .panel .slidedown .glyphicon, .chat .glyphicon{margin-right: 5px;}
.chat .panel-body{height: 340px !important;overflow: hidden;padding:0;}
.chat .panel-body ul{padding:15px 25px;}
@media (max-width:479px) {
  .chat-input {display: none !important}
}

/**** Profil Widget ****/
.profil-name-heading {position: absolute;top:40px;color:#fff;width: 100%}
.widget-profil-img-center {margin-top:-40px;border:10px solid #fff;box-shadow: 2px 4px 6px 0 rgba(0,0,0,.3);}

/**** Tasks Widget ****/
#task-manager .pull-left {margin-top: -10px}
#sortable-todo .sortable {padding: 15px 10px 10px;}
#task-manager .task-actions {padding: 15px 10px 10px;}
#task-manager .task-actions .ui-checkbox {float: left;}
#task-manager .task-actions .pull-right {margin-top: -10px}
/**** Animations Page ****/
.font-animation {padding: 10px 0}
.font-animation a{font-size: 16px;color:#121212;}
.font-animation a i{color:#3598DB;}
.font-animation a:hover{text-decoration: none}
.animation_title {font-family:'Open Sans', verdana, arial; font-size:6rem;color: #3598DB;}
#animationSandbox {display: block;overflow:hidden;}

/**** Graph Toggle Widget ****/
#tooltip, .graph-info a {background: #ffffff;-webkit-border-radius: 3px;-moz-border-radius: 3px;border-radius: 3px;}
.graph-info {width: 100%;margin-bottom: 10px;}
#tooltip, .graph-info a {font-size: 12px;line-height: 20px;color: #646464;}
.tickLabel {font-weight: bold;font-size: 12px;color: #666;}
#tooltip {position: absolute;display: none;padding: 5px 10px;border: 1px solid #e1e1e1;}
#lines, #bars {width: 34px;height: 32px;padding: 0;  margin-right: 0;margin-left: 10px;float: right;cursor: pointer;}
#lines.active, #bars.active {background: #00A2D9;}
#lines span, #bars span {display: block;width: 34px;height: 32px;background: url('../img/small/lines.png') no-repeat 9px 12px;}
#bars span { background: url('../img/small/bars.png') no-repeat center 10px; }
#lines.active span { background-image: url('../img/small/lines_active.png'); }
#bars.active span { background-image: url('../img/small/bars_active.png'); }
.yAxis .tickLabel:first-child,.yAxis .tickLabel:last-child { display: none; }
.graph-info:before, .graph-info:after,.graph-container:before, .graph-container:after {content: '';display: block;clear: both;}
/*----------------------------------  END WIDGETS  -----------------------------------*/
/*------------------------------------------------------------------------------------*/



/*-------- Customized ------------*/
.profile-nav img { max-width:100%; border-radius:50%}
.company-memo .panel-body{height: 162px !important;overflow: hidden;padding:0;}
.company-memo .panel-body ul { padding: 15px 25px;}
.company-memo li { border-bottom: 1px solid #d6d6d6; margin-bottom: 10px; padding-bottom: 5px;}
.company-memo li.left .chat-body { margin-left: 0px;}
.company-memo .header {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    border: medium none;
    height: auto;
    min-height: 0;
    position: relative;
    width: auto;
    z-index: 1;
}
.company-memo li .chat-body p { color: #777777; margin: 0 0 5px 0;}
.company-memo .panel .slidedown .glyphicon, .company-memo .glyphicon { margin-right: 5px;}
.m-b-m20 { margin-bottom:-20px;}
.m-t-m30 { margin-top:-30px;}
.panel-title .fa { margin-right:5px;}
.italic { font-style:italic}

.stars{margin: 4px 0 0; font-size: 16px; color: #d17581;}
.rating .panle-body{ height:368px !important; overflow:hidden} 
.mark_read input[type=checkbox] {left: -20px; margin: -2px 0 0;}

/* 28-07-2014 */
.makeblock {
  display: block;
}
/*added by suman to show loader*/
.overlay {
	background-attachment: scroll;
	background-clip: border-box;
	background-color: #fff;
	background-image: none;
	background-origin: padding-box;
	background-position: 0 0;
	background-repeat: repeat;
	background-size: auto auto;
	height: 100%;
	opacity: 0.5;
	position: fixed;
	top: 50px;
	width: 100%;
	z-index: 1000001;
}
.loader {
	left: 40%;
	position: absolute;
	top: 40%;
}
/*end loader*/
  .confirmon-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    left: 0;
    margin: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999999;
  }
  .confirmon-box {
    background-color: #fff;
    border: 5px solid #666666;
    box-shadow: 0 0 10px #444;
    color: #0090d9;
    font-size: 15px;
    font-weight: bold;
    left: 50%;
    margin-left: -15%;
    padding: 15px 39px;
    position: fixed;
    text-align: center;
    top: 185px;
    width: 27%;
    z-index: 999999;
  }
  .confirmon-button{
  	background-color: #428bca;
    border-color: #357ebd;
    color: white;
    cursor: pointer;
    padding:8px 10px !important;
    border-radius: 4px;
    line-height: 1.4;
    font-size: 15px;
  }
.yes{background-color:#DBFFDE;border: 1px solid lightgray;}
.no{background-color:#FFDCDC;border: 1px solid lightgray;}
.maybe{background-color:#DCDBFD;border: 1px solid lightgray;}
.yesforced{background-color:#9dc9a0;border: 1px solid lightgray;}
.maybeforced{background-color:#a1a0e5;border: 1px solid lightgray;}
.clockinActive{
	color: #d9534f;
	font-weight: 700 !important;
}
.input_check{
	margin-top: 2px !important;
	margin-left: -23px !important;
}
.help-inline {
    display: inline-block;
    padding-left: 5px;
    vertical-align: middle;
    color:#b94a48;
    font-weight: 500;
}
input[readonly] {
     cursor: pointer !important;
}
input[disabled] {
     cursor: not-allowed !important;
}
.striketext{
	text-decoration: line-through;
	color:lightgray;
}
.redstriketext{
	text-decoration: line-through red !important;
}
.bordergreen{
	border: solid 2px green !important;
}
.greentext{
	color:green;
	text-decoration: line-through;
}
.redtext{
	color:#d9534f;
}
.legend{
  	border: 1px outset threedface;
  	width: 3%;
}
.multiSelect .checkBoxContainer{
	max-height:200px;
	overflow-y:scroll;
	text-align:left;
	font-size:14px;
}

.unsortheading{color:#0090d9;}
.select_tag{text-align: left !important}
.btn.red.add-block-btn.image_cls {
    bottom: 75px;
    position: relative;
    right: 15px;
    border-radius: 47px;
     padding: 6px 14px;
     background-color: #DE453D;
     color: #fff;
     font-weight: bold;
}
.toolbarViewerRight #print{
	display: none;
}
.toolbarViewerRight #download{
	display: none;
}
 .inner-addon {
  position: relative;
  }
  .inner-addon .fa {
  position: absolute;
  padding: 10px;
  pointer-events: none;
  }
  .left-addon .fa  { left:  0px;}
  .left-addon input  { padding-left:  30px; }
  .ng-invalid-pattern {border-color: red !important;}
  .ng-invalid-max {border-color: red !important;}
  //Responsivecss
  .prof_info{min-height:230px;}
  .sd_tbl_response{float:left;width:100%;overflow-y:hidden;overflow-x:auto;}
  .tbl_response{ float: left;
  overflow-x: auto;
  width: 100%;}
  .sd_btn_res button{padding:7px !important;}
    .sd_res_btn select{
  padding:7px 9px !important;
  }
  .tbl_response{ float: left;
  overflow-x: auto;
  width: 100%;}
    .sd_res_btn select{
  padding:7px 9px !important;
  }
  .tbl_response .ng-scope.ng-isolate-scope .ng-table-counts{margin-bottom:10px;}
  .tbl_response .ng-scope.ng-isolate-scope .ng-table-counts button{padding:7px 19px;}
  .sd_calender1 {margin: auto;max-width: 320px;position: relative;width: 100%;}
  .fl_l100{float:left;width:100%;}
  .pad_b15{padding-bottom:15px;}
  .sd_table_req{margin:auto;}
  .sd_dv_list {padding: 0 15px;}
  .sd_dv_list span {display: inline-block;width: 25%;}
  .sd_dv_list span:first-child{font-weight:bold;}
  .hide-event {opacity:0.01}
    @media(max-width:980px)
  {
	    .sd_tbl_response1{float:left;width:100%;overflow-y:hidden;overflow-x:scroll;}
  }
  
  @media(max-width:767px)
  {
	  .sd_subbtn {margin-top: 10px;}
	  .pull-right.text-right.sd_legend {text-align: left;}
	  .sd_btnsuccess{text-align:left;float:none;}
	  .sd_btnsuccess button{margin-left:0px !important;}
	  .sd_table_memo {margin-bottom: 10px !important;}
  }
  @media(max-width:480px)
  {
  .sd_panel_head h3{width:auto;}
  .prof_info{text-align:center;}
  .sd_fnt_14{font-size:14px !important;}
  .sd_last_btn button {
  padding: 7px 12px;
}
.sd_last_btn a.btn {padding: 7px 14px;}
.pull-right.text-right.sd_legend,.sd_avail h3{font-size:20px;}

  }
  @media(max-width:360px)
  {
	  .pull-right.text-right.sd_legend,.sd_avail h3{font-size:14px;}
	  .ng-table-counts.pull-right button {
  padding: 7px 16px;
}
  }

  .mrng_sel select {width: 41%;}.pad_btm10p{padding-bottom:10px ;}@media(max-width:991px){.mrng_lbl{text-align:left !important}.mrng_sel select{width:100% !important}}@media(max-width:767px){.form-group.frm_txtcen{text-align:center !important;}.frm_txtcen label{font-size:18px;font-weight:500;padding-bottom:10px;}}@media(max-width:480px){.ng-table-pager ul {float: left !important; margin-top: 10px; text-align: center;width: 100% !important;}.ng-table-pager ul.pagination li{float:none;}.ng-table-counts{text-align:center;float:left;width:100%;}.ng-table-counts button{float:none !important;}}
              
 @media(max-width:480px){.res_cen {margin-bottom: 12px;text-align: center !important;}.res_cen .DTTT_container{float:none !important}.navbar-inverse.navbar-mob-view .header-menu.xs{display: block;}.navbar-inverse.navbar-mob-view .navbar-brand {width: 162px;} .navbar-inverse.navbar-mob-view .header-menu.xs.navbar-nav > li > a {padding: 1px 17px 8px 14px;}.navbar-inverse.navbar-mob-view .navbar-nav>.open>a, .navbar-inverse.navbar-mob-view .navbar-nav>.open>a:hover, .navbar-inverse.navbar-mob-view .navbar-nav>.open>a:focus {color: #fff;background-color: #2b2e33;}
.header-menu.xs #user-header .dropdown-menu {width: 178px;position: absolute;background-color: #fff;color:#000;}.header-menu.xs #user-header span{display:none;}#main-menu{margin-top: 15px;}.header-menu.xs #user-header .dropdown-menu li>a{color: #000;}.header-menu.xs #user-header .dropdown-menu li>a:hover{color: #000;}
}        
 .frm_txtcen select {margin-right: 10px;}@media(max-width:767px){.mar_bt10{margin-bottom:10px !important;}}@media(max-width:480px){.colres100{width:100% !important}.grnbtn100{width:100% !important}.head_to{font-size:18px;}}
 #eventtable td, #eventtable th {white-space: nowrap;}
/*.xdsoft_time_box{height:auto !important;}*/
@media(max-width: 1199px){.sidebar-medium #sidebar ul li ul {position: absolute;left: 125px;width: 220px;margin-top: -70px;}
.sidebar-medium #sidebar {width: 125px;position: absolute;height: auto;}
}

