.angucomplete-holder {
    position: relative;
}

.angucomplete-dropdown {
    border-color: #ececec;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    width: 100%;
    padding: 6px;
    cursor: pointer;
    z-index: 9999;
    position: absolute;
    overflow-y: auto;
    margin-top: -6px;
    background-color: #ffffff;
    max-height: 150px;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.25);
}

.angucomplete-searching {
    color: #acacac;
    font-size: 14px;
}

.angucomplete-description {
    font-size: 14px;
}

.angucomplete-row {
    padding: 5px;
    color: #000000;
    margin-bottom: 4px;
    clear: both;
}

.angucomplete-selected-row {
    background-color: lightblue;
    color: #ffffff;
}

.angucomplete-image-holder {
    padding-top: 2px;
    float: left;
    margin-right: 10px;
    margin-left: 5px;
}

.angucomplete-image {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    border-color: #ececec;
    border-style: solid;
    border-width: 1px;
}

.angucomplete-image-default {
    /* Add your own default image here
     background-image: url('/assets/default.png');
    */
    background-position: center;
    background-size: contain;
    height: 34px;
    width: 34px;
}


/*


.jumbotron {
    padding-top: 40px;
    text-align: center;
}

.jumbotron-red {
    background-color: #FF5C5B;
    color: #ffffff;
    padding: 30px;
    text-align: left;
}


.dropdown {
    width: 53px;
    position: absolute;
    right: 4px;
    top: 50px;
    z-index: 100;
    padding: 0px;
    text-align: left;
    text-align: center;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    text-align: center;
}

.dropdown-icon {
    margin-bottom: 0px;
    padding: 5px;
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
}

.dropdown-icon:hover {
    background-color: #fb8200;
}

.dropdown-orange {
    background-color: #FFA300;
    color: #ffffff;
}

.btn-orange:hover {
    background-color: #F78200;
    color: #ffffff;
}

.padded-row {
    margin-top: 10px;
}

.large-padded-row {
    margin-top: 20px;
}

.very-large-padded-row {
    margin-top: 40px;
}

.ng-cloak {
    display: none;
}



.search-box {
    position: relative;
    border-style: solid;
    border-color: #F2F2F2;
    border-width: 2px;
    border-radius: 4px;
    padding: 6px;
    background-color: #ffffff;
    margin-top: 10px;
}

.search-box-entry {
    padding-left: 10px;
}

.search-box-entry-input {
    width: 80%;
    border-style: none;
    font-size: 18px;
}

.search-box-icon {
    position: absolute;
    right: 10px;
    top: 10px;
}

.search-box-entry-input:focus {
    outline: 0;
}

.lead {
    font-size: 22px;
}

.centered {
    text-align: center;
}


/*
.form-control {
    outline: 0;
    border-color: #ECECEC;
    border-style: solid;
    border-width: 1px;
    width: 95%;
    background-color: #ffffff;
    padding: 6px;
    border-radius: 2px;
    margin-bottom: 5px;
    font-size: 14px;
}
*/
.form-control-small {
    -webkit-transition: width .5s ease-in-out;
    -moz-transition: width .5s ease-in-out;
    -o-transition: width .5s ease-in-out;
    -ms-transition: width .5s ease-in-out;
    transition: width .5s ease-in-out;
    /*width: 50%;*/
}

.form-control:-webkit-autofill {
    background-color: #fff !important;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.error-handler-title {
    font-size: 50px;
    color: #ffffff;
}

.error-handler-text {
    font-size: 22px;
    color: #ffffff;
}

.icon-spaced {
    margin-left: 5px;
}

.form-control-fill {
    width: 96%;
}

.criterion {
    font-size: 14px;
    display: inline;
    margin-left: 2px;
    width: 300px;
    height: 150px;
    position: relative;
}

.criterion-rating {
    padding: 2px;
    background-color: #acacac;
    border-radius: 0px;
    color: #ffffff;
    margin-left: 3px;
    font-size: 12px;
    margin-top: -3px;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
    display: inline;
}

.criterion-rating-dropdown {
    padding: 2px;
    border-radius: 0px;
    color: #ACACAC;
    margin-left: 0px;
    font-size: 12px;
    margin-top: -3px;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
    display: block;
}

.criterion-rating-dropdown:hover {
    color: #383736;
}

.criterion-rating-green {
    background-color: #5BC25B;
}

.criterion-rating-red {
    background-color: #EA3D35;
}

.criterion-rating-orange {
    background-color: #F88707;
}

.criterion-dropdown {
    width: 60px;
    text-align: left;
    top: 60px;
    left: 100px;
    padding: 2px;
    background-color: #e0e0e0;
    z-index: 9999;
    position: relative;
}

.criterion-menu {
    display: inline;
}



.placeholder-colour {
    color: #e0e0e0;
}

.card.ng-show {
    -webkit-transition:all linear 0.5s;
    transition:all linear 0.5s;
    line-height:20px;
    opacity:0.2;
    padding:10px;
    border:1px solid black;
    background:black;
}

.opaque-cover {
    position: relative;
    top: 0;
    left: 0;
    opacity: 0.5;
    background-color: #000000;
    z-index: 9999;
    height: 100%;
    width: 100%;
}



.comment-entry {
    margin-bottom: 10px;
}

.highlight {
	color: #ff0000;
}

form input.ng-invalid-autocomplete-required {
  border-color: red;
}

.valid-status {
  display: inline-block;
  margin-left: 10px;
}

.valid-status.valid {
  color: green;
}

.valid-status.invalid {
  color: red;
}

.console {
  margin-left: 30px;
  font-family: Monaco;
  font-size: 12px;
  color: #ccc;
}

.small-input {
  /*width: 30%;*/
  border:none;
  color:green;
  font-weight:bold;
}
